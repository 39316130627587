export type JobTypeName = ('instant'|'regular'|'quote');

export class JobType {
    id?: number;
    name?: JobTypeName;
    title?: string;
    suggestion?: number;
    description?: string;

    static fromJson(fields): JobType {
        let item = Object.assign(new this(), fields);
        return item;
    }

    get isInstant(): boolean {
        return this.name === 'instant';
    }

    get isRegular(): boolean {
        return this.name === 'regular';
    }

    get isQuote(): boolean {
        return this.name === 'quote';
    }
}
