
export class CircleEventLocation {
    address?: string;
    country?: string;
    city?: string;
    postal_code?: string;
    coordinate_lat?: number;
    coordinate_long?: number;


    static fromJson(fields): CircleEventLocation {
        let item = Object.assign(new this(), fields);
        return item;
    }
}

