import { Pipe, PipeTransform } from '@angular/core';
import { UserGroupType } from '../models/user-group.model';
import { User } from '../models/user.model';

@Pipe({
    name: 'userGroup'
})
export class UserGroupPipe implements PipeTransform {
    transform(user: User, ...roles: string[]): boolean {
        const testUser: User = user instanceof User ? user : User.fromJson(user);
        return testUser.hasGroups(roles as UserGroupType[]);
    }
}
