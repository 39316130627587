<footer *ngIf="visible"
    [class.sidebar]="mode === 'sidebar'"
    [class.appmenu]="mode === 'offcanvas'"
>
    <div class="container-xl">
        <div class="row">
            <div [ngClass]="{
                'col-md-4 col-sm-6': mode === 'default',
                'col-12 order-2 mb-3 text-center border-top': mode === 'sidebar',
                'col-12 order-2 mb-3 text-start': mode === 'offcanvas'
            }">
                <div class="discover">
                    <h3 [class.text-center]="modeOffcanvas">Discover</h3>
                    <ul>
                        <li>
                            <a routerLink="/about/itasker">Become an iTasker</a>
                        </li>

                        <li *ngFor="let app of links">
                            <a [routerLink]="'/about/' + app.link" *ngIf="app.type === 'link'">
                                {{ app?.title }}
                            </a>
                            <a [href]="app.link" target="_blank" *ngIf="app.type === 'external'">
                                {{ app?.title }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div [ngClass]="{
                'col-md-4 col-sm-6': mode === 'default',
                'col-12 order-1 mb-3 text-center border-top': mode === 'sidebar',
                'col-12 order-1 mb-3 text-start': mode === 'offcanvas'
            }">
                <div class="footer-menu">
                    <h3 [class.text-center]="modeOffcanvas">Company</h3>
                    <ul>
                        <li *ngIf="urlAboutUs?.length">
                            <a [routerLink]="'/about/' + urlAboutUs">About Us</a>
                        </li>
                        <li>
                            <a routerLink="/about/contact-us">Contact Us</a>
                        </li>
                        <li *ngIf="urlTerms?.length">
                            <a [routerLink]="'/about/' + urlTerms">Terms and Conditions</a>
                        </li>
                        <li *ngIf="urlPrivacy?.length">
                            <a [routerLink]="'/about/' + urlPrivacy">Privacy Policy</a>
                        </li>

                        <li *ngIf="urlAboutUs?.length">
                            <a href="https://blog.itask.com/" target="_blank">Blog</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div [ngClass]="{
                'col-md-4 col-sm-12': mode === 'default',
                'col-12 order-0 mb-3 text-center border-top': mode === 'sidebar',
                'col-12 order-0 mb-3 pb-3 text-center': mode === 'offcanvas',
                'd-none': isMobileApp
            }">
                <h3 [class.text-center]="modeOffcanvas">Download our app</h3>
                <p>Track your tasks wherever you are with our mobile app</p>
                <mobile-apps [footer]="true" [sidebar]="mode === 'sidebar'"></mobile-apps>
            </div>
        </div>
    </div>
</footer>
