export type InvoiceItemType = ('regular'|'other'|'tax'|'total'|'discount');

export class InvoiceItem {
    title?: string;
    subtitle?: string;
    quantity?: number;
    amount?: number;
    total?: number;
    type?: InvoiceItemType;
    page?: number;

    static fromJson(fields): InvoiceItem {
        let item = Object.assign(new this(), fields);

        item.amount = parseFloat(fields?.amount);
        item.quantity = parseFloat(fields?.quantity);
        item.total = parseFloat(fields?.total);

        return item;
    }

    setAmount(amount: number|string, quantity: number|string = null): void {
        this.amount = Math.floor((parseFloat(amount as string || '') || 0) * 100) / 100;

        quantity = ('' + quantity).replace(',', '.') || null;
        this.quantity = Math.floor((parseFloat('' + (quantity ?? this.quantity) || '') || 0) * 100) / 100;

        this.total = this.amount * (this.quantity || 1);
        this.total = Math.floor(Number(this.total.toFixed(2)) * 100) / 100;
    }
}
