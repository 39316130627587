import { AssetStatus } from "./asset.model";

export class AssetCategory {
    id?: number;
    title?: string;
    status?: AssetStatus;
    created?: string;
    updated?: string;

    static fromJson(fields: any): AssetCategory {
        let item = Object.assign(new this, fields);

        return item;
    }
}
