import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, share } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchbarService {
    protected visible: boolean = true;
    protected search?: string = undefined;
    protected focusing: boolean = false;

    protected subject: BehaviorSubject<{
        visible: boolean,
        search?: string,
        focus?: boolean
    }> = new BehaviorSubject({
            visible: this.visible,
            search: this.search,
            focus: this.focusing,
        });

    constructor() { }

    show(): void {
        this.visible = true;
        this.focusing = false;

        this.subject.next({
            visible: this.visible,
            search: this.search,
            focus: this.focusing,
        });
    }

    hide(): void {
        this.visible = false;
        this.focusing = false;

        this.subject.next({
            visible: this.visible,
            search: this.search,
            focus: this.focusing,
        });
    }

    focus(): void {
        this.focusing = true;

        this.subject.next({
            visible: this.visible,
            search: this.search,
            focus: this.focusing,
        });
    }

    updateQuery(q: string = ''): void {
        this.search = q;
        this.focusing = false;

        this.subject.next({
            visible: this.visible,
            search: this.search,
            focus: this.focusing,
        });
    }

    /**
     * Current new notification items
     * @returns
     */
    getChanges(): Observable<{visible: boolean, search?: string, focus?: boolean}> {
        return this.subject.asObservable().pipe(share());
    }
}
