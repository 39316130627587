import { Itasker } from './itaskers/itasker.model';
import { UserGroup, UserGroupType } from './user-group.model';
import { ServiceCategory } from './service-category.model';
import { UserRole } from './user-role.model';

export type UserStatus = ('active'|'inactive');
export type UserApproval = ('pending'|'approved'|'rejected');

export interface UserCompact {
    token: string;
    id?: number;
    uuid?: string;
    email?: string;
    name_first?: string;
    name_last?: string;
    avatar?: string;
    group?: UserGroup;
};

export class User {
    id?: number;
    uuid?: string;
    email?: string;
    name_first?: string;
    name_last?: string;
    initials?: string;
    avatar?: string;
    itasker_id?: number;
    itasker?: boolean;
    itasker_details?: Itasker;
    description?: string;
    phone?: string;
    phone_token?: string;
    phone_os?: string;
    city?: string;
    coords?: {
        lat?: number;
        long?: number;
    };
    stripe_acct?: string;
    stripe_id?: string;
    address?: string;
    postal_code?: string; // missing?
    status?: UserStatus;
    group?: UserGroup;
    roles?: UserRole[];
    permissions?: string[];
    approval?: UserApproval;
    uploads?: Array<{
        id?: number;
        title?: string;
        description?: string;
        path?: string;
        url?: string;
        mime?: string;
        size?: number;
        expired_at?: any;
    }>;
    last_login?: string;
    created?: string;
    updated?: string;
    removed?: boolean;
    counters?: {
        chats?: number,
        notifications?: number,
        rating?: number,
        tasks?: number,
        hired?: number,
        completed?: number,
    };
    category?: ServiceCategory;
    token?: string;

    // custom
    get name(): string {
        return this.name_first; // privacy - only first name
    }

    get full_name(): string {
        return [this.name_first || '', this.name_last || '']
            .filter(i => i && i.length)
            .join(' ');
    }

    /**
     * Check if user is guest
     */
    isGuest(): boolean {
        return !(this.name_first || '').length
            || !(this.name_last || '').length
            || !(this.id || 0)
            || !(this.token || '').length;
    }

    isAdmin(isValid: boolean = false): boolean {
        return this.hasGroups('admin', isValid);
    }

    isItasker(isValid: boolean = false): boolean {
        return this.hasGroups('itasker', isValid);
    }

    isWorker(isValid: boolean = false): boolean {
        return this.hasGroups('worker', isValid);
    }

    isOperator(isValid: boolean = false): boolean {
        return this.hasGroups('operator', isValid);
    }

    isManager(isValid: boolean = false): boolean {
        return this.hasGroups('manager', isValid);
    }

    isPaymaster(isValid: boolean = false): boolean {
        return this.hasGroups('paymaster', isValid);
    }

    isClient(isValid: boolean = false): boolean {
        return this.hasGroups('client', isValid);
    }

    /**
     * Check if any of the provided groups are valid for current user
     * @param groups
     * @param isValid
     * @returns
     */
    hasGroups(groups: UserGroupType|UserGroupType[], isValid: boolean = false) {
        groups = typeof groups === 'string'
            ? [groups]
            : groups;

        let found = (groups || []).filter(group => {
            if (group === 'guest') {
                return this.isGuest();
            } else {
                return (!isValid || !this.isGuest()) && this.group?.name === group;
            }
        }).length;

        return groups.length > 0
            ? found > 0
            : true;
    }

    /**
     * Check if any of requested permissions are assigned to current user
     *
     * @param permissions List of permissions like 'profile.update', 'user.index', etc.
     * @param skipNonAdmins Returns true if user is not admin, no matter of provided permissions to check
     * @return {boolean} True if all of requested permissions are given to the user
     */
    can(permissions: string[]|string, skipNonAdmins: boolean = true): boolean {
        permissions = typeof permissions === 'string' ? [permissions] : permissions;

        if (!this.isAdmin()) {
            return skipNonAdmins;
        }

        for (const permission of permissions) {
            if (!(this.permissions || []).includes(permission)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Check if at least one of the requested permissions is assigned to current user
     *
     * @param permissions List of permissions like 'profile.update', 'user.index', etc.
     * @param skipNonAdmins Returns true if user is not admin, no matter of provided permissions to check
     * @return {boolean} True if one (or more) of requested permissions is given to the user
     */
    canAnyOf(permissions: string[]|string, skipNonAdmins: boolean = true): boolean {
        permissions = typeof permissions === 'string' ? [permissions] : permissions;

        if (!this.isAdmin()) {
            return skipNonAdmins;
        }

        for (const permission of permissions) {
            if ((this.permissions || []).includes(permission)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Check if User data is invalid
     */
    isValid(): boolean {
        return this.hasToken()
            && (this.name_first || '').length > 0
            && (this.name_last || '').length > 0
            && (this.id || 0) > 0;
    }

    get is_valid(): boolean {
        return this.isValid();
    }

    /**
     * Check if stored token is available
     */
    hasToken(): boolean {
        return (this.token || '').length > 0;
    }

    /**
     * Get current stored token
     */
    getToken(): string|null {
        return (this.token || '').length ? this.token : null;
    }

    /**
     * Set user token
     */
    setToken(token: string): this {
        if (token && typeof token === 'string' && token.length > 0) {
            this.token = token;
        }

        return this;
    }

    static fromJson(fields: any): User {
        let item = Object.assign(new this(), fields);

        item.category = ServiceCategory.fromJson(fields?.category);
        item.roles = fields?.roles?.map(role => UserRole?.fromJson(role));

        // *WARNING* - the line bellow cause recursion
        // item.itasker_details = Itasker.fromJson(fields?.itasker_details);
        return item;
    }

    get defaultRoute(): string {
        const map: {[name in UserGroupType]?: string} = {

        };

        return this.group?.name in map
            ? map[this.group?.name]
            : '/dashboard';
    }

    protected capitalizeString(item: string): string {
        return item.charAt(0).toUpperCase() + item.slice(1);
    }

    get nameFormat(): string {
        return this.capitalizeString(this.name_first) + ' ' + this.capitalizeString(this.name_last);
    }

    get compactData(): UserCompact {
        return {
            token: this.token,
            id: this.id,
            uuid: this.uuid,
            email: this.email,
            name_first: this.name_first,
            name_last: this.name_last,
            avatar: this.avatar,
            group: {
                id: this.group?.id,
                name: this.group?.name,
                title: this.group?.title,
            },
        };
    }
}
