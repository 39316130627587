
import { ProjectCurrentUser } from './project-current-user.model';
import { Comment } from "../comment.model";
import { Itasker } from "../itaskers/itasker.model";
import { ProjectImage } from "./project-image.model";
import { ProjectCompletion, ProjectStatus, ProjectUserRated } from "./project-types";

export class Project {
    id?: number;
    uuid?: string;
    title?: string;
    description?: string;
    cover_photo?: string;
    rating?: number;
    comments_count?: number;
    favorites_count?: number;
    status?: ProjectStatus;
    completion?: ProjectCompletion;
    itasker?: Itasker;
    images?: ProjectImage[];
    comments?: Comment[];
    created?: string;
    updated?: string;
    current_user?: ProjectCurrentUser;

    static fromJson(fields): Project {
        let item = Object.assign(new this(), fields);
        item.images = item?.images?.map(image => ProjectImage.fromJson(image));
        item.itasker = Itasker.fromJson(item?.itasker);
        item.current_user = ProjectCurrentUser.fromJson(item?.current_user);
        item.comments = item?.comments?.map(comment => Comment.fromJson(comment));
        return item;
    }
};
