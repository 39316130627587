export type SettingType = ('boolean'|'integer'|'string');

export class Setting {
    name?: string;
    value?: number|string|boolean;
    default?: any;
    type?: SettingType;

    static fromJson(fields): Setting {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
