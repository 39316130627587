import { User } from "../user.model";
import { Itasker } from "./itasker.model";
import { Reference } from "./reference.model";

export class Review {
    id?: number;
    rating?: boolean;
    review?: string;
    itasker?: Itasker;
    author?: User;
    created?: string;
    updated?: string;

    static fromJson(fields: any): Review {
        let item = Object.assign(new this(), fields);
        item.rating = item.rating === 1;
        item.author = User.fromJson(item?.author);
        return item;
    }

    static fromReferenceModel(fields: Reference): Review {
        let item = new this();

        item.author = User.fromJson({
            name_first: fields.full_name.split(' ')[0] ?? '',
            name_last: fields.full_name.split(' ')[1] ?? '',
        });
        item.id = fields.id;
        item.rating = fields.rating === 'satisfied';
        item.review = fields.description;
        item.updated = null;
        item.created = null;
        return item;
    }
};
