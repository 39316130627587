import {JobServicePivot} from './job-service-pivot.model';

export class JobService {
    id?: number;
    slug?: string;
    name?: string;
    title?: string;
    image?: string;
    rate?: string;
    init_time?: string;
    init_appointment_time?: number;
    init_appointment_discount?: number;
    follow_up_appointment_discount?: number;
    warranty_time?: number;
    quote_fee: number = 1;
    is_custom?: boolean;
    pivot?: JobServicePivot;

    static fromJson(fields): JobService {
        let item = Object.assign(new this(), fields);
        item.pivot = JobServicePivot.fromJson(item?.pivot);
        return item;
    }
}
