import { JobStatus } from "./job-status.model";

export class JobUpload {
    id?: number;
    title?: string;
    description?: string;
    path?: string;
    url?: string;
    mime?: string;
    size?: number;
    status?: ('active'|'inactive');
    job_status?: JobStatus;

    is_image?: boolean;


    static fromJson(fields): JobUpload {
        let item = Object.assign(new this(), fields);
        item.job_status = JobStatus.fromJson(item.job_status);
        item.is_image = (item.mime?.split('/')?.[0] ?? '') === 'image';
        return item;
    }
}
