<header>
    <nav class="navbar fixed-top py-0 py-lg-2 px-0 px-sm-1 px-md-2 navbar-expand-lg navbar-light bg-white app">
        <div class="container-fluid d-flex flex-nowrap flex-row ps-1 ps-lg-3 pe-lg-3">
            <div class="navbar-brand d-block me-auto d-lg-none" [class.me-auto]="!apps?.length">
                <div class="d-flex align-items-stretch align-content-start flex-nowrap flex-row">
                    <div class="d-flex justify-content-center align-items-stretch align-content-stretch flex-nowrap flex-row me-auto me-lg-0 d-lg-none">
                        <div class="logo-link order-lg-0 d-flex flex-column align-content-stretch justify-content-center order-1 order-lg-0">
                            <a routerLinkActive="active" routerLink="/" class="align-self-center ms-1">
                                <img src="/assets/img/logo.svg" [alt]="appName" alt="iTaskApp" title="Home" class="d-inline-block p-0 p-lg-1" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="navbar-nav me-auto order-0 order-lg-1 d-flex align-items-center justify-content-between flex-row apps-list" *ngIf="apps?.length > 1">
                <div class="align-self-center d-flex flex-row align-items-center mx-auto">
                    <div class="dropdown apps">
                        <button class="btn btn-default btn-sm py-1 px-1 border-0 ripple"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                aria-label="Applications">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#BE0C1E"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"/></svg>
                        </button>

                        <div class="dropdown-menu dropdown-menu-start">
                            <div class="row flex-sm-nowrap mx-0 px-1 px-md-3">
                                <div class="col-6 col-sm text-center py-2 app-icon" *ngFor="let app of apps">
                                    <a [href]="app.link" (click)="onAppClick($event, app)">
                                        <i class="icon d-block mb-2 mx-auto text-center" rel="nofollow">
                                            <img [src]="app?.image" [alt]="app?.title" title="" height="16" width="16" style="opacity:0">
                                        </i>
                                        <span class="title">
                                            <i *ngIf="app?.loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                                            {{ app?.title }}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="searchbar mx-auto" *ngIf="searchBarVisible">
                <form action="/" method="GET" (submit)="onSearchBarInput($event)">
                    <div class="search-field d-flex">
                        <div class="input-wrapper">
                            <input type="text"
                                    [placeholder]="searchBarPlaceholder"
                                    (input)="onSearchBarInput($event)"
                                    name="q"
                                    class="search"
                                    [(ngModel)]="searchBarInput"
                                    #searchBarElement
                                    autocomplete="off">

                            <button *ngIf="searchBarInput?.length" type="button" class="px-3 btn btn-clear-search" aria-label="Clear Search" (click)="onSearchBarClear($event)">
                                <div class="sr-only">Remove</div>
                                <i class="fa-solid fa-xmark"></i>
                            </button>

                            <button type="submit" class="px-3 btn btn-primary btn-rounded" aria-label="Search Now">
                                <div class="sr-only">Search Now</div>
                                <i class="fa-solid fa-magnifying-glass text-light"></i>
                            </button>
                        </div>
                        <a routerLink="/services/" class="btn btn-primary btn-rounded btn-lighter px-3 px-sm-4 mx-1 mx-md-2 text-nowrap d-none d-sm-block">
                            <span class="d-none d-xxl-inline-block">See All Services</span>
                            <span class="d-xxl-none">See All</span>
                        </a>
                    </div>
                </form>
            </div>

            <ul class="navbar-nav ms-auto order-0 order-lg-1 d-flex align-items-center justify-content-between flex-row" *ngIf="isAuthReady">
                <ng-container [ngTemplateOutlet]="profile"></ng-container>
            </ul>
        </div>
    </nav>
</header>

<div class="offcanvas offcanvas-start" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
    <div class="offcanvas-header d-none d-lg-block">
        <a routerLinkActive="active" routerLink="/" class="align-self-center">
            <img src="/assets/img/logo.svg" [alt]="appName" alt="iTaskApp" title="Home" class="w-100 h-100" />
        </a>
    </div>
    <div class="offcanvas-body px-3 px-md-4 px-lg-0 pt-0">
        <div class="text-end">
            <button type="button" class="btn-close text-reset my-2" data-bs-dismiss="offcanvas" aria-label="Close">
                <i class="fa-solid fa-xmark fa-fw fa-lg"></i>
            </button>
        </div>


        <ul class="navbar-nav d-block" *ngIf="isAuthReady && currentPage !== '/neighborhood'">
            <ng-container *ngFor="let name of [
                'home',
                'dashboard',
                'dashboard_mobile',
                'services',
                'discount_club',
                'company',
                'wallet',
                'messages_signed',
                'connections',
                'manage',
                'about_us',
                'become_an_itasker',
                'blog',
            ]">
                <ng-container [ngTemplateOutlet]="itemWrap" [ngTemplateOutletContext]="{name:name, items:menus, hideSeparator:true, offcanvas:true, parent:menus[name]}"></ng-container>
            </ng-container>
        </ul>

        <ul class="navbar-nav d-block" *ngIf="isAuthReady && currentPage === '/neighborhood'">
            <ng-container *ngFor="let name of [
                'neighborhood',
                'manage',
            ]">
                <ng-container [ngTemplateOutlet]="itemWrap" [ngTemplateOutletContext]="{name:name, items:menus, hideSeparator:true, offcanvas:true, parent:menus[name]}"></ng-container>
            </ng-container>
        </ul>

        <ul class="shortcuts mt-3" *ngIf="(user | userGroup:'itasker':'client')" [attr.data-bs-dismiss]="'offcanvas'">
            <ng-container *ngIf="!isPageCircle">
                <li *ngIf="(user | userGroup:'itasker') && featureCustomInvoices">
                    <a routerLink="/finances/add" class="text-warning" >
                        <div class="icon-plus">
                            <i class="fa-solid fa-file-invoice-dollar fa-fw"></i>
                            <i class="fa-solid fa-plus fa-fw"></i>
                        </div>
                        Create<br />Invoice
                    </a>
                </li>
                <li *ngIf="(user | userGroup:'itasker') && featureCustomQuotes">
                    <a routerLink="/finances/add" [queryParams]="{'type': 'quote'}" class="text-blue">
                        <div class="icon-plus">
                            <i class="fa-solid fa-file-lines fa-fw"></i>
                            <i class="fa-solid fa-plus fa-fw"></i>
                        </div>
                        Create<br />Quote
                    </a>
                </li>
                <li>
                    <a routerLink="/tasks/add" class="text-primary">
                        <div class="icon-plus">
                            <i class="fa-solid fa-list-check fa-fw"></i>

                            <i class="fa-solid fa-plus fa-fw"></i>
                        </div>

                        <ng-container *ngIf="(user | userGroup:'itasker')">Create<br />Internal Task</ng-container>
                        <ng-container *ngIf="!(user | userGroup:'itasker')">Create<br />Task</ng-container>
                    </a>
                </li>
                <!-- <li *ngIf="(user | userGroup:'itasker')">
                    <a routerLink="/tasks/add" class="text-info" >
                        <i class="fa-solid fa-square-plus"></i>
                        Create<br />Expenses
                    </a>
                </li> -->
            </ng-container>

            <ng-container *ngIf="isPageCircle">
                <li *ngIf="(user | userValid)">
                    <a routerLink="/neighborhood/add" class="text-primary">
                        <div class="icon-plus">
                            <i class="fa-solid fa-users-line fa-fw"></i>
                            <i class="fa-solid fa-plus fa-fw"></i>
                        </div>
                        Create a<br />Group
                    </a>
                </li>
            </ng-container>
        </ul>

        <ul class="shortcuts mt-3" *ngIf="(user | userGroup:'admin')" [attr.data-bs-dismiss]="'offcanvas'">
            <ng-container *ngIf="isPageCircle">
                <li *ngIf="(user | userValid)">
                    <a routerLink="/neighborhood/add" class="text-primary">
                        <div class="icon-plus">
                            <i class="fa-solid fa-users-line fa-fw"></i>
                            <i class="fa-solid fa-plus fa-fw"></i>
                        </div>
                        Create a<br />Group
                    </a>
                </li>
            </ng-container>
        </ul>

        <ul class="shortcuts mt-3" *ngIf="(user | userGroup:'worker')" [attr.data-bs-dismiss]="'offcanvas'">
            <ng-container *ngIf="isPageCircle">
                <li *ngIf="(user | userValid)">
                    <a routerLink="/neighborhood/add" class="text-primary">
                        <div class="icon-plus">
                            <i class="fa-solid fa-users-line fa-fw"></i>
                            <i class="fa-solid fa-plus fa-fw"></i>
                        </div>
                        Create a<br />Group
                    </a>
                </li>
            </ng-container>
        </ul>

        <div class="d-block d-lg-none text-center additional-links">
            <div class="collapse show" id="collapseMenuFooter" [attr.data-bs-dismiss]="'offcanvas'">
                <app-footer mode="offcanvas" class="border-top mt-4 pt-0"></app-footer>
            </div>
        </div>
    </div>
</div>

<nav id="bottom" class="navbar navbar-expand fixed-bottom navbar-light bg-light px-0 pb-0 pt-1" [class.d-lg-none]="!isMobileApp">
    <div class="container-xl d-block px-0">
        <div class="row mx-auto justify-content-between flex-nowrap">
            <a class="col text-center item"
                data-bs-toggle="offcanvas"
                data-bs-target="#sidebarMenu"
                [routerLinkActiveOptions]="{exact: true}"
                routerLinkActive="active"
                #appMore="routerLinkActive"
                aria-label="Additional Menu Options"
            >
                <div class="sr-only">Additional Menu Options</div>
                <i class="fa-solid fa-bars fa-fw"></i>
                <div class="labels text-nowrap">More</div>
            </a>

            <a  *ngIf="!(user | userGroup:'admin') || (user | userCan:'task.index')"
                class="col text-center item" routerLink="/dashboard" routerLinkActive="active" #appDashboard="routerLinkActive"
            >
                <i class="icons ic-dashboard" [class.solid]="appDashboard.isActive && !showNotifications"></i>
                <div class="labels text-nowrap">Dashboard</div>
            </a>

            <a class="col text-center item" routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" #appHome="routerLinkActive">
                <i class="icons ic-home" [class.solid]="appHome.isActive && !showNotifications"></i>
                <div class="labels text-nowrap">Home</div>
            </a>

            <a class="col text-center item" routerLink="/messages" routerLinkActive="active" #appMessages="routerLinkActive">
                <i class="icons ic-messages position-relative" [class.solid]="appMessages.isActive && !showNotifications">
                    <span *ngIf="user?.counters?.chats > 0"
                        class="badge rounded-pill bg-danger position-absolute d-inline-block d-lg-none"
                    >{{ user?.counters?.chats | niceCount:0:1000:'' }}</span>
                </i>
                <div class="labels text-nowrap position-relative">
                    Messages

                    <span *ngIf="user?.counters?.chats > 0"
                        class="badge rounded-pill bg-danger position-absolute d-none d-lg-inline-block"
                    >{{ user?.counters?.chats | niceCount:0:1000:'' }}</span>
                </div>
            </a>

            <div class="col text-center item notification-icon-wrap" (click)="onNotificationsToggle($event)" #mobileNotificationTarget aria-label="User Notifications List">
                <i class="icons notification-icon fa-bell fa-fw position-relative"
                    [class.fas]="showNotifications"
                    [class.far]="!showNotifications"
                    [class.solid]="showNotifications"
                >
                    <span *ngIf="user?.counters?.notifications > 0"
                        class="badge rounded-pill bg-danger position-absolute d-inline-block d-lg-none"
                    >{{ user?.counters?.notifications | niceCount:0:1000:'' }}</span>
                </i>
                <div class="labels text-nowrap  position-relative">
                    Notifications

                    <span *ngIf="user?.counters?.notifications > 0"
                        class="badge rounded-pill bg-danger position-absolute d-none d-lg-inline-block"
                    >{{ user?.counters?.notifications | niceCount:0:1000:'' }}</span>
                </div>
            </div>
        </div>
        <div class="notifications dropdown d-block d-lg-none position-static w-auto px-0">
            <ng-container [ngTemplateOutlet]="notificationsPanel" [ngTemplateOutletContext]="{bottom:true}"></ng-container>
        </div>
    </div>
</nav>

<ng-template #profile>
    <ng-container *ngIf="user | userValid">
    <li class="nav-item dropdown notifications d-none d-lg-inline-block" [class.show]="showNotifications">
        <a href class="nav-link dropdown-toggle py-0 px-2 "
            undoClick [targets]="[mobileNotificationTarget]" (onUndoClick)="onNotificationsToggle(null, false)"
            (click)="onNotificationsToggle($event)" aria-haspopup="true" aria-expanded="false"
        >
            <div class="position-relative">
                <i class="notification-icon fa-bell fa-fw far"></i>
                <i class="notification-icon fa-bell fa-fw fas"></i>

                <div *ngIf="user?.counters?.notifications > 0"
                    class="badge rounded-pill bg-danger position-absolute px-2 py-1"
                    style="top: -8px; right: -9px"><small>{{ user?.counters?.notifications | niceCount:0:1000:'' }}</small>
                </div>
            </div>
        </a>

        <span class="d-block d-lg-none h-100"></span>

        <ng-container [ngTemplateOutlet]="notificationsPanel"></ng-container>
    </li>
    <li class="nav-item messages d-none d-lg-inline-block">
        <a routerLink="/messages" class="nav-link" routerLinkActive="active">

            <div class="position-relative">
                <i class="icons ic-messages message-icon"></i>
                <i class="icons ic-messages message-icon solid"></i>

                <div *ngIf="user?.counters?.chats > 0"
                    class="badge rounded-pill bg-danger position-absolute px-2 py-1 d-none d-lg-inline-block"
                    style="top: -5px; right: -8px"><small>{{ user?.counters?.chats | niceCount:0:1000:'' }}</small></div>
            </div>
        </a>
    </li>
    <li class="nav-item dropdown profile">
        <a href class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="User Profile">
            <div class="position-relative d-inline-block me-sm-2">
                <avatar [user]="user" className="ms-0 me-0"></avatar>
            </div>
            <div class="d-none d-md-inline-block profile-name">{{ user | userName }}</div>
        </a>
        <div class="dropdown-menu dropdown-menu-end position-absolute"  style="max-width: min(350px, 90vw)">
            <div class="card border-top-0 border-start-0 border-end-0 rounded-0 mb-2">
                <div class="card-body py-2 mb-1">
                    <div class="row flex-sm-nowrap">
                        <div class="col-sm-auto pe-sm-1 text-center">
                            <avatar [user]="user" size="small" className="mx-0"></avatar>
                        </div>
                        <div class="col-sm ps-sm-1 overflow-hidden">
                            <div class="fw-bold mt-2 text-truncate">{{ user | userFullName }}</div>
                            <div class="text-uppercase text-info text-truncate small" *ngIf="!( user | userGroup:'admin')">{{ user?.group?.title }}</div>
                            <div class="profile-roles" *ngIf="( user | userGroup:'admin')">
                                <div class="text-uppercase text-info text-truncate small" *ngIf="!user?.roles?.length">{{ user?.group?.title }}</div>
                                <span class="badge rounded-pill text-bg-primary" *ngFor="let role of user?.roles">{{ role?.title }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngFor="let name of [
                'profile',
                'preferences',
                'separator',
                'check_in_out',
                'separator',
                'log_out'
            ]">
                <ng-container [ngTemplateOutlet]="itemWrap" [ngTemplateOutletContext]="{name:name, items:menus, children:true}"></ng-container>
            </ng-container>
        </div>
    </li>
    </ng-container>

    <li *ngIf="!(user | userValid)" class="nav-item dropdown d-block d-lg-none profile">
        <a routerLink="/auth/login" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="User Profile">
            <avatar className="px-0 mx-0"></avatar>
        </a>
    </li>

    <ng-container *ngFor="let name of [
        'log_in',
        'sign_up'
    ]">
        <ng-container [ngTemplateOutlet]="itemWrap" [ngTemplateOutletContext]="{name:name, items:menus, hideSeparator:true}"></ng-container>
    </ng-container>

</ng-template>

<ng-template #item let-item="item" let-children="children" let-offcanvas="offcanvas" let-parent="parent">

    <!-- is internal (route) link -->
    <a  *ngIf="!item.disabled && item.route?.length"
        [class]="item.class || ''"
        [ngClass]="{
            'text-nowrap': !offcanvas,
            'text-sm-nowrap px-2 py-3 py-sm-2 px-sm-3 text-lg-center d-flex d-lg-block': offcanvas && !children,
            'nav-link': !children,
            'fw-bold dropdown-item py-3 py-sm-2 px-sm-2': children
        }"
        [attr.data-bs-dismiss]="offcanvas ? 'offcanvas' : null"
        [routerLink]="item.route || null"
        [queryParams]="item.queryParams || {}"
        routerLinkActive="active"
        [routerLinkActiveOptions]="item.exact ? {exact: true} : {}"
        (isActiveChange)="onActiveChange($event, item, parent)"
        [href]="item.href | safe:'url'"
    >
        <span class="badge rounded-pill bg-primary float-end " *ngIf="item.badge?.length">{{ item.badge }}</span>
        <i *ngIf="item?.icon" [class]="item.icon" [class.icon]="true"></i>
        {{ item.title }}
    </a>

    <!-- is external link -->
    <a *ngIf="!item.disabled && !item.route?.length"
        [class]="item.class || ''"
        [ngClass]="{
            'text-nowrap': !offcanvas,
            'text-sm-nowrap px-2 py-sm-2 px-sm-3 text-lg-center d-flex d-lg-block': offcanvas,
            'nav-link': !children,
            'dropdown-item fw-bold': children
        }"
        (isActiveChange)="onActiveChange($event, item, parent)"
        [href]="item.href | safe:'url'"
        (click)="onExternalLink($event, item)"
        target="_blank"
    >
        <i *ngIf="item?.icon" [class]="item.icon" [class.icon]="true"></i>
        {{ item.title }}
    </a>
</ng-template>

<!-- default item wrapper -->
<ng-template #itemWrap let-name="name" let-items="items" let-children="children" let-hideSeparator="hideSeparator" let-offcanvas="offcanvas", let-parent="parent">

    <li *ngIf="items[name] && !items[name].separator && !items[name].hidden && items[name].children && !children"
        class="nav-item dropdown {{ (items[name].classParent || '') }}"
        onclick="event.stopPropagation()"
    >
        <a href class="nav-link px-2 py-3 py-sm-2 px-sm-3 text-lg-center d-flex d-flex-wrap d-lg-block dropdown-toggle" [class.force-show]="parent?.active" data-bs-toggle="dropdown">
            <i *ngIf="items?.[name]?.icon" [class]="items[name].icon" [class.icon]="true"></i>

            <div class="headline">{{ items[name].title }}</div>
            <div class="break"></div>

            <div class="dropdown-menu" [class.force-show]="parent?.active">
                <ng-container *ngFor="let child of items[name].children | keyvalue:keyOrder">
                    <ng-container [ngTemplateOutlet]="itemWrap" [ngTemplateOutletContext]="{
                        name:child.key,
                        items:items[name].children,
                        children:true,
                        hideSeparator:hideSeparator,
                        offcanvas:offcanvas,
                        parent:items[name]
                    }"></ng-container>
                </ng-container>
            </div>
        </a>
    </li>

    <li *ngIf="items[name] && !items[name].separator && !items[name].hidden && !items[name].children && !children"
        class="nav-item {{ (items[name].classParent || '') }}"
    >
        <ng-container [ngTemplateOutlet]="item" [ngTemplateOutletContext]="{item:items[name], children:false, offcanvas:offcanvas, parent:parent}"></ng-container>
    </li>

    <ng-container *ngIf="items[name] && !items[name].separator && !items[name].hidden && !items[name].children && children"
                  [ngTemplateOutlet]="item"
                  [ngTemplateOutletContext]="{item:items[name], children:true, offcanvas:offcanvas, parent:parent}"></ng-container>

    <div *ngIf="items[name] && items[name].separator && !items[name].hidden" class="dropdown-divider" [ngClass]="{'d-none d-lg-block': hideSeparator}"></div>

</ng-template>

<ng-template #notificationsPanel let-bottom="bottom">
    <div class="dropdown-menu dropdown-menu-end position-absolute notifications-panel" [class.show]="showNotifications" [class.bottom-aligned]="bottom">
        <div class="bg-loading" *ngIf="loading && !notifications?.data?.length">
            <i class="fa-solid fa-spinner fa-2x fa-pulse mx-auto text-secondary mx-auto"></i>
        </div>

        <div class="items"
            *ngIf="showNotifications"
            [class.loading]="loading"
            infinite-scroll [scrollWindow]="false"
            [infiniteScrollDistance]="2"
            (scrolled)="onNotificationNext($event)"
        >
            <a *ngFor="let notification of notifications?.data"
                class="dropdown-item"
                [class.active]="!notification.read"
                [routerLink]="notification.url"
                [queryParams]="notification?.queryParamsList || null"
                [fragment]="notification?.fragmentUrl || null"
                (click)="onNotificationClick($event, notification)"
            >
                <div class="row">
                    <div class="col-auto pe-0" *ngIf="notification.photo?.length">
                        <img [src]="notification.photo" alt="" *ngIf="notification.photo?.length" />
                    </div>

                    <div class="col ps-2">
                        <div [innerHTML]="notification.content | safe:'html'"></div>
                        <small class="mt-1 d-block"
                                [class.text-light]="!notification.read"
                                [class.text-dark]="notification.read"
                        >{{ notification.date }}</small>
                    </div>
                </div>
            </a>

            <ng-container *ngIf="!loading && !notifications?.data?.length">
                <p class="text-center text-muted my-3">You don't have any notifications yet.</p>
            </ng-container>
        </div>

        <button *ngIf="notifications?.data?.length && showNotifications"
                type="button"
                [disabled]="loading"
                class="btn btn-primary btn-sm mx-2 mt-2"
                (click)="onNotificationMarkAsRead($event)"
        >
            <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
            Mark All as Read

        </button>
    </div>
</ng-template>

<audio #chatNotification preload src="/assets/sounds/chat-notification.m4a"></audio>
