import { ProjectIndex } from './../projects/project-index.model';

export class ItaskerProject {
    completed?: ProjectIndex;
    unfinished?: ProjectIndex;

    static fromJson(fields): ItaskerProject {
        let item = Object.assign(new this(), fields);

        item.completed = fields?.completed?.map(project => ProjectIndex.fromJson(project));
        item.unfinished = fields?.unfinished?.map(project => ProjectIndex.fromJson(project));

        return item;
    }
};
