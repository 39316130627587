import * as moment from 'moment';

export class JobPayment {

    id?: number;
    title?: string;
    provider?: string;
    amount?: number;
    currency?: string;
    order?: string;
    status?: string;
    created?: string;
    updated?: string;

    get createdFormatted(): string {
        return moment.utc(this.created).format('ll');
    }

    get updatedFormatted(): string {
        return moment.utc(this.updated).format('ll');
    }

    static fromJson(fields): JobPayment {
        let item = Object.assign(new this(), fields);

        return item;
    }

}
