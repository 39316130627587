import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
    {
        canActivate: [AuthGuard],
        path: 'about',
        loadChildren: () => import('src/app/about/about.module').then(m => m.AboutModule),
        data: {
            name: 'page',
            title: 'About',
        }
    }, {
        canActivate: [AuthGuard],
        path: 'profile',
        loadChildren: () => import('src/app/profile/profile.module').then(m => m.ProfileModule),
        data: {
            name: 'page',
            title: 'Profile',
        }
    }, {
        canActivate: [AuthGuard],
        path: 'become-itasker',
        loadChildren: () => import('src/app/become-itasker/become-itasker.module').then(m => m.BecomeItaskerModule),
        data: {
            name: 'page',
            groups: ['itasker'],
            title: 'Become an iTasker'
        }
    }, {
        canActivate: [AuthGuard],
        path: 'tasks',
        loadChildren: () => import('src/app/tasks/tasks.module').then(m => m.TasksModule),
        data: {
            name: 'page',
            title: 'Tasks'
        }
    }, {
        canActivate: [AuthGuard],
        path: 'dashboard',
        loadChildren: () => import('src/app/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
            name: 'page',
            guest: false,
            title: 'Dashboard',
        }
    }, {
        canActivate: [AuthGuard],
        path: 'neighborhood',
        loadChildren: () => import('src/app/circles/circles.module').then(m => m.CirclesModule),
        data: {
            footer: true,
            name: 'circles',
            title: 'Neighborhood',
            search: {
                visible: false,
                route: '/neighborhood',
                placeholder: 'Search groups...'
            }
        }
    }, {
        path: 'tax-assistant',
        loadChildren: () => import('src/app/tax-assistant/tax-assistant.module').then(m => m.TaxAssistantModule),
        data: {
            footer: true,
            name: 'page',
            title: 'Tax Assistant',
        }
    }, {
        canActivate: [AuthGuard],
        path: 'task-types',
        loadChildren: () => import('src/app/types/types.module').then(m => m.TypesModule),
        data: {
            name: 'page',
            guest: false,
            title: 'Types',
            groups: ['admin'],
            permissions: ['task.type.index'],
        }
    }, {
        canActivate: [AuthGuard],
        path: 'custom-forms',
        loadChildren: () => import('src/app/custom-forms/custom-forms.module').then(m => m.CustomFormsModule),
        data: {
            name: 'Custom Forms',
            guest: false,
            title: 'Custom Forms',
            groups: ['admin'],
            permissions: ['customform.index']
        }
    }, {
        canActivate: [AuthGuard],
        path: 'messages',
        loadChildren: () => import('src/app/messages/messages.module').then(m => m.MessagesModule),
        data: {
            name: 'page',
            guest: false,
            title: 'Messages'
        }
    }, {
        canActivate: [AuthGuard],
        path: 'teams',
        loadChildren: () => import('src/app/teams/teams.module').then(m => m.TeamsModule),
        data: {
            name: 'page',
            groups: ['admin', 'itasker'],
            permissions: ['team.index'],
            title: 'Teams'
        }
    }, {
        canActivate: [AuthGuard],
        path: 'workers',
        loadChildren: () => import('src/app/workers/workers.module').then(m => m.WorkersModule),
        data: {
            name: 'page',
            groups: ['admin', 'itasker'],
            permissions: ['worker.index'],
            title: 'Workers'
        }
    // }, {
    //     canActivate: [AuthGuard],
    //     path: 'documents',
    //     loadChildren: () => import('src/app/documents/documents.module').then(m => m.DocumentsModule),
    //     data: {
    //         name: 'page',
    //         groups: ['itasker'],
    //         title: 'Documents'
    //     }
    }, {
        canActivate: [AuthGuard],
        path: 'itasker-clients',
        loadChildren: () => import('src/app/itasker-clients/itasker-clients.module').then(m => m.ItaskerClientsModule),
        data: {
            name: 'page',
            groups: ['itasker'],
            title: 'Clients'
        }
    }, {
        canActivate: [AuthGuard],
        path: 'preferences',
        loadChildren: () => import('src/app/preferences/preferences.module').then(m => m.PreferencesModule),
        data: {
            name: 'page',
            groups: ['admin'],
            permissions: ['preference.index'],
            title: 'Global Preferences'
        }
    }, {
        canActivate: [AuthGuard],
        path: 'settings',
        loadChildren: () => import('src/app/settings/settings.module').then(m => m.SettingsModule),
        data: {
            name: 'page',
            groups: ['admin'],
            permissions: ['setting.index'],
            title: 'System Settings'
        }
    }, {
        canActivate: [AuthGuard],
        path: 'stats',
        loadChildren: () => import('src/app/stats/stats.module').then(m => m.StatsModule),
        data: {
            name: 'page',
            groups: ['admin'],
            permissions: ['tracking.index'],
            title: 'Stats'
        }
    }, {
        canActivate: [AuthGuard],
        path: 'finances',
        loadChildren: () => import('src/app/finances/finances.module').then(m => m.FinancesModule),
        data: {
            name: 'page',
            title: 'Finances'
        }
    }, {
        path: 'invoices/proforma/:uuid',
        redirectTo: 'finances/proforma/:uuid',
    }, {
        canActivate: [AuthGuard],
        path: 'services',
        loadChildren: () => import('src/app/services/services.module').then(m => m.ServicesModule),
        data: {
            name: 'page',
            title: 'Services'
        }
    }, {
        canActivate: [AuthGuard],
        path: 'services-manage',
        loadChildren: () => import('src/app/services-manage/services-manage.module').then(m => m.ServicesManageModule),
        data: {
            name: 'page',
            title: 'Services'
        }
    }, {
        canActivate: [AuthGuard],
        path: 'users',
        loadChildren: () => import('src/app/users/users.module').then(m => m.UsersModule),
        data: {
            name: 'page',
            title: 'Users',
            groups: ['admin'],
            permissions: ['user.index'],
        }
    }, {
        canActivate: [AuthGuard],
        path: 'roles',
        loadChildren: () => import('src/app/roles/roles.module').then(m => m.RolesModule),
        data: {
            name: 'page',
            title: 'Roles',
            groups: ['admin'],
            permissions: ['role.index'],
        }
    }, {
        canActivate: [AuthGuard],
        path: 'companies',
        loadChildren: () => import('src/app/companies/companies.module').then(m => m.CompaniesModule),
        data: {
            name: 'page',
            title: 'Companies',
            groups: ['admin'],
            permissions: ['company.index'],
        }
    }, {
        canActivate: [AuthGuard],
        path: 'pages',
        loadChildren: () => import('src/app/pages/pages.module').then(m => m.PagesModule),
        data: {
            name: 'page',
            title: 'Pages',
            groups: ['admin'],
            permissions: ['article.index'],
        }
    }, {
        canActivate: [AuthGuard],
        path: 'categories',
        loadChildren: () => import('src/app/categories/categories.module').then(m => m.CategoriesModule),
        data: {
            name: 'page',
            title: 'Categories',
            groups: ['admin'],
            permissions: ['category.index'],
        }
    }, {
        canActivate: [AuthGuard],
        path: 'shortcuts',
        loadChildren: () => import('src/app/shortcuts/shortcuts.module').then(m => m.ShortcutsModule),
        data: {
            name: 'page',
            title: 'Shortcuts',
            groups: ['admin'],
            permissions: ['shortcut.index'],
        }
    }, {
        canActivate: [AuthGuard],
        path: 'promo-codes',
        loadChildren: () => import('src/app/promo-codes/promo-codes.module').then(m => m.PromocodesModule),
        data: {
            name: 'page',
            title: 'Promo Codes',
            groups: ['admin'],
            permissions: ['promocode.index'],
        }
    }, {
        canActivate: [AuthGuard],
        path: 'task-codes',
        loadChildren: () => import('src/app/task-codes/task-codes.module').then(m => m.TaskcodesModule),
        data: {
            name: 'page',
            title: 'Tasks Codes',
            groups: ['itasker', 'admin'],
            permissions: ['taskcode.index'],
        }
    }, {
        canActivate: [AuthGuard],
        path: 'connections',
        loadChildren: () => import('src/app/connections/connections.module').then(m => m.ConnectionsModule),
        data: {
            name: 'page',
            title: 'Connections',
        }
    }, {
        canActivate: [AuthGuard],
        path: 'auth',
        loadChildren: () => import('src/app/auth/auth.module').then(m => m.AuthModule),
        data: {
            name: 'page',
        }
    }, {
        canActivate: [AuthGuard],
        path: '',
        children: [{
            path: '',
            loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule),
            data: {
                footer: true,
            }
        }, {
            path: 's',
            loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule),
        }, {
            path: 'discount-club',
            loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule),
        }],
    }, {
        path: 'errors',
        loadChildren: () => import('src/app/errors/errors.module').then(m => m.ErrorsModule)
    }, {
        path: '**',
        redirectTo: '/errors/404'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {initialNavigation: 'enabled'}),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
