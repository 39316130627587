export class AssetUpload {
    id?: number;
    title?: string;
    description?: string;
    path?: string;
    mime?: string;
    size?: number;
    status?: ('active'|'inactive');

    static fromJson(fields: any): AssetUpload {
        let item = Object.assign(new this, fields);

        return item;
    }
}
