import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'niceCount',

})
export class NiceCountPipe implements PipeTransform {
    protected readonly SUFFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']

    transform(value: number|string, decimals: number = 0, divider: number = 1000, separator: string = ' '): string {
        value = typeof value !== 'number' ? parseInt(value || '', 10)
            : value;

        if (value <= 0 || value === null) {
            return '0';
        }

        const dm = decimals < 0 ? 0 : decimals;
        const exponent = Math.floor(Math.log(value) / Math.log(divider));

        return parseFloat((value / Math.pow(divider, exponent)).toFixed(dm))
            + separator
            + (this.SUFFIXES[exponent] ?? '');
    }
}
