import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, BehaviorSubject, of, from } from 'rxjs';
import { map, share, mergeMap } from 'rxjs/operators';


import { ApiService } from './api.service';
import { CookieService } from './cookie.service';
import { ProfileService } from './profile.service';
import { UserAuth } from 'src/app/shared/models/user-auth.model';
import { User } from 'src/app/shared/models/user.model';
import { ReactNativeService } from './react-native.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

export interface AuthServiceStatus {
    isSigned: boolean
};

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    protected readonly USER_TOKEN_NAME: string = 'user';
    protected readonly COOKIE_DAYS_EXPIRE: number = 365;

    protected static isLoaded: boolean = false;
    protected subjectReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
    protected subjectStatus: BehaviorSubject<AuthServiceStatus> = new BehaviorSubject({
        isSigned: false,
    });

    public user: User = new User();

    constructor(
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
        private api: ApiService,
        private router: Router,
        private profileService: ProfileService,
        private cookieService: CookieService,
        private reactNativeService: ReactNativeService,
    ) {
        if (AuthService.isLoaded && !isPlatformServer(this.platform)) {
            return;
        }
        AuthService.isLoaded = true;

        this.userLoad();

        if (!this.user.isValid() && this.user.hasToken()) {
            this.getActualProfileData().then((data: any) => {}).catch(e => {});
        } else {
            const isValid = this.user.isValid();

            if (isValid) {
                this.api.updateToken(this.user.getToken());
            }

            this.subjectReady.next(true);
            this.subjectStatus.next({
                isSigned: isValid,
            });
        }

    }

    register(data): Observable<any> {
        return this.api.post('/auth/register', data);
    }

    login({email, password, phone_token = null, phone_os = null}): Observable<UserAuth> {
        return this.api.post('/auth/login', {
            email,
            password,
            phone_token: phone_token || undefined,
            phone_os: phone_os || undefined,
        }).pipe(map((data: {data: UserAuth}) => {
            let profile: User = "data" in data && data.data && 'user' in data.data ? data.data.user : null;
            let token: string = "data" in data && data.data && 'token' in data.data ? data.data.token : '';

            this.api.updateToken(token);

            this.saveToken(token);

            this.userStore(profile, token);
            this.subjectReady.next(true);
            this.subjectStatus.next({
                isSigned: this.user.isValid(),
            });

            return data.data;
        }));
    }

    loginAs(token: string): Observable<User> {
        this.user.setToken(token);
        this.api.updateToken(token);
        this.saveToken(token);

        return this.profileService.getProfile().pipe(
            map(profile => {
                this.userStore(profile.data, token);
                this.subjectReady.next(true);
                this.subjectStatus.next({
                    isSigned: this.user.isValid(),
                });

                return profile.data;
            })
        );
    }

    /**
     * Performs a proper logout to the system, which should make token invalidated
     * @param {boolean} skipToken Skip the logout API request
     */
    logout(skipToken: boolean = false, isPhone: boolean = false): Observable<any> {
        let final: () => any = () => {
            this.api.updateToken('');
            this.user = new User();
            this.removeToken();
            this.subjectReady.next(true);
            this.subjectStatus.next({
                isSigned: this.user.isValid(),
            });
        };

        if (skipToken) {
            final();
            return of({}).pipe(map((data: any) => {
                return data;
            }));
        }

        let data = {...(isPhone ? {phone: true} : {})};

        return from(this.reactNativeService.onLogout()).pipe(mergeMap(val =>
            this.api.post('/auth/logout', data)
                .pipe(map((data: any) => {
                    final();
                    return data;
                }))
        ));
    }

    /**
     * Send an email with reset link
     * @param
     */
    forgot({email}): Observable<UserAuth> {
        return this.api.post('/auth/forgot', {email});
    }

    /**
     * Reset password
     * @param
     */
    reset({email, password, token}): Observable<UserAuth> {
        return this.api.post('/auth/reset', {email, password, token});
    }

    /**
     * Get authentication status changes over time
     */
    getStatus(): Observable<AuthServiceStatus> {
        return this.subjectStatus.asObservable().pipe(share());
    }

    /**
     * Returns status of the authentication process, i.e. is in progress (false) or finished (true)
     */
    getAuthReady(): Observable<boolean> {
        return this.subjectReady.asObservable().pipe(share());
    }

    /**
     * Update profile data using server
     */
    getActualProfileData(): Promise<boolean> {
        return new Promise((resolve, rejected) => {

            this.api.updateToken(this.user.getToken());

            let onError: () => void = (): void => {
                this.logout(true).subscribe((data: any) => {
                    this.subjectReady.next(true);
                    this.subjectStatus.next({
                        isSigned: this.user.isValid(),
                    });

                    // In order to force NavigationEnd events firing
                    setTimeout(() => {
                        this.router.navigate(['/']);
                        resolve(this.user.isValid());
                    });
                }, error => {
                    rejected(error);
                });
            };

            this.profileService.getProfile().subscribe(profile => {
                this.userStore(profile.data);
                this.subjectReady.next(true);
                this.subjectStatus.next({
                    isSigned: this.user.isValid(),
                });

                resolve(this.user.isValid());
            }, error => {
                onError();
                rejected(error);
            });
        });
    }

    /**
     * Get User Token
     */
    protected userLoad(): void {
        const token: string = this.loadToken();
        this.user.setToken(token);
    }

    protected userStore(profile: User, token: string = ''): void {
        const userToken = token || this.user?.token;
        this.user = User.fromJson(profile);
        this.user.token = userToken || this.user.token || '';
        this.api.updateToken(this.user.getToken());
    }

    protected saveToken(token): void {
        try {
            this.cookieService.set(this.USER_TOKEN_NAME, token, 365, '/');

            if (isPlatformBrowser(this.platform) && 'localStorage' in window) {
                localStorage.setItem(this.USER_TOKEN_NAME, token);
            }
        } catch (e) { }
    }

    protected loadToken(saveMissing: boolean = true): string|null {
        try {
            let cookieToken: string = this.cookieService.get(this.USER_TOKEN_NAME);
            let storageToken: string = (isPlatformBrowser(this.platform) && 'localStorage' in window)
                ? localStorage.getItem(this.USER_TOKEN_NAME)
                : '';

            const token = cookieToken || storageToken || '';

            saveMissing && this.saveToken(token);

            return token;
        } catch (e) {
            return null;
        }
    }

    protected removeToken(): void {
        try {
            this.cookieService.delete(this.USER_TOKEN_NAME);

            if (isPlatformBrowser(this.platform) && 'localStorage' in window) {
                localStorage.removeItem(this.USER_TOKEN_NAME)
            }
        } catch (e) { }
    }
}
