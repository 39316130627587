import { User } from './../user.model';
import { AssetEvent } from './asset-event.model';
import { AssetCategory } from './asset-category.model';
import { AssetUpload } from './asset-upload.model';

export type AssetType = ('movable'|'immovable');
export type AssetStatus = ('active'|'inactive');

export class Asset {
    id?: number;
    title?: string;
    brand?: string;
    model?: string;
    serial_number?: string;
    notes?: string;
    event_initial?: string;
    event_next?: string;
    template?: boolean;
    type?: AssetType;
    status?: AssetStatus;
    created?: string;
    updated?: string;
    user?: User;
    category?: AssetCategory;
    events?: AssetEvent[];
    uploads?: AssetUpload[];
    tags?: string[];

    static fromJson(fields: any): Asset {
        let item = Object.assign(new this(), fields);

        item.user = User.fromJson(fields?.user);
        item.category = AssetCategory.fromJson(fields?.category);
        item.events = fields?.events?.map((item: any) => AssetEvent.fromJson(item));
        item.uploads = fields?.uploads?.map((item: any) => AssetUpload.fromJson(item));

        return item;
    }
}
