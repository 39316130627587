import { Invoice } from './../invoice.model';
import { Job } from './job.model';

export class JobInvoice extends Invoice {
    job: Job;

    static fromJson(fields): Invoice {
        let item: JobInvoice = super.fromJson(fields) as JobInvoice;
        item.job = Job.fromJson(fields.job);
        return item;
    }
}
