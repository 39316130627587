import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { UserCompact } from '../models/user.model';

type ReactNativeEvents = (
     'route'
    |'logout'
    |'phone'
    |'user'
    |'share'
);

@Injectable({
    providedIn: 'root'
})
export class ReactNativeService {
    protected readonly MOBILE_APP_ID: string = 'com.itask';
    protected readonly REACT_NATIVE_COOKIE_NAME: string = this.MOBILE_APP_ID + '.apps';
    protected messages: Subject<any> = new Subject();
    protected urlChange: boolean = false;

    protected innerAppChooserSeen: boolean = null;
    protected set appChooserSeen(val: boolean) {
        if (this.innerAppChooserSeen !== val) {
            this.innerAppChooserSeen = val;

            if (!isPlatformServer(this.platform) && typeof sessionStorage !== 'undefined') {
                sessionStorage.setItem(this.REACT_NATIVE_COOKIE_NAME, '' + this.innerAppChooserSeen);
            }
        }
    }
    protected get appChooserSeen(): boolean {
        return this.innerAppChooserSeen;
    }

    constructor(
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
        @Optional() @Inject(REQUEST) private request: Request,
    ) {
        if (isPlatformServer(this.platform) || typeof sessionStorage === 'undefined') {
            this.innerAppChooserSeen = false;
        } else {
            this.innerAppChooserSeen = sessionStorage.getItem(this.REACT_NATIVE_COOKIE_NAME) === 'true';
        }
    }

    isMobile(): boolean {
        return this.isReactNativeWebView()
            || this.isReactNativeServerRequest();
    }

    isReactNativeWebView(): boolean {
        return 'ReactNativeWebView' in window
            && window['ReactNativeWebView']
            && 'postMessage' in window['ReactNativeWebView'];
    }

    isReactNativeServerRequest(): boolean {
        if (!isPlatformServer(this.platform)) {
            return false;
        }

        for (let key in this.request?.headers) {
            if (
                ['x-requested-with', 'x-app-id'].indexOf(key.toLowerCase()) !== -1
                && (this.request?.headers[key] || '').indexOf(this.MOBILE_APP_ID + '.') !== -1
            ) {
                return true;
            }
        }
        return false;
    }

    sendData(event: ReactNativeEvents, data: any) {
        if (typeof window === 'undefined' || !window) {
            return;
        }

        if (this.isReactNativeWebView()) {
            window['ReactNativeWebView']?.postMessage(JSON.stringify({
                event,
                data,
            }));
        }
    }

    messageReceived(data: any): void {
        this.messages.next(data);
    }

    notifyUrlChange(url: string) {
        if (!this.urlChange) { // skip first time
            this.urlChange = true;
            return;
        }
        this.sendData('route', url);
    }

    notifyLogout() {
        this.sendData('logout', null);
    }

    onLogout(): Promise<any> {
        let subscription = null;
        let timeout = null;

        const cleanUp: (data?: any) => void = (data?: any): void => {
            timeout && clearTimeout(timeout);
            subscription && subscription.unsubscribe();
        }

        return new Promise((resolve, reject) => {
            if (!this.isReactNativeWebView()) {
                resolve(null);
            } else {
                subscription = this.messages.pipe(share()).subscribe(data => {
                    cleanUp(data);
                    resolve(null);
                });
                this.notifyLogout();

                // if nothing is received => resolve anyway so user don't wait to log out
                timeout = setTimeout(() => {
                    cleanUp();
                    resolve(null);
                }, 300);
            }
        });
    }

    onShare(): Promise<boolean> {
        let subscription = null;
        let timeout = null;

        return new Promise((resolve, reject) => {
            if (!this.isReactNativeWebView()) {
                resolve(false);
            } else {
                subscription = this.messages.pipe(share()).subscribe(data => {
                    resolve(true);
                });

                // if nothing is received => resolve anyway
                timeout = setTimeout(() => {
                    resolve(false);
                }, 300);
            }
        });
    }

    notifyUserChange(user: UserCompact): void {
        this.sendData('user', user);
    }

    getPhoneDetails(): Promise<{phone_token?: string, phone_os?: string, phone_model?: string}> {
        let subscription = null;
        let timeout = null;

        const cleanUp: () => void = (): void => {
            timeout && clearTimeout(timeout);
            subscription && subscription.unsubscribe();
        }

        return new Promise((resolve, reject) => {
            if (!this.isReactNativeWebView()) {
                reject();
            } else {
                subscription = this.messages.pipe(share()).subscribe(data => {
                    cleanUp();

                    if (data?.data && typeof data?.data === 'object') {
                        resolve({
                            phone_token: data.data?.token,
                            phone_os: data.data?.os,
                            phone_model: data.data?.model
                        });
                    } else {
                        reject();
                    }
                });

                this.sendData('phone', {});

                // if nothing is received => resolve anyway so user don't wait to log out
                timeout = setTimeout(() => {
                    cleanUp();
                    reject();
                }, 500);
            }
        });
    }

    isAppChooserSeen(): boolean {
        return this.appChooserSeen;
    }

    setAppChooserSeen(seen: boolean = true) {
        this.appChooserSeen = seen;
    }

    filteredDropdownHandler(event?: any, className: string = 'p-inputtext'): void {
        if (this.isTouchDevice()) {
            const input = ('element' in event && 'querySelector' in event?.element) ? event?.element?.querySelector('input' + (className ? '.' + className : ''))
                : ('el' in event && 'nativeElement' in event?.el) ? event?.el?.nativeElement?.querySelector('input' + (className ? '.' + className : ''))
                : event?.querySelector('input');

            input && input.blur();
        }

        const input = ('element' in event && 'querySelector' in event?.element) ? event?.element?.querySelector('input' + (className ? '.' + className : ''))
            : ('el' in event && 'nativeElement' in event?.el) ? event?.el?.nativeElement?.querySelector('input' + (className ? '.' + className : ''))
            : event?.querySelector('input');

        input && input.blur();
    }

    isTouchDevice() {
        return (typeof window !== 'undefined' && 'ontouchstart' in window)
            || (
                typeof navigator !== 'undefined' && (
                    navigator.maxTouchPoints > 0
                    || navigator['msMaxTouchPoints'] > 0
                )
            );
    }
}
