import { JobService } from "../jobs/job-service.model";
import { User } from "../user.model";
import { ItaskerProject } from './itasker-project.model';
import { Review } from "./review.model";
import { Reference } from "./reference.model";
import { ServiceCategory } from "../service-category.model";


export interface ItaskerPosition {
    label: string;
    value: string;
};

export const ItaskerPositions: ItaskerPosition[] = [
    {
        value: 'owner',
        label: 'Owner',
    }, {
        value: 'director',
        label: 'Director',
    }, {
        value: 'manager',
        label: 'Manager',
    }, {
        value: 'executive',
        label: 'Executive',
    }, {
        value: 'other',
        label: 'Other',
    }
];

export class Itasker {
    id: number = 0;
    rating?: number = 0;
    reviews?: number = 0;
    connections?: number = 0;
    is_connected?: boolean = false;
    is_connectable?: boolean = false;
    uuid?: string;
    name_first?: string;
    name_last?: string;
    company_position?: string;
    email?: string;
    phone?: string;
    wsib?: string;
    tax_number?: string;
    business_name?: string;
    address_street?: string;
    address_street_number?: string;
    address_unit?: string;
    address_country?: string;
    address_province?: string;
    address_city?: string;
    address_zip_code?: string;
    website?: string;
    learn_about?: string;
    outer_tasks?: number;
    insurance_email?: string;
    contact_agent?: ('active'|'inactive');
    insurance_number?: string;
    insurance_file?: string;
    banking_province_id?: number;
    transit_number?: string;
    institution_number?: string;
    insurance_file_url?: string;
    account_number?: string;
    paid?: number;
    user?: User;
    cover_photo?: string;
    logo?: string;
    description?: string;
    category?: ServiceCategory;
    services?: JobService[];
    references?: Reference[];
    projects?: ItaskerProject;
    projects_count?: number = 0;
    projects_total_count?: number = 0;
    reviews_list?: Review[];
    areas: any [];
    trusted?: boolean = false;
    completion?: {
        all?: boolean,
        services?: boolean,
        references?: boolean,
        payment?: boolean,
        teams?: boolean,
        teams_approved?: boolean,
    };
    created_at?: string;
    updated_at?: string;

    get position(): string {
        return (ItaskerPositions.filter(item => item.value === this.company_position)[0] || {}).label || this.company_position;
    }

    get name(): string {
        return this.name_first;
    }

    static fromJson(fields): Itasker {
        let item = Object.assign(new this(), fields);
        item.user = User.fromJson(fields?.user);
        item.services = fields?.services?.map(service => JobService.fromJson(service));
        item.projects = ItaskerProject.fromJson(fields?.projects);
        item.references = fields?.references?.map(project => Reference.fromJson(project));
        item.category = ServiceCategory.fromJson(fields?.category);
        return item;
    }

    copy(): Itasker {
        return Itasker.fromJson(this);
    }
};
