import * as moment from 'moment';

export class JobAppointment {
    protected innerStart: string = null;
    protected innerEnd: string = null;

    // default model fields

    id?: number;

    set start(value: string) {
        if (this.innerStart !== value) {
            this.innerStart = value;
            this.momentStart = moment(this.innerStart);
        }
    }
    get start(): string{
        return this.innerStart;
    }
    set end(value: string) {
        if (this.innerEnd !== value) {
            this.innerEnd = value;
            this.momentEnd = moment(this.innerEnd);
        }
    }
    get end(): string{
        return this.innerStart;
    }

    // custom fields

    momentStart: moment.Moment = null;
    momentEnd: moment.Moment = null;

    getRange(): {date: string, from: string, to: string} {
        return {
            date: this.momentStart?.format('ddd, ll'),
            from: this.momentStart?.format('LT'),
            to: this.momentEnd?.format('LT'),
        };
    }

    get range(): string {
        const diff = this.momentEnd?.diff(this.momentStart, 'hours');
        return [
            this.momentStart?.format('llll'),
            ' ',
            (diff > 24
                ? 'to ' + this.momentEnd?.format('llll')
                : '– ' + this.momentEnd?.format('LT')
            )
        ].join('');
    }

    get rangeHours(): string {
        return [
            this.momentStart?.format('LT'),
            ' ',
            this.momentEnd?.format('LT')
        ].join('');
    }

    get old(): boolean {
        return this.momentEnd?.isBefore(moment());
    }

    get rangeTimestamp(): {start: number, end: number} {
        return {
            start: this.momentStart?.unix(),
            end: this.momentEnd?.unix()
        };
    }

    constructor() {
        this.momentStart = moment(this.start);
        this.momentEnd = moment(this.end);
    }

    static fromJson(fields): JobAppointment {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
