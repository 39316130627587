import { User } from "../user.model";
import { CircleEventLocation } from './circle-event-location.model';
import { CircleEventLink } from './circle-event-link.model';

export type CircleEventStatus = ('active'|'inactive');
export type CircleEventVisibility = ('public'|'private');
export type CircleEventStatusInterested = ('interested'|'uninterested'|'undecided');

export class CircleEvent {
    id?: number;
    title?: string;
    description?: string;
    image?: string;
    url?: string;
    links?: CircleEventLink[];
    event?: string;
    user?: User;
    counts?: {
        interested?: number;
        uninterested?: number;
        viewed?: number;
    };
    location?: CircleEventLocation;
    visibility?: CircleEventVisibility;
    status?: CircleEventStatus;
    pinned?: boolean;
    status_interested?: CircleEventStatusInterested;
    created?: string;
    updated?: string;
    can?: {
        interest?: boolean;
        participants?: boolean;
        status?: boolean;
        modify?: boolean;
        delete?: boolean;
    };

    // custom
    isUpdated?: boolean = false;
    isPast?: boolean = false;
    isFuture?: boolean = false;

    static fromJson(fields): CircleEvent {
        let item = Object.assign(new this(), fields);
        item.user = User.fromJson(fields?.user);
        item.description = ('' + (fields?.description || '')).trim();
        item.isUpdated = fields?.updated?.length && fields?.created != item.updated;
        item.location = CircleEventLocation.fromJson(fields?.location);
        item.links = fields?.links?.map(item => CircleEventLink.fromJson(item));

        const dateEvent = new Date(fields?.event);

        item.isPast = dateEvent < new Date();
        item.isFuture = !item.isPast;

        return item;
    }
}

