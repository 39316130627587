import { ProjectStatus } from "./project-types";

export class ProjectImage {
    id?: number;
    title?: string = '';
    description?: string = '';
    path?: string = '';
    mime?: string = '';
    url?: string = '';
    size?: number = 0;
    status?: ProjectStatus;

    static fromJson(fields: any): ProjectImage {
        let item = Object.assign(new this(), fields);
        return item;
    }
};
