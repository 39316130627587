export type CustomFormFieldGroupStatus = ('active'|'inactive');

export class CustomFormFieldGroup {
    id?: number;
    field: number;
    status?: CustomFormFieldGroupStatus
    created?: string;
    updated?: string;

    static fromJson(fields: any): CustomFormFieldGroup {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
