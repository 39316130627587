export type CustomFormTypeStatus = ('active'|'inactive');
export type CustomFormTypeName = (
    'input'
    |'textarea'
    |'select'
    |'checkbox'
    |'radio'
    |'date'
    |'datetime'
    |'upload'
    |'p'
    |'hr'
    |'section'
    |'group'
    |'result'
    |'toggler'
);


export class CustomFormType {
    id?: number;
    title?: string;
    icon?: string;
    name?: CustomFormTypeName;
    with_options?: boolean;
    with_groups: boolean;
    available_rules?: string[];
    status?: CustomFormTypeStatus;
    created?: string;
    updated?: string;

    static fromJson(fields: any): CustomFormType {

        let item = Object.assign(new this(), fields);

        return item;
    }
}
