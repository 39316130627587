export type CustomFormFieldRuleStatus = ('active'|'inactive');
export type CustomFormFieldRuleType = ('required'|'min'|'max'|'minlength'| 'maxlength'|'min_options'|'max_options'|'email');

export class CustomFormFieldRule {
    id?: number;
    rule?: CustomFormFieldRuleType;
    value?: number;
    message?: string;
    status?: CustomFormFieldRuleStatus
    created?: string;
    updated?: string;

    static fromJson(fields: any): CustomFormFieldRule {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
