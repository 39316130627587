export type CustomFormFieldOptionStatus = ('active'|'inactive');

export class CustomFormFieldOption {
    id?: number;
    title?: string;
    value?: string|string[];
    status?: CustomFormFieldOptionStatus
    created?: string;
    updated?: string;

    static fromJson(fields: any): CustomFormFieldOption {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
