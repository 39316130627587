export class CustomFormTemplateFieldOption {
    id?: number;
    title?: string;
    value?: string;

    static fromJson(fields: any): CustomFormTemplateFieldOption {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
