import { CustomFormFieldOption } from './custom-form-field-option.model';
import { CustomFormFieldRule } from './custom-form-field-rule.model';
import { CustomFormType } from './custom-form-type.model';
import { CustomFormFieldGroup } from './custom-form-field-group.model';

export type CustomFormFieldStatus = ('active'|'inactive');

export class CustomFormField {
    id?: number;
    title?: string;
    description?: string;
    tags?: string;
    field?: number;
    field_template?: number;
    type?: CustomFormType;
    status?: CustomFormFieldStatus;
    options?: CustomFormFieldOption[] = [];
    rules?: CustomFormFieldRule[] = [];
    groups?: CustomFormFieldGroup[] = [];
    value?: any;
    created?: string;
    updated?: string;

    static fromJson(fields: any): CustomFormField {
        let item = Object.assign(new this(), fields);

        item.type = CustomFormType.fromJson(fields?.type);
        item.options = fields?.options?.map(item => CustomFormFieldOption.fromJson(item));
        item.rules = fields?.rules?.map(item => CustomFormFieldRule.fromJson(item));
        item.groups = fields?.groups?.map(item => CustomFormFieldGroup.fromJson(item));

        return item;
    }
}
