import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { Setting } from '../models/setting.model';

import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class SettingService {
    protected settings: BehaviorSubject<Setting[]> = new BehaviorSubject([]);

    constructor(
        private api: ApiService
    ) { }

    /**
     * Get List of Settings
     */
    getItems(): Observable<{data: Setting[]}> {
        return this.api.get('/settings').pipe(map(data => {
            data.data = data.data?.map(item => Setting.fromJson(item));
            this.settings.next(data.data);
            return data;
        }));
    }

    /**
     * Edit List of Settings
     */
    editItems(data: any): Observable<{data: Setting[]}> {
        return this.api.post('/settings', data).pipe(map(data => {
            data.data = data.data?.map(item => Setting.fromJson(item));
            this.settings.next(data.data);
            return data;
        }));
    }

    /**
     * Returns current settings that are fetched last time
     */
    getCurrentSettings(): Observable<Setting[]> {
        return this.settings.asObservable().pipe(share());
    }

    /**
     * Get specific setting's value
     * @param name
     * @param defaultValue
     * @returns
     */
    getSetting(name: string, defaultValue?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscribe = this.getCurrentSettings().subscribe(data => {
                resolve(data.find(setting => setting.name === name)?.value ?? defaultValue);
            }, error => {
                reject(error);
            });
        });
    }
}
