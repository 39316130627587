import * as moment from 'moment';
import { Itasker } from './itaskers/itasker.model';
import { Project } from './projects/project.model';

import { Job } from "./jobs/job.model";
import { Team } from './team.model';
import { User } from './user.model';
import { Circle } from './circles/circle.model';
import { CircleMember } from './circles/circle-member.model';
import { CirclePost } from './circles/circle-post.model';
import { Comment } from './comment.model';
import { CircleEvent } from './circles/circle-event.model';
import { Asset } from './assets/asset.model';

export type NotificationItemType = (
    'JobAddedNotification'
    | 'JobAssignedNotification'
    | 'JobHiringNotification'
    | 'JobCancelNotification'
    | 'InvoiceSendNotification'
    | 'InvoiceRejectedNotification'
    | 'InvoiceApprovedNotification'
    | 'InvoiceUnauthorizedNotification'
    | 'QuoteUnauthorizedNotification'
    | 'AnonymousInvoiceNotification'
    | 'AnonymousQuoteNotification'
    | 'JobSuggestionNotification'
    | 'JobAppointmentNotification'
    | 'JobFollowupNotification'
    | 'JobFollowupConfirmNotification'
    | 'JobFollowupCancelNotification'
    | 'JobStatusNotification'
    | 'JobStatusSystemNotification'
    | 'JobRatedNotification'
    | 'TeamNotification'
    | 'WorkerNotification'
    | 'ReportIssueNotification'
    | 'ItaskerProjectRejectNotification'
    | 'ReportNotification'
    | 'JobRequestedNotification'
    | 'CircleRequestNotification'
    | 'CircleAcceptNotification'
    | 'CircleRejectNotification'
    | 'CirclePostNotification'
    | 'CircleCommentNotification'
    | 'CircleEventNotification'
    | 'AssetEventNotification'
    | 'CompanyNotification'
);

export class NotificationItem {
    id?: string;
    type?: NotificationItemType;
    data?: any;
    read?: string;
    created?: string;
    updated?: string;

    static fromJson(fields: any): NotificationItem {
        let item = Object.assign(new this(), fields);

        const type: NotificationItemType = item.type;

        if (([
            'JobAddedNotification',
            'JobAssignedNotification',
            'JobHiringNotification',
            'JobCancelNotification',
            'JobSuggestionNotification',
            'InvoiceSendNotification',
            'InvoiceRejectedNotification',
            'InvoiceApprovedNotification',
            'InvoiceUnauthorizedNotification',
            'QuoteUnauthorizedNotification',
            'AnonymousInvoiceNotification',
            'AnonymousQuoteNotification',
            'JobFollowupNotification',
            'JobFollowupConfirmNotification',
            'JobFollowupCancelNotification',
            'JobStatusSystemNotification',
            'JobRequestedNotification',
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = Job.fromJson(item.data?.job);
        }

        if (([
            'ReportIssueNotification',
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                job: Job.fromJson(item.data?.job),
                user: User.fromJson(item.data?.user),
            };
        }

        if (([
            'JobAppointmentNotification',
            'JobStatusNotification',
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                job: Job.fromJson(item.data?.job),
                initiator: User.fromJson(item.data?.initiator),
            };
        }

        if (([
            'JobRatedNotification',
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                job: Job.fromJson(item.data?.job),
                rate: item.data?.rating === 1 ? true
                    : item.data?.rating === 0 ? false
                    : null,
            };
        }

        if (([
            'TeamNotification'
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                team: Team.fromJson(item.data?.team),
                user: User.fromJson(item.data?.user),
            };
        }

        if (([
            'WorkerNotification'
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                worker: User.fromJson(item.data?.worker),
                user: User.fromJson(item.data?.user),
            };
        }

        if (([
            'CompanyNotification'
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                company: Itasker.fromJson(item.data?.company),
                user: User.fromJson(item.data?.user),
            };
        }

        if (([
            'ItaskerProjectRejectNotification'
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                project: Project.fromJson(item?.data?.project),
                company: Itasker.fromJson(item?.data?.company),
                owner: User.fromJson(item?.data?.owner),
                reason: item.data?.reason,
            };
        }

        if (([
            'ReportNotification'
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                url: item.data?.url,
                reporter: User.fromJson(item?.data?.reporter),
                reason: item.data?.reason,
            };
        }

        if (([
            'CircleRequestNotification',
            'CircleAcceptNotification',
            'CircleRejectNotification',
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                circle: Circle.fromJson(item?.data?.circle),
                member: User.fromJson(item?.data?.member),
            };
        }

        if (([
            'CirclePostNotification',
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                circle: Circle.fromJson(item?.data?.circle),
                post: CirclePost.fromJson(item?.data?.post),
            };
        }

        if (([
            'CircleCommentNotification',
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                circle: Circle.fromJson(item?.data?.circle),
                post: CirclePost.fromJson(item?.data?.post),
                comment: Comment.fromJson(item?.data?.comment),
            };
        }

        if (([
            'CircleEventNotification',
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                circle: Circle.fromJson(item?.data?.circle),
                event: CircleEvent.fromJson(item?.data?.event),
            };
        }

        if (([
            'AssetEventNotification',
        ] as NotificationItemType[]).indexOf(type) >= 0) {
            item.data = {
                asset: Asset.fromJson(item?.data?.asset),
            };
        }

        return item;
    }

    get nice(): string {
        const item: any = this.data;

        let messages: {[name in NotificationItemType]?: () => string} = {
            'JobAddedNotification': () => `<strong>${item.client?.name || 'A client'}</strong> \
                created a new <strong>${item.service?.name || ''}</strong> task that might interesting you. Check it out!`,

            'JobAssignedNotification': () => `A new <strong>${item.service?.name || ''}</strong> task was assigned to you.`,

            'InvoiceApprovedNotification': () => item.type?.name === 'quote'
                ? `<i class="fa-regular fa-circle-check fa-fw text-success"></i> <strong>${item.client?.name || 'A client'}</strong> \
                    Approved the Quote for <strong>${item.service?.name || ''}</strong> task`
                : `<i class="fa-regular fa-circle-check fa-fw text-success"></i> <strong>${item.client?.name || 'A client'}</strong> \
                    Approved and paid the Invoice for <strong>${item.service?.name || ''}</strong> task`
                ,

            'InvoiceRejectedNotification': () => item.type?.name === 'quote'
                ? `<i class="fas fa-exclamation-triangle fa-fw text-warning"></i> <strong>${item.client?.name || 'A client'}</strong> \
                    Rejected the Quote for <strong>${item.service?.name || ''}</strong> task!`
                : `<i class="fas fa-exclamation-triangle fa-fw text-warning"></i> <strong>${item.client?.name || 'A client'}</strong> \
                    Rejected the Invoice for <strong>${item.service?.name || ''}</strong> task!`
                ,

            'JobSuggestionNotification': () => `iTasker applied for your task <strong>${item.service?.name || ''}</strong>.`,

            'InvoiceSendNotification': () => item.type?.name === 'quote'
                ? `<i class="fa-regular fa-file-lines fa-fw text-warning"></i> <strong>${item.itasker?.user?.name || item.itasker?.business_name || 'An iTasker'}</strong> \
                    create and sent to you Quote for your <strong>${item.service?.name || ''}</strong> task!`
                : `<i class="fa-regular fa-file-lines fa-fw text-warning"></i> <strong>${item.itasker?.user?.name || item.itasker?.business_name || 'An iTasker'}</strong> \
                    create and sent to you Invoice for your <strong>${item.service?.name || ''}</strong> task!`
                ,

            'InvoiceUnauthorizedNotification': () => `<i class="fa-regular fa-file-lines fa-fw text-warning"></i> <strong>${item.itasker?.user?.name || item.itasker?.business_name || 'An iTasker'}\
                </strong> create and sent to you an Invoice!`,
            'AnonymousInvoiceNotification': () => `<i class="fa-regular fa-file-lines fa-fw text-warning"></i> <strong>${item.itasker?.user?.name || item.itasker?.business_name || 'An iTasker'}\
                </strong> create and sent to you an Invoice!`,

            'QuoteUnauthorizedNotification': () => `<i class="fa-regular fa-file-lines fa-fw text-warning"></i> <strong>${item.itasker?.user?.name || item.itasker?.business_name || 'An iTasker'}\
                </strong> create and sent to you a Quote!`,
            'AnonymousQuoteNotification': () => `<i class="fa-regular fa-file-lines fa-fw text-warning"></i> <strong>${item.itasker?.user?.name || item.itasker?.business_name || 'An iTasker'}\
                </strong> create and sent to you a Quote!`,

            'JobHiringNotification': () => `Congratulations! <strong>${item.client?.name || 'A client'}</strong> \
                hired you for <strong>${item.service?.name || ''}</strong> task!`,

            'JobCancelNotification': () => `<strong>${item.client?.name || 'A client'}</strong> \
                just canceled a task <strong>${item.service?.name || ''}</strong> that you have been hired to!`,

            'JobAppointmentNotification': () => `<strong>${item?.initiator?.name || item?.job?.itasker?.user?.name || item?.job?.itasker?.business_name || 'An iTasker'}</strong> changed appointment time to<br /> \
                <strong>${item?.job?.appointmentFormatted}</strong> for your <strong>${item?.job?.service?.name || ''}</strong> task!`,

            'JobStatusNotification': () => `<strong>${item?.initiator?.name || item?.job?.itasker?.user?.name || item?.job?.itasker?.business_name || 'An iTasker'}</strong> changed status to \
                <strong>${item?.job?.status?.title}</strong> for your <strong>${item?.job?.service?.name || ''}</strong> task!`,

            'JobStatusSystemNotification': () => `The Status auto changed to \
                <strong>${item.status?.title}</strong> for your <strong>${item.service?.name || ''}</strong> task!`,

            'JobFollowupNotification': () => `<strong>${item.itasker?.user?.name || item.itasker?.business_name || 'An iTasker'}</strong> created a follow up task.<br /> \
                Please review and approve if you agree with it.`,

            'JobFollowupConfirmNotification': () => `<strong>${item.client?.name || 'A Client'}</strong> confirmed a follow up task<br /> \
                <strong>${item.service?.name || ''}</strong>, that you created!`,

            'JobFollowupCancelNotification': () => `<i class="fas fa-exclamation-triangle fa-fw text-warning"></i> \
                <strong>${item.client?.name || 'A Client'}</strong> canceled a follow up task<br /> \
                <strong>${item.service?.name || ''}</strong>, that you created!`,

            'TeamNotification': () => `<strong>${item.user?.name || 'An iTasker'}</strong> created or modified existing Team<br /> \
                that needs your attention since it is not approved yet.`,

            'WorkerNotification': () => `<strong>${item.user?.name || 'An iTasker'}</strong> created or modified data for worker <strong>${item.worker?.name}</strong>,\
                which needs your attention since it is not approved yet.`,

            'CompanyNotification': () => `<strong>${item.company?.business_name || 'A company'}</strong> have changes on services initialized by iTasker <strong>${item.user?.name}</strong>,\
                which may need your attention for Approval status.`,

            'JobRatedNotification': () => item.rate !== null
                ? `<strong>${item.job?.user?.name || 'A Client'}</strong> rated your work on a task <strong>${item.job?.service?.name || ''}</strong>\
                as "<strong>${item.rate ? 'Satisfied' : 'Disappointed'}"</strong>.`
                : `<strong>${item.job?.user?.name || 'A Client'}</strong> rated your work on a task <strong>${item.job?.service?.name || ''}</strong>.`,

            'ReportIssueNotification': () => {
                return item.job?.id
                    ? `<strong>${item.job?.user?.name || 'A user'}</strong> reported an issue with task <strong>${item.job?.service?.name || ''}</strong>`
                    : `<strong>${item.user?.name || 'A user'}</strong> reported an issue`
            },

            'ItaskerProjectRejectNotification': () => `<strong>${item?.company?.business_name || 'A Company'}</strong> created project <strong>${item.project?.title || ''}</strong> with probably a NSFW content!`,

            'ReportNotification': () => `<strong>${item?.reporter?.name_first || 'An Anonymous User'}</strong> reported content with Reason <strong>${item.reason || 'Unspecified'}</strong>!`,

            'JobRequestedNotification': () => `<strong>${item.client?.name || 'A client'}</strong> \
                requested a new <strong>${item.service?.name || ''}</strong> task that needs your approval first!`,

            'CircleRequestNotification': () => `<strong>${item.member?.name || 'An user'}</strong> \
                requested to join on your private group <strong>${item.circle?.title || ''}</strong>.`,

            'CircleAcceptNotification': () => `You were accepted into Neighborhood's private \
                group <strong>${item.circle?.title || ''}</strong>.`,

            'CircleRejectNotification': () => `You were rejected into Neighborhood's private \
                group <strong>${item.circle?.title || ''}</strong>.`,

            'CirclePostNotification': () => `New Post added into Neighborhood's \
                group <strong>${item.circle?.title || ''}</strong>.`,

            'CircleCommentNotification': () => `New comment added into Neighborhood's \
                group's post.`,

            'CircleEventNotification': () => `New Event added into Neighborhood's \
                group <strong>${item.circle?.title || ''}</strong> that may interested you.`,

            'AssetEventNotification': () => `New Scheduled event for your asset <strong>${item.asset?.title || ''}</strong> occurred.`,
        };

        if (this.type in messages) {
            return messages[this.type]();
        }

        return 'An unknown notification';
    }

    get link(): string {
        if (([
            'JobAddedNotification',
            'JobAssignedNotification',
            'JobHiringNotification',
            'JobSuggestionNotification',
            'JobCancelNotification',
            'JobStatusSystemNotification',
            'JobFollowupNotification',
            'JobFollowupConfirmNotification',
            'JobFollowupCancelNotification',
            'JobRequestedNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return '/tasks/details/' + (this.data?.id);
        }

        if (([
            'JobAppointmentNotification',
            'JobStatusNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return '/tasks/details/' + (this.data?.job?.id);
        }

        if (([
            'ReportIssueNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            if (this.data?.job?.id) {
                return '/tasks/details/' + this.data?.job?.id;
            } else if (this.data?.user?.id) {
                return '/users/edit/' + this.data?.user?.id;
            }
        }

        if (([
            'JobRatedNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return '/tasks/details/' + (this.data?.job?.id);
        }

        if (([
            'InvoiceRejectedNotification',
            'InvoiceApprovedNotification',
            'InvoiceSendNotification',
            'InvoiceUnauthorizedNotification',
            'QuoteUnauthorizedNotification',
            'AnonymousQuoteNotification',
            'AnonymousInvoiceNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return '/tasks/details/' + (this.data?.id) + '/invoice';
        }

        if (([
            'TeamNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return '/teams/view/' + (this.data?.team?.id);
        }

        if (([
            'WorkerNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return '/workers/view/' + (this.data?.worker?.id);
        }

        if (([
            'CompanyNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return '/profile/' + (this.data?.company?.uuid);
        }

        if (([
            'ItaskerProjectRejectNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return '/profile/' + (this.data?.company?.uuid) + '/projects/' + (this.data?.project?.uuid);
        }

        if (([
            'ReportNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return this.data?.url?.split('#')[0] || null;
        }

        if (([
            'AssetEventNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return '/dashboard/assets/view/' + (this.data?.asset?.id);
        }

        if (([
            'CircleRequestNotification',
            'CircleAcceptNotification',
            'CircleRejectNotification',
            'CirclePostNotification',
            'CircleEventNotification',
            'CircleCommentNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return '/neighborhood/view/' + (this.data?.circle?.uuid);
        }

        return null;
    }

    get queryParams(): object {
        if (([
            'CircleRequestNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return {
                'tab': 'members'
            };
        }

        if (([
            'CirclePostNotification',
            'CircleCommentNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return {
                'tab': 'discussions'
            };
        }

        if (([
            'CircleEventNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return {
                'tab': 'events'
            };
        }

        return null;
    }

    get fragment(): string {
        if (([
            'ReportNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return this.data?.url?.split('#')[1] || null;
        }

        if (([
            'CircleRequestNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return this.data?.member?.id;
        }

        if (([
            'CirclePostNotification',
            'CircleCommentNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return this.data?.post?.id;
        }

        if (([
            'CircleEventNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            return this.data?.event?.id;
        }

        return null;
    }

    get image(): string {
        let url = null;

        if (([
            'JobAddedNotification',
            'JobRequestedNotification',
            'JobAssignedNotification',
            'JobHiringNotification',
            'JobCancelNotification',
            'JobSuggestionNotification',
            'InvoiceRejectedNotification',
            'InvoiceApprovedNotification',
            'JobStatusSystemNotification',
            'JobFollowupNotification',
            'JobFollowupConfirmNotification',
            'JobFollowupCancelNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            url = this.data?.client?.image || this.data?.service?.image;
        }

        if (([
            'InvoiceSendNotification',
            'InvoiceUnauthorizedNotification',
            'QuoteUnauthorizedNotification',
            'AnonymousQuoteNotification',
            'AnonymousInvoiceNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            url = this.data?.itasker?.logo || this.data?.itasker?.user?.avatar || this.data?.client?.image || this.data?.service?.image;
        }

        if (([
            'JobAppointmentNotification',
            'JobStatusNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            url = this.data?.job?.client?.image || this.data?.job?.service?.image;
        }

        if (([
            'ReportIssueNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            if (this.data?.job?.id) {
                url = this.data?.job?.client?.avatar || this.data?.job?.service?.image;
            } else if (this.data?.user?.id) {
                url = this.data?.user?.avatar;
            }
        }

        if (([
            'JobRatedNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            url = this.data?.job?.client?.image || this.data?.job?.service?.image;
        }

        if (([
            'TeamNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            url = this.data.user?.avatar || this.data?.team?.users?.[0]?.avatar || null;
        }

        if (([
            'WorkerNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            url = this.data.worker?.avatar || this.data?.user?.avatar || null;
        }

        if (([
            'CompanyNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            url = this.data.company?.logo || this.data.user?.avatar || null;
        }

        if (([
            'ItaskerProjectRejectNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            url = this.data?.company?.logo || this.data?.owner?.avatar || null;
        }

        if (([
            'CircleRequestNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            url = this.data?.member?.avatar || (this.data?.circle?.image?.length ? this.data?.circle?.image : null) || null;
        }

        if (([
            'AssetEventNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            url = this.data?.asset?.uploads?.length ? this.data?.asset?.uploads[0]?.url : null;
        }

        if (([
            'CircleAcceptNotification',
            'CircleRejectNotification',
            'CirclePostNotification',
            'CircleCommentNotification',
            'CircleEventNotification',
        ] as NotificationItemType[]).indexOf(this.type) >= 0) {
            url = this.data?.circle?.image?.length ? this.data?.circle?.image : null;
        }

        return url?.length ? url + '?h=80' : null;
    }

    get posted(): string {
        return moment(this.created).fromNow();
    }
}
