class PaginateLinks {
    public first: string;
    public last: string;
    public prev: string;
    public next: string;
};

class PaginateMetaLink {
    public url?: string;
    public label?: string|number;
    public active?: boolean;
};

class PaginateMeta {
    public path: string;
    public links?: PaginateMetaLink[];

    public from: number;
    public to: number;

    public current_page: number;
    public last_page: number;
    public per_page: number;

    private innerTotal: number;
    public set total(val: number) {
        if (this.innerTotal !== val) {
            this.innerTotal = val;
            this.innerTotal < 0 && (this.innerTotal = 0);
        }
    }
    public get total(): number {
        return this.innerTotal;
    }
};

export class Paginate<T> {
    public data: Array<T>;
    public links: PaginateLinks;
    public meta: PaginateMeta;

    constructor() {
        this.meta = new PaginateMeta();
        this.links = new PaginateLinks();
    }

    /**
     * Get next page of pagination
     */
    nextPage(): number|null {
        if (this.meta?.current_page < this.meta?.last_page) {
            return ++this.meta.current_page;
        }
        return null;
    }

    /**
     * Get previous page of pagination
     */
    prevPage(): number|null {
        if (this.meta?.current_page > 1) {
            return --this.meta.current_page;
        }
        return null;
    }

    /**
     * Check if pagination is reach the end
     */
    reachEnd(): boolean {
        return this.meta.current_page >= this.meta.last_page;
    }

    /**
     * Set Data for pagination
     *
     * @param data
     */
    setData(data: Array<T>) {
        this.data = data;
    }

    /**
     * Add new item at the beginning of items
     * @param item
     */
    append(item: T|T[]): void {
        item = Array.isArray(item) ? item : [item];
        this.data.push(...item);
        this.increaseItems(item?.length);
    }

    /**
     * Add new item at the ending of items
     * @param item
     */
    prepend(item: T|T[]): void {
        item = Array.isArray(item) ? item : [item];
        this.data.unshift(...item);
        this.increaseItems(item?.length);
    }

    protected increaseItems(count: number = 1) {
        this.meta.total += count;
        this.meta.to += count;
        this.meta.last_page = Math.ceil(this.meta.total / this.meta.per_page);
        this.links.last = this.links.last.replace(/=(\d+)$/, '=' + this.meta.last_page);
    }
};
