import { Itasker } from "../itaskers/itasker.model";
import { User } from "../user.model";
import { JobService } from "./job-service.model";

import * as moment from 'moment';
import { JobAppointment } from "./job-appointment.model";

export class JobSuggestion {
    id?: string;
    uuid?: string;
    itasker_id?: number;
    itasker?: Itasker;
    name?: string;
    image?: string;

    price?: number;
    rate?: string;
    init_time?: string;
    init_appointment_time?: string;
    init_appointment_discount?: string;
    follow_up_appointment_discount?: string;
    warranty_time?: string;
    appointment?: string;

    user?: User;
    services?: JobService[];

    static fromJson(fields): JobSuggestion {
        let item = Object.assign(new this(), fields);

        item.user = User.fromJson(item?.user);
        item.itasker = Itasker.fromJson(item?.itasker);
        item.services = item.services?.map(service => JobService.fromJson(service));

        return item;
    }

    appointmentFormatted(appointments: JobAppointment[]): string {
        let appointment = moment(this.appointment);

        let found = appointments.filter(item => {
            return moment.utc(item.start).isSame(appointment);
        });

        return found && found.length ? found[0].range : appointment.format('llll');
    }
}
