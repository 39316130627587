import * as moment from 'moment';
import { User } from './../user.model';

export type ChatType = ('private'|'group');

export class Chat {
    id?: number|string;
    type?: ChatType;
    title?: string;
    items?: {
        total?: number,
        seen?: number,
        new?: number,
    };
    moderator?: User;
    users?: User[];
    deleted_users?: User[];
    created?: string;
    updated?: string;

    static fromJson(fields: any): Chat {
        let item = Object.assign(new this(), fields);
        item.moderator = User.fromJson(item?.moderator);
        item.users = item.users?.map(user => User.fromJson(user));
        item.deleted_users = item.deleted_users?.map(user => User.fromJson(user));
        return item;
    }

    isUserModerator(user: User): boolean {
        return this.moderator && this.moderator?.id === user?.id;
    }
}
