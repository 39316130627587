import { isPlatformServer } from '@angular/common';
import { Directive, ElementRef, Inject, Optional, PLATFORM_ID } from '@angular/core';

@Directive({
    selector: 'img'
})
export class ImgDirective {
    protected readonly LAZY_ATTR_NAME: string = 'data-lazy-img-url';

    constructor(
        { nativeElement }: ElementRef<HTMLImageElement>,
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
    ) {
        const isServer = isPlatformServer(this.platform);
        const supports = 'loading' in HTMLImageElement.prototype;

        if (!nativeElement.hasAttribute('data-skip-lazy-loading')) {
            return;
        }

        if (supports || isServer) {
            nativeElement.setAttribute('loading', 'lazy');
        } else if (!isServer && 'IntersectionObserver' in window) {
            const observer = new IntersectionObserver((entries, imgObserver) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const lazyImage: HTMLImageElement = entry.target as HTMLImageElement;
                        if (lazyImage.hasAttribute(this.LAZY_ATTR_NAME)) {
                            lazyImage.setAttribute('src', lazyImage.getAttribute(this.LAZY_ATTR_NAME));
                            setTimeout(() => {
                                lazyImage.removeAttribute(this.LAZY_ATTR_NAME);
                            }, 10);
                        }
                        imgObserver.unobserve(lazyImage);
                    } else if (!entry.isIntersecting) {
                        nativeElement.setAttribute(this.LAZY_ATTR_NAME, nativeElement.getAttribute('src'));
                        nativeElement.setAttribute('src', '');
                    }
                });
            });

            observer.observe(nativeElement);
        }
    }
}
