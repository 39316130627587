export type CustomFormPostItemStatus = (
    'active'
    |'inactive'
);

export type CustomFormPostItemOperator = (
    'addition'
    |'subtraction'
    |'multiplication'
    |'division'
    |'prefix'
    |'suffix'
    |'min'
    |'max'
);
export type CustomFormPostItemCast = (
    'boolean'
    |'integer'
    |'decimal'
    |'string'
);

export class CustomFormPostItem {
    id?: number;
    field?: number;
    field_template?: number;
    operator?: CustomFormPostItemOperator;
    value?: string;
    cast?: CustomFormPostItemCast;
    status?: CustomFormPostItemStatus
    created?: string;
    updated?: string;

    static fromJson(fields: any): CustomFormPostItem {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
