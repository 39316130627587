export type CustomFormPostConditionStatus = ('active'|'inactive');
export type CustomFormPostConditionOperator = ('and'|'or');
export type CustomFormPostConditionCondition = ('lt'|'lte'|'gt'|'gte'|'eq'|'neq');

export class CustomFormPostCondition {
    id?: number;
    operator?: CustomFormPostConditionOperator;
    condition?: CustomFormPostConditionCondition;
    value?: string;
    replace?: string;
    status?: CustomFormPostConditionStatus;
    created?: string;
    updated?: string;

    static fromJson(fields: any): CustomFormPostCondition {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
