import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class HttpUnauthInterceptorService implements HttpInterceptor {
    private authService = null;

    constructor(private injector: Injector, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => { },
                (error: any) => {
                    if (error instanceof HttpErrorResponse && error.status === 401) {
                        // redirect to the login route
                        // or show a modal

                        if (!this.authService) {
                            this.authService = this.injector.get(AuthService);
                        }
                        this.authService.logout(true).subscribe((data: any) => {
                            setTimeout(() => {
                                this.router.navigate(['/auth/login']);
                            });
                        });
                    }
                }
            )
        );
    }
}
