import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { BehaviorSubject, Observable, Observer, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

const authEndpoint = () => `${environment.scheme}://${environment.domain}`
    + `${environment.port ? ':' + environment.port : ''}/api/broadcasting/auth`;

export enum WebsocketServiceConnectionStatus {
    CONNECTING, // not opened yet
    CONNECTED,  // ready for both send & receive data
    CLOSED,     // closed
    RETRYING    // closed, but in process of reconnection
};

export type WebsocketServiceChannelType = ('private'|'public'|'presence');

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {
    protected readonly TAG: string = WebsocketService.name;
    protected readonly DEBUG: boolean = !environment.production;
    protected readonly CONFIG = environment.webSockets;

    protected isServer: boolean = false;
    protected pusher = null;
    protected echo = null;
    protected type: WebsocketServiceChannelType = 'presence';
    protected subscriptions: {[name: string]: {
        count: number,
        total: number,
        register: () => void,
        handler: Observable<any>,
    }} = {};

    protected retryTimer: any = null;
    protected retryTimeout: number = 10000;
    protected connectionState = new BehaviorSubject<WebsocketServiceConnectionStatus>(WebsocketServiceConnectionStatus.CONNECTING);
    protected innerCurrentConnectionState: WebsocketServiceConnectionStatus = WebsocketServiceConnectionStatus.CONNECTING;

    set currentConnectionState(value: WebsocketServiceConnectionStatus) {
        if (this.innerCurrentConnectionState !== value) {
            this.innerCurrentConnectionState = value;
            this.connectionState.next(this.innerCurrentConnectionState);
        }
    }

    get currentConnectionState() {
        return this.innerCurrentConnectionState;
    }

    constructor(
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
        private api: ApiService,
        private auth: AuthService,
    ) {
        this.isServer = isPlatformServer(this.platform);


        let user = null;
        this.auth.getAuthReady().subscribe((data: any) => {

            if (this.auth.user.isValid() && user !== this.auth.user.id) {
                this.reconnect();
            }

            user = this.auth.user.id;
        });
    }

    reconnect() {
        if (this.isServer) {
            return this;
        }

        if (this.pusher || this.echo) {
            this.disconnect();
        }

        this.pusher = new Pusher(this.CONFIG.key, {
            ...this.CONFIG,
            authEndpoint: authEndpoint(),
            ...(this.api.getToken() ? {
                auth: {
                    headers: {
                        Authorization: `Bearer ${this.api.getToken()}`,
                        'X-Auth-Token': `Bearer ${this.api.getToken()}`,
                        Accept: 'application/json'
                    }
                }
            } : null)
        });

        this.echo = new Echo({...this.CONFIG, client: this.pusher, enabledTransports: ['wss', 'ws']});

        this.pusher.connection
            .unbind('connecting', this.willOpen)
            .bind('connecting', this.willOpen);

        this.pusher.connection
            .unbind('connected', this.didOpen)
            .bind('connected', this.didOpen);

        this.pusher.connection
            .unbind('unavailable', this.didClose)
            .bind('unavailable', this.didClose);

        this.pusher.connection
            .unbind('disconnected', this.didClose)
            .bind('disconnected', this.didClose);

        for (let name in this.subscriptions) {
            this.subscriptions[name] && this.subscriptions[name].register();
        }

        return this;
    }

    protected didOpen = () => {
        this.retryTimer && clearInterval(this.retryTimer);
        this.currentConnectionState = WebsocketServiceConnectionStatus.CONNECTED;
    };

    protected willOpen = () => {
        this.retryTimer && clearInterval(this.retryTimer);
        this.currentConnectionState = WebsocketServiceConnectionStatus.CONNECTING;
    }

    protected didClose = () => {
        this.currentConnectionState = WebsocketServiceConnectionStatus.CLOSED;

        this.retryTimer && clearInterval(this.retryTimer);
        this.retryTimer = setInterval(() => {
            this.echo.connector.pusher.connection.connect();
            this.DEBUG && console.log('WSS Reconnecting attempt ...');
        }, this.retryTimeout);
    }

    disconnect() {
        this.pusher && (this.pusher = null);
        this.echo && this.echo.disconnect();
        // this.subscriptions = {};
        return this;
    }

    on(event: string, channelType: WebsocketServiceChannelType, channelName: string, prefix = "."): Observable<any> {
        return this.subscribe(channelType, channelName, event, prefix);
    }

    onMultiple(events: string[], channelType: WebsocketServiceChannelType, channelName: string, prefix = "."): Array<Observable<any>> {
        return events.map(event => this.subscribe(channelType, channelName, event, prefix));
    }

    /**
     * Get observable version of connection state, which is shareable between subscribers
     */
    getConnectionState(): Observable<WebsocketServiceConnectionStatus> {
        return this.connectionState.asObservable().pipe(share());
    }

    protected subscribe(type: WebsocketServiceChannelType, channel: string, event: string, prefix: string = '.'): Observable<any> {
        if (this.isServer) {
            return new Observable((observer: Observer<any>) => {
                observer.complete();
            });
        }

        if (!this.echo) {
            this.reconnect();
        }

        const name: string = channel + '|' + event;

        if (name in this.subscriptions && this.subscriptions[name] && this.subscriptions[name].count) {
            this.subscriptions[name].count++;
            this.subscriptions[name].total++;
            return this.subscriptions[name].handler;
        }

        let echoInstance = () => {
            if (type === 'public') {
                return this.echo.channel(channel);
            } else if (type === 'private') {
                return this.echo.private(channel)
            } else if (type === 'presence') {
                return this.echo.private(channel);
            }
        }

        let observers: Array<Observer<any>> = [];
        const listener = (message: any) => {
            this.DEBUG && console.log('%c' + event, 'color:#ff8c00', message);
            observers && observers.filter(item => item).map(observer => observer.next(message));
        };

        this.subscriptions[name] = {
            count: 1,
            total: 1,
            register: () => {
                echoInstance().stopListening((prefix || '') + event, listener);
                echoInstance().listen((prefix || '') + event, listener);
            },
            handler: new Observable((observer: Observer<any>) => {
                observers[this.subscriptions[name].total] = observer;

                try {
                    this.subscriptions[name].register();

                    return () => {
                        if (this.subscriptions[name].total in observers) {
                            delete observers[this.subscriptions[name].total];
                        }

                        if (name in this.subscriptions && this.subscriptions[name]) {
                            this.subscriptions[name].count--;

                            if (this.subscriptions[name].count <= 0) {
                                delete this.subscriptions[name];
                                echoInstance().stopListening((prefix || '') + event, listener);
                                (type === 'private' || type === 'presence') && this.echo.leave(channel);
                            }
                        }

                        observer.complete();
                    };
                } catch (e) {
                    observer.error(e);
                    return () => {};
                }
            }),
        };

        return this.subscriptions[name].handler;
    }
}
