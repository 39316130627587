import { Injectable } from '@angular/core';
import { Observable, Subscription, throwError, timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { User } from 'src/app/shared/models/user.model';
import { UserPreference } from 'src/app/shared/models/user-preference.model';
import { Paginate } from '../models/paginate.model';
import { FilterSort } from '../models/filter-sort.model';
import { UserCoordinate } from '../models/user-coordinate.model';
import { environment } from 'src/environments/environment';
import { ReactNativeService } from './react-native.service';
import { Router } from '@angular/router';
import { Favorite } from '../models/favorite.model';

export interface ProfileListFilter {
    sort?: string|FilterSort[];
    page?: number;
    limit?: number;
};

export interface ProfileFavoriteListFilter {
    sort?: string|FilterSort[];
    page?: number;
    limit?: number;
    type?: ('companies'|'projects');
};

export interface ProfileInputCoordinate {
    coordinate_long?: number;
    coordinate_lat?: number;
};

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    protected readonly MAX_TIMEOUT_GEOLOCATION: number = environment.gps.timeout ?? (60 * 1000);

    constructor(
        private api: ApiService,
        private reactNativeService: ReactNativeService,
        private router: Router,
    ) { }

    getProfile(): Observable<{data: User}> {
        return this.api.get<{data: User}>("/profile").pipe(
            map(data => {
                data.data = User.fromJson(data?.data);
                return data;
            })
        );
    }

    updateProfile({
        name_first = undefined,
        name_last = undefined,
        email = undefined,
        avatar = undefined,
        password = undefined,
        password_current = undefined,
        phone = undefined,
        address = undefined,
        postal_code = undefined,
        city = undefined,
        coordinate_lat = undefined,
        coordinate_long = undefined,
        category = undefined,
        description = undefined,
        projects = undefined,
        cover = undefined,
        business_name = undefined,
    }): Observable<{data: User}> {
        return this.api.post<{data: User}>('/profile', {
            name_first,
            name_last,
            email,
            avatar,
            password,
            password_current,
            phone,
            address,
            postal_code,
            city,
            coordinate_lat,
            coordinate_long,
            category,
            description,
            projects,
            cover,
            business_name,
        });
    }

    removeProfile(data: {password: string}): Observable<{data: any}> {
        return this.api.delete<{data: User}>('/profile', {body: data});
    }

    /**
     * Get Current preferences that user can and have
     * @returns
     */
    getPreferences(): Observable<{data: UserPreference[]}> {
        return this.api.get('/profile/preferences');
    }

    /**
     * Change current user preferences
     * @param data
     * @returns
     */
    updatePreferences(data: Array<{name: string, value: boolean|number|string}>): Observable<{data: UserPreference[]}> {
        return this.api.post('/profile/preferences', {data});
    }

    /**
     * Get Favorites List
     * @param filter
     * @returns
     */
    indexFavorites(filter?: ProfileFavoriteListFilter): Observable<Paginate<Favorite>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort));

        return this.api.get('/profile/favorites', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<Favorite>(), data);
                data.data = data.data?.map(item => Favorite.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get Favorite By ID
     * @param filter
     * @returns
     */
    getFavorite(id: string): Observable<Paginate<Favorite>> {
        return this.api.get('/profile/favorites/' + id).pipe(
            map(data => {
                data = Object.assign(new Paginate<Favorite>(), data);
                data.data = data.data?.map(item => Favorite.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Delete a Favorite Item
     * @param data
     * @returns
     */
    destroyFavorites(id: number): Observable<any> {
        return this.api.delete('/profile/favorites/' + id);
    }

    /**
     * Get Profile Coordinates
     * @param filter
     * @returns
     */
    indexCoordinates(filter?: ProfileListFilter): Observable<Paginate<UserCoordinate>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort));

        return this.api.get('/profile/coordinates', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<User>(), data);
                data.data = data.data?.map(item => User.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Add new Profile Coordinates
     * @param data
     * @returns
     */
    storeCoordinates(data?: ProfileInputCoordinate): Observable<{data: UserCoordinate}> {
        return this.api.post('/profile/coordinates', data).pipe(
            map(data => {
                data.data = UserCoordinate.fromJson(data?.data);
                return data;
            })
        );
    }

    updateActivity(timeOut: number = 1, fallbackRoute: string = '/', successRedirectsRoute: string = null): Subscription {
        let subscription: Subscription = null;

        return timer(1000, (timeOut > 0 ? timeOut : 1) * 1000 * 60).subscribe({
            next: async data => {
                subscription?.unsubscribe();

                if (this.reactNativeService.isMobile()
                    ||  typeof navigator === 'undefined'
                    || !("geolocation" in window.navigator)
                ) {
                    return;
                }

                if (navigator?.permissions && navigator?.permissions?.query) {
                    navigator.permissions.query({name:'geolocation'}).then(result => {
                        result.state !== 'granted' && this.router.navigateByUrl(fallbackRoute);
                    });
                } else {
                    navigator.geolocation.getCurrentPosition(position => {
                        this.router.navigateByUrl(fallbackRoute)
                    });
                }

                window.navigator.geolocation.getCurrentPosition(position => {
                    subscription = this.storeCoordinates({
                        coordinate_lat: position?.coords?.latitude || 0,
                        coordinate_long: position?.coords?.longitude || 0
                    }).subscribe({
                        next: data => {
                            successRedirectsRoute?.length && this.router.navigateByUrl(successRedirectsRoute);
                        },
                        error: error => {
                            throw throwError(() => new Error('Cannot store coordinates'));
                        }
                    });

                }, error => {
                    throw throwError(() => error);
                }, {
                    enableHighAccuracy: true,
                    timeout: this.MAX_TIMEOUT_GEOLOCATION,
                    maximumAge: 0
                });
            },
            error: error => {
                subscription?.unsubscribe();
            },
            complete: () => {
                subscription?.unsubscribe();
            }
        });
    }
}
