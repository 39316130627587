import { CustomFormField } from './custom-form-field.model';
import { CustomFormPost } from './custom-form-post.model';
import { CustomFormTemplate } from './custom-form-template.model';

export type CustomFormStatus = ('active'|'inactive');

export class CustomForm {
    id?: number;
    title?: string;
    description?: string;
    fields?: CustomFormField[] = [];
    posts?: CustomFormPost[] = [];
    template?: CustomFormTemplate;
    status?: CustomFormStatus;
    created?: string;
    updated?: string;

    static fromJson(fields: any): CustomForm {
        let item = Object.assign(new this(), fields);

        item.template = CustomFormTemplate.fromJson(fields?.template);
        item.fields = fields?.fields?.map(item => CustomFormField.fromJson(item));
        item.posts = fields?.posts?.map(item => CustomFormPost.fromJson(item));

        return item;
    }

    /**
     * Remove all ID's from current custom form and fields, field options and field validations
     *
     * @returns
     */
    prepareForCloning(): CustomForm
    {
        this.id = null;

        this.fields = this.fields?.map(field => {
            field.id = null;

            field.options = field.options?.map(option => {
                option.id = null;
                return option;
            });

            field.rules = field.rules?.map(rule => {
                rule.id = null;
                return rule;
            });
            return field;
        });

        this.posts = this.posts?.map(field => {
            field.id = null;

            field.items = field.items?.map(item => {
                item.id = null;
                return item;
            });

            field.conditions = field.conditions?.map(item => {
                item.id = null;
                return item;
            });

            return field;
        });

        return this;
    }
}
