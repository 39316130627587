import { JobServiceStatus } from "../../services/job.service";

export class JobStatus {
    id?: number;
    name?: JobServiceStatus;
    title?: string;

    get isStatusNew(): boolean {
        return [
            'new',
            'pending',
            'assigned',
            'accepted',
            'confirmation',
        ].indexOf(this.name) >= 0;
    }

    get isStatusActive(): boolean {
        return [
            'pending',
            'assigned',
            'accepted',
            'on_site',
            'on_route',
            'on_break',
            'on_materials_pickup',
            'confirmation',
        ].indexOf(this.name) >= 0;
    }

    get isStatusCompleted(): boolean {
        return [
            'completed',
            'completed_extra_time',
            'not_completed',
            'partially_completed',
            'failed',
            'cancel',
            'dispute',
        ].indexOf(this.name) >= 0;
    }

    get isStatusCanceled(): boolean {
        return [
            'cancel',
        ].indexOf(this.name) >= 0;
    }

    get isStatusDisputed(): boolean {
        return [
            'dispute',
        ].indexOf(this.name) >= 0;
    }

    get isStatusConfirmation(): boolean {
        return [
            'confirmation',
        ].indexOf(this.name) >= 0;
    }

    static fromJson(fields): JobStatus {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
