import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Component, HostBinding, Inject, Input, OnDestroy, OnInit, Optional, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ReactNativeService } from '../../services/react-native.service';
import { SettingService } from '../../services/setting.service';

type Mode = ('default'|'sidebar'|'offcanvas');

@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit, OnDestroy {
    protected readonly NO_FOOTER_CLASS_NAME: string = 'no-footer';
    protected subscriptions: Subscription[] = [];
    protected innerVisible: boolean = false;
    protected isServer: boolean = true;
    protected innerMode: Mode = 'default';

    @HostBinding('class.sidebar') modeSidebar: boolean = false;
    @HostBinding('class.appmenu') modeOffcanvas: boolean = false;

    @Input()
    set mode(value: Mode) {
        if (this.innerMode !== value) {
            this.innerMode = value;
        }
        this.modeSidebar = this.innerMode === 'sidebar';
        this.modeOffcanvas = this.innerMode === 'offcanvas';
    }
    get mode() {
        return this.innerMode;
    }

    urlPrivacy: string = '';
    urlTerms: string = '';
    urlAboutUs: string = '';
    links: any = null;
    isMobileApp: boolean = false;

    set visible(val: boolean) {
        if (this.innerVisible !== val) {
            this.innerVisible = val;

            if (!this.isServer && val) {
                this.renderer.removeClass(this.document.body, this.NO_FOOTER_CLASS_NAME);
            } else if (!this.isServer) {
                this.renderer.addClass(this.document.body, this.NO_FOOTER_CLASS_NAME);
            }
        }
    }
    get visible(): boolean {
        return this.innerVisible;
    }

    constructor(
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
        @Optional() @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private router: Router,
        private route: ActivatedRoute,
        private reactNativeService: ReactNativeService,
        private settingService: SettingService,
    ) {
        this.isServer = isPlatformServer(this.platform);
    }

    ngOnInit(): void {
        this.isMobileApp = this.reactNativeService.isMobile();
        this.visible = !this.reactNativeService.isMobile() || this.mode === 'offcanvas';

        const subscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                const url = (this.router?.url?.split('?')[0] || '').trim();

                let current = this.route.root;
                while (current.children[0] !== undefined) {
                    current = current.children[0];
                }

                const hasFooter = !!(current.snapshot.data['footer'] || false);

                this.visible = !url || hasFooter
                    ? (!this.reactNativeService.isMobile() || this.mode === 'offcanvas')
                    : this.mode === 'offcanvas';
            });
        this.subscriptions.push(subscription);

        this.getAboutUsLinks();
    }

    ngOnDestroy(): void {
        this.subscriptions.map(item => item?.unsubscribe());
    }

    protected getAboutUsLinks(): void {
        const subscription = this.settingService.getCurrentSettings().subscribe(data => {
            this.urlAboutUs = data.filter(item => item.name === 'about.us')[0]?.value as string ?? '';
            this.urlTerms = data.filter(item => item.name === 'about.terms')[0]?.value as string ?? '';
            this.urlPrivacy = data.filter(item => item.name === 'about.privacy')[0]?.value as string ?? '';

            this.links = [];
            let links = {};

            for (let item in data) {
                if (data[item].name.indexOf('footer.') >= 0) {
                    const name = data[item].name.split('.');

                    if (name[1] in links) {
                        links[name[1]][name[2]] = data[item].value;
                    } else {
                        links[name[1]] = {
                            [name[2]]: data[item].value
                        };
                    }
                }
            }

            for (let link in links) {
                if (links[link].status) {
                    links[link].type = 'link';

                    if (links[link].link?.match(/^https?:\/\//)) {
                        links[link].type = 'external';
                    }

                    this.links.push(links[link]);
                }
            }

        });
        this.subscriptions.push(subscription);
    }
}
