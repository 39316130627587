import { User } from './../user.model';
import { Upload } from './upload.model';
import * as moment from 'moment';

export class Message {
    id?: number;
    message?: string;
    url?: string;
    uploads?: Upload[];
    user?: User;
    created?: string;
    updated?: string;

    // custom
    protected _posted?: string;
    protected _createdFormatted?: string;

    static fromJson(fields: any): Message {
        let item = Object.assign(new this(), fields);
        item.user = User.fromJson(item?.user);
        item.uploads = item.uploads?.map(item => Upload.fromJson(item));
        return item;
    }


    static mapUser(messages: Message[], users: User[]): Message[] {
        let persons: {[id: number]: User} = {};
        for (let user of users) {
            persons[user.id] = user;
        }

        messages?.map(item => {
            if (item.user?.id in persons) {
                item.user = persons[item?.user?.id];
            }
            return item;
        });

        return messages;
    }

    matchAuthorAndTime(another: Message): boolean {
        return another
            && another?.user?.id === this.user?.id
            && moment(this.created).endOf('minute').isSame(moment(another.created).endOf('minute'));
    }
}
