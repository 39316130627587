<section id="app" *ngIf="!footer && !isMobileApp">
    <div class="container-xl">
        <div class="row">
            <div class="col-lg-6 col-md-12" *ngIf="withLabel">
                <p>iTaskApp Available for download:</p>
            </div>
            <div [ngClass]="{
                'col-lg-6 col-md-12': withLabel,
                'col-12 text-center': !withLabel
            }">
                <div class="app-icon" [class.bigger]="sidebar">
                    <ng-container *ngTemplateOutlet="links"></ng-container>
                </div>
            </div>
        </div>
    </div>
</section>

<div  id="app" *ngIf="!footer && isMobileApp" class="pt-0 pb-2"></div>

<div class="app-info" *ngIf="footer && !isMobileApp">
    <h4 *ngIf="withLabel">iTaskApp Available for download</h4>
    <div class="footer-icons" [class.bigger]="sidebar">
        <ng-container *ngTemplateOutlet="links"></ng-container>
    </div>
</div>

<ng-template #links>
    <a href="https://apps.apple.com/ca/app/itaskapp/id1552487042" target="_blank" [ngClass]="{'d-block mx-0 my-3 p-0': sidebar}">
        <img src="/assets/img/stores/appstore.svg" alt="AppStore"  title="" />
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.itask.app" target="_blank" [ngClass]="{'d-block mx-0 my-3 p-0': sidebar}">
        <img src="/assets/img/stores/google-play.svg" alt="Google Play" title="" />
    </a>
</ng-template>
