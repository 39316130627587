import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Paginate } from '../models/paginate.model';
import { ApiService } from './api.service';
import { Chat } from './../models/chats/chat.model';
import { Message } from './../models/chats/message.model';
import { map, share } from 'rxjs/operators';
import { WebsocketService } from './websocket.service';
import { User } from '../models/user.model';

export interface ChatServiceListFilter {
    page?: number;
    limit?: number;
    recipients?: number[]|string;
};


export interface ChatServiceMessageListFilter {
    page?: number;
    limit?: number;
    without_user?: number;
};

export interface ChatServiceUserListFilter {
    page?: number;
    limit?: number;
    q?: string;
    ids?: number[]|string;
};

interface ChatServiceInputMessage {
    message?: string;
    uploads?: any[];
};

interface ChatServiceInput {
    message?: string;
    uploads?: any[];
    recipients: number[]|string;
};

interface ChatServiceUpdate {
    title?: string;
    users?: Array<{
        id: number;
        restrict?: boolean;
    }>
};

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    protected newMessageSubject: BehaviorSubject<number> = new BehaviorSubject(null);

    constructor(
        private api: ApiService,
        private wsService: WebsocketService,
    ) { }

    getList(filter?: ChatServiceListFilter): Observable<Paginate<Chat>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.recipients && Array.isArray(filter.recipients) && (filter['recipients'] = filter.recipients.join(','));


        return this.api.get('/chats', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<Chat>(), data);
                data.data = data.data?.map(item => Chat.fromJson(item));
                return data;
            })
        );
    }

    getItem(id: number): Observable<{data: Chat}> {
        return this.api.get('/chats/' + id).pipe(
            map(data => {
                data.data = Chat.fromJson(data?.data);
                return data;
            })
        );
    }

    postItem(data: ChatServiceInput): Observable<{data: Chat}> {
        return this.api.post('/chats', data).pipe(
            map(data => {
                data.data = Chat.fromJson(data?.data);
                return data;
            })
        );
    }

    updateItem(id: number, data: ChatServiceUpdate): Observable<{data: Chat}> {
        return this.api.post('/chats/' + id, data).pipe(
            map(data => {
                data.data = Chat.fromJson(data?.data);
                return data;
            })
        );
    }

    getItemMessages(id: number, filter?: ChatServiceMessageListFilter): Observable<Paginate<Message>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);


        return this.api.get('/chats/' + id  + '/messages', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<Message>(), data);
                data.data = data.data?.map(item => Message.fromJson(item));
                return data;
            })
        );
    }

    sendMessage(id: number, data: ChatServiceInputMessage): Observable<{data: Message}> {
        return this.api.post('/chats/' + id  + '/messages', data).pipe(
            map(data => {
                data.data = Message.fromJson(data?.data);
                return data;
            })
        );
    }


    getMessage(id: number, message: number): Observable<{data: Message}> {
        return this.api.get('/chats/' + id  + '/messages/' + message).pipe(
            map(data => {
                data.data = Message.fromJson(data?.data);
                return data;
            })
        );
    }

    newMessages(): Observable<{data: {count: number}}> {
        return this.api.get('/chats/new').pipe(
            map(data => {
                this.newMessageSubject.next(data?.data?.count || 0);
                return data;
            })
        );
    }

    readMessages(ids: number[]): Observable<{data: {count: number, current: number}}> {
        return this.api.post('/chats/read', {ids: ids.join(',')}).pipe(
            map(data => {
                this.newMessageSubject.next(data?.data?.count || 0);
                return data;
            })
        );
    }

    newMessageReceived(userId: number): Observable<{unseen: number, chat: Chat, message: Message}> {
        return this.wsService.on('chat.update', 'private', 'user.' + userId).pipe(
            map(data => {
                data.unseen = Number(data.unseen);
                data.chat = Chat.fromJson(data?.chat);
                data.message = Message.fromJson(data?.message);

                if (!('name_first' in data?.message?.user)) {
                    data.message = Message.mapUser([data.message], data.chat?.users)[0];
                }

                this.newMessageSubject.next(data.unseen || 0);

                return data;
            })
        );
    }

    getNewMessageChanges(): Observable<number> {
        return this.newMessageSubject.asObservable().pipe(share());
    }

    getUsers(filter?: ChatServiceUserListFilter): Observable<Paginate<User>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.ids && Array.isArray(filter.ids) && (filter['ids'] = filter.ids.join(','));

        return this.api.get('/chats/users', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<User>(), data);
                data.data = data.data?.map(item => User.fromJson(item));
                return data;
            })
        );
    }
}
