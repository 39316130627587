import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';

import { Team } from "../team.model";
import { Itasker } from "../itaskers/itasker.model";
import { Review as ItaskerReview } from "../itaskers/review.model";
import { JobStatus } from "./job-status.model";
import { User } from "../user.model";
import { JobSuggestion } from './job-suggestion.model';
import { JobType } from "./job-type.model";
import { JobAppointment } from "./job-appointment.model";
import { JobUpload } from './job-upload.model';
import { JobActivity } from './job-activity.model';
import { JobForm } from './job-form.model';
import { PriceType } from "./../price-type.model";
import { JobInvoice } from './job-invoice.model';
import { Invoice } from '../invoice.model';
import { Service } from '../service.model';

export type JobGroup = ('regular'|'internal'|'proforma');

export class Job {
    id?: number;
    client?: User;
    service?: Service;
    type?: JobType;
    group?: JobGroup;
    rate?: string;
    city?: string;
    address?: string;
    postal_code?: string;
    coordinate_long?: number;
    coordinate_lat?: number;
    coordinate_exact?: boolean;
    comment_special?: string;
    comment_description?: string;
    itasker?: Itasker;
    team?: Team;
    status?: JobStatus;
    price?: number;
    price_from?: number;
    price_to?: number;
    price_type?: PriceType;
    price_paid?: any;
    appointment?: string;
    appointments?: JobAppointment[];
    uploads?: JobUpload[];
    suggestions?: JobSuggestion[];
    suggestion_counter?: number;
    posted?: string;
    updated?: string;
    can?: {
        apply?: boolean;
        assign_itasker?: boolean;
        assign_team?: boolean;
        review?: boolean;
        cancel?: boolean;
        change_status?: boolean;
        set_appointment?: boolean;
        dispute?: boolean;
        invoice_view?: boolean;
        invoice_build?: boolean;
        invoice_approval?: boolean;
        quote_view?: boolean;
        quote_build?: boolean;
        complete?: boolean;
        followup?: boolean;
        followup_confirmation?: boolean;
        itasker_approval?: boolean;
        duplicate?: boolean;
        see_suggestions?: boolean;
        see_related?: boolean;
        form_download?: boolean;
    };
    reason_cant_apply?: string;
    invoices?: JobInvoice[];
    invoice?: Invoice;
    tax?: any;
    activities?: JobActivity[];
    review?: ItaskerReview;
    system_update?: string;
    related?: {
        parent?: number;
        children?: number;
        siblings?: number;
        total?: number;
    };
    category?: string;
    forms?: JobForm[];

    get priceFormatted(): string {
        const pipe = (new DecimalPipe('en'));
        let result: string[] = [];

        const isTax = this.service?.type === 'tax';

        if (this.type?.name == 'regular') {
            if (this?.price || isTax) {
                result = [
                    '$' +  pipe.transform(this?.price ?? 0, '1.0-2'),
                ];
            } else if (this?.price_from != this?.price_to) {
                result = [
                    '$' + pipe.transform(this?.price_from, '1.0-2'),
                    '–',
                    '$' + pipe.transform(this?.price_to, '1.0-2')
                ];
            } else {
                result = [
                    '$' + pipe.transform(this?.price_from, '1.0-2'),
                ];
            }
        } else if (this.type?.name === 'quote') {
            result = [];
        } else if (this.type?.name === 'instant') {
            if (this?.price || isTax) {
                result = [
                    '$' +  pipe.transform(this?.price ?? 0, '1.0-2'),
                ];
            } else if (this?.price_from != this?.price_to) {
                result = [
                    '$' + pipe.transform((this?.price_from + this?.price_to) / 2, '1.0-2'),
                ];
            } else {
                result = [
                    '$' + pipe.transform(this?.price_from, '1.0-2'),
                ];
            }
        }

        if (result?.length && this.price_type?.title === 'hour') {
            result = [...result, (this.service?.type === 'tax' ? '' : '/hr')];
        }

        return result.filter(item => item?.length).join(' ');
    }

    get appointmentFormatted(): string {
        return this.getAppointmentFormatted('full');
    }

    getAppointmentFormatted(type: ('date'|'time'|'full')) {
        const appointment = moment(this.appointment);

        const found = this.appointments.filter(item => {
            return moment(item.start).isSame(appointment);
        });

        return found && found.length
            ? type === 'date' ? found[0].getRange().date
            : type === 'time' ? [found[0].getRange().from, found[0].getRange().to].join(' - ')
            : found[0].range
            : appointment.format(
                type === 'full' ? 'llll'
                : type === 'date' ? 'ddd, ll'
                : type === 'time' ? 'LT' : ''
            );
    }

    get addressFormatted(): string {
        if (this.address) {
            return [this.address || ''].filter(item => item?.length).join(', ');
        } else {
            return [this.city || '', this.postal_code || ''].filter(item => item?.length).join(', ');
        }
    }

    isUserRelated(user: User): boolean {
        return this.client?.id === user?.id
            || this.itasker?.user?.id === user?.id
            || user.isAdmin()
            || this.team?.users?.filter(worker => worker.id === user?.id).length > 0;
    }

    get groupLabel(): string {
        const labels: {[name in JobGroup]: string} = {
            'internal': 'Internal',
            'proforma': 'Custom Invoice/Quote',
            'regular': 'Standard',
        };
        return this.group in labels ? labels[this.group] : '';
    }

    static fromJson(fields): Job {
        let item = Object.assign(new this(), fields);

        item.client = User.fromJson(fields?.client);
        item.service = Service.fromJson(fields?.service);
        item.type = JobType.fromJson(fields?.type);
        item.team = Team.fromJson(fields?.team);
        item.itasker = Itasker.fromJson(fields?.itasker);
        item.status = JobStatus.fromJson(fields?.status);
        item.invoice = Invoice.fromJson(fields?.invoice);
        item.price_type = PriceType.fromJson(fields?.price_type);
        item.appointments = fields?.appointments?.map((appointment: any) => JobAppointment.fromJson(appointment));
        item.suggestions = fields?.suggestions?.map((suggestion: any) => JobSuggestion.fromJson(suggestion));
        item.invoices = fields?.invoices?.map((invoice: any) => JobInvoice.fromJson(invoice));
        item.activities = fields?.activities?.map((item: any) => JobActivity.fromJson(item));
        item.review = ItaskerReview.fromJson(fields?.review);
        item.uploads = fields?.uploads?.map((item: any) => JobUpload.fromJson(item));
        item.forms = fields?.forms?.map((item: any) => JobForm.fromJson(item));

        return item;
    }
}
