import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { Paginate } from '../models/paginate.model';
import { ApiService } from './api.service';
import { NotificationItem } from './../models/notification-item.model';
import { environment } from 'src/environments/environment';

export interface NotificationServiceFilter {
    page?: number;
    limit?: number;
};

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    protected newMessageSubject: BehaviorSubject<number> = new BehaviorSubject(null);
    protected readonly DEBUG: boolean = !environment.production;

    constructor(
        private api: ApiService,
    ) { }

    /**
     * Get List
     * @param filter
     */
    getList(filter?: NotificationServiceFilter): Observable<Paginate<NotificationItem>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/notifications', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<NotificationItem>(), data);
                data.data = data.data?.map(item => {
                    try {
                        return NotificationItem.fromJson(item);
                    } catch (e) {
                        this.DEBUG && console.warn(e);
                    }

                    return null;
                }).filter(item => item);

                return data;
            })
        );
    }

    /**
     * Get new Items count
     * @returns
     */
    newItemsCount(): Observable<{data: {count: number}}> {
        return this.api.get('/notifications/new').pipe(
            map(data => {
                this.newMessageSubject.next(data?.data?.count || 0);
                return data;
            })
        );
    }

    /**
     * Set read status either by list of UUIDs or all of unread notifications (if UUID is not provided)
     * @param uuid
     */
    update(data?: {uuid?: string[]}): Observable<null> {
        return this.api.post('/notifications', data);
    }

    /**
     * Deletes a single item
     * @param uuid
     */
    delete(uuid: string): Observable<null> {
        return this.api.delete('/notifications/' + uuid);
    }

    /**
     * Current new notification items
     * @returns
     */
    getNewItemChanges(): Observable<number> {
        return this.newMessageSubject.asObservable().pipe(share());
    }
}
