import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/shared/models/user.model';

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {
    transform(user: any|User, defaultName: ''): string {
        return (
            user instanceof User ? user
                : Object.assign(new User, user)
        ).name || defaultName;
    }
}
