import * as moment from 'moment';

import { JobAppointment } from "./jobs/job-appointment.model";
import { InvoiceItem } from "./invoice-item.model";
import { InvoiceTaskcode } from "./invoice-taskcode.model";
import { InvoiceUpload } from "./invoice-upload.model";
import { JobPayment } from './jobs/job-payment.model';

export type InvoiceStatus = ('new'|'rejected'|'approved'|'paid'|'void'|'failed'|'refund'|'suspend');
export type InvoiceType = ('client'|'itasker'|'quote');

export class Invoice {
    id?: number;
    type?: InvoiceType;
    payment?: JobPayment;
    minutes?: number;
    status?: InvoiceStatus;
    notes?: string;
    details?: string;
    issuer?: string;
    recipient?: string;
    appointment?: string;
    appointments?: JobAppointment[];
    items?: InvoiceItem[];
    taskcodes: InvoiceTaskcode[];
    uploads?: InvoiceUpload[];
    created?: string;
    updated?: string;
    due_to?: string;
    amount?: string|number;
    currency?: string;
    download?: string;
    has_job?: number;

    static fromJson(fields): Invoice {
        let item = Object.assign(new this(), fields);

        item.items = item.items?.map(item => InvoiceItem.fromJson(item));
        item.payment = JobPayment.fromJson(item.payment);
        item.uploads = item.uploads?.map(item => InvoiceUpload.fromJson(item));

        return item;
    }

    get statusName(): string {
        const statuses: {[name in InvoiceStatus]: string} = {
            'new': 'New',
            'rejected': 'Rejected',
            'approved': 'Approved',
            'paid': 'Paid',
            'void': 'Void',
            'failed': 'Failed',
            'refund': 'Refund',
            'suspend': 'Suspend',
        };
        return statuses[this.status] || 'UNKNOWN';
    }

    get hasIssues(): boolean {
        return this.id >= 0 && [
            'failed',
            'rejected',
            'suspend',
            'void',
        ].indexOf(this.status) >= 0;
    }

    get isStatusNew(): boolean {
        return this.id >= 0 && [
            'new',
            'pending',
        ].indexOf(this.status) >= 0;
    }

    get typeName(): string {
        const types: {[name in InvoiceType]: string} = {
            'client': 'Client',
            'itasker': 'iTasker',
            'quote': 'Quote',
        };
        return types[this.type] || 'UNKNOWN';
    }

    get createdFormatted(): string {
        return moment.utc(this.created).format('ll');
    }

    get updatedFormatted(): string {
        return moment.utc(this.updated).format('ll');
    }

    get dueToFormatted(): string {
        return moment.utc(this.due_to).format('MM/DD/YYYY hh:mm A');
    }

    get idFormatted(): string {
        if (this.id <= 0) {
            return '---';
        }

        return '' + (this.id ?? '');

        // let result = this.id + '';
        // let count = result.length;

        // for (let i = count ; i < 10; i ++) {
        //     result = '0' + result;
        // }

        // return result;
    }

    get amountFormatted(): string {
        return parseFloat('' + (this.amount || 0)).toFixed(2) + ' ' + this.currency;
    }

    get paymentFormatted(): string {
        return parseFloat('' + (this.payment?.amount || 0)).toFixed(2) + ' ' + this.currency;
    }
}
