import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/app/shared/models/user.model';

export type AvatarSize = ('default'|'tiny'|'small'|'medium'|'large'|'big'|'huge');
export type AvatarComponentPrefix = ('xs'|'sm'|'md'|'lg'|'xl'|'xxl');
export interface AvatarComponentSizeResponsive {
    xs: AvatarSize;
    sm: AvatarSize;
    md: AvatarSize;
    lg: AvatarSize;
    xl: AvatarSize;
    xxl: AvatarSize;
};


@Component({
    selector: 'avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnDestroy {
    protected readonly DEFAULT_URL_WIDTH: number = 36 * 2;
    protected innerPhoto: string = null;
    protected innerUser: User = null;
    protected innerSize: AvatarSize = 'default';
    protected innerSizes?: AvatarComponentSizeResponsive = null;

    imageLoaded: boolean = true;
    imageError: boolean = false;

    initials: string = '';
    urlWidth: number = this.DEFAULT_URL_WIDTH;
    urlWidths: {[name in AvatarComponentPrefix]?: number} = {};
    nameToSizeMap: {[name in AvatarSize]: number} = {
        'tiny': 36*2,
        'default': 36*2,
        'small': 60*2,
        'medium': 80*2,
        'large': 100*2,
        'big': 150*2,
        'huge': 350*2,
    };

    @Output() onRemove: EventEmitter<any> = new EventEmitter();

    @Input()
    set photo(val: string) {
        if (this.innerPhoto !== val) {
            this.imageError = false;
            this.imageLoaded = true;
            this.innerPhoto = val;
        }
    }
    get photo(): string {
        return this.innerPhoto;
    }

    @Input()
    set user(val: User) {
        if (this.innerUser !== val) {
            if (this.innerUser?.avatar !== val?.avatar) {
                this.imageError = false;
                this.imageLoaded = true;
            }
            this.innerUser = val;
            this.initials = this.computeInitials();
        }
    }
    get user(): User {
        return this.innerUser;
    }

    @Input() enableRemoving: boolean = false;
    @Input() urlResize: boolean = true; // adds ?w=[number] to the end of URL's
    @Input() removingTitle: string = 'Remove avatar';
    @Input() status: boolean = null;
    @Input() showEmpty: boolean = false;
    @Input() withShadow: boolean = false;
    @Input() label: string = '';
    @Input() className: string = null;
    @Input() logo: boolean = false;

    @Input()
    set size(size: AvatarSize) {
        if (this.innerSize !== size) {
            this.innerSize = size;

            if (this.urlResize) {
                this.urlWidth = size in this.nameToSizeMap
                    ? this.nameToSizeMap[size]
                    : this.DEFAULT_URL_WIDTH;
            }
        }
    }
    get size() {
        return this.innerSize;
    }

    @Input()
    set sizes(sizes: AvatarComponentSizeResponsive) {
        if (this.innerSizes !== sizes) {
            this.innerSizes = sizes;


            for (let prefix in sizes) {
                let size = sizes[prefix];

                this.urlWidths[prefix] = size in this.nameToSizeMap
                    ? this.nameToSizeMap[size]
                    : this.DEFAULT_URL_WIDTH;
            }
        }
    }

    get sizes(): any {
        return this.innerSizes;
    }

    constructor() { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    onRemoveClicked(event: Event): boolean {
        event.preventDefault();
        this.onRemove.emit();
        return false;
    }

    protected computeInitials() {
        const name: string[] = (this.label || this.user?.name || '').trim().toUpperCase().trim().split(' ');
        let first: string = name[0] && name[0].length > 0 ? (name[0].match(/./ug) || [])[0] || '' : '';
        let second: string = name[1] && name[1].length > 0 ? (name[1].match(/./ug) || [])[0] || '' : '';
        return [first, second].join('').trim();
    }
}
