import { CustomFormTemplateField } from './custom-form-template-field.model'

export type CustomFormTemplateStatus = ('active'|'inactive');

export class CustomFormTemplate {
    id?: number;
    title?: string;
    description?: string;
    mime?: string;
    size?: number;
    filename?: string;
    path?: string;
    url?: string;
    fields?: CustomFormTemplateField[] = [];
    status?: CustomFormTemplateStatus;
    created?: string;
    updated?: string;

    static fromJson(fields: any): CustomFormTemplate {
        let item = Object.assign(new this(), fields);

        item.fields = fields?.fields?.map(item => CustomFormTemplateField.fromJson(item));

        return item;
    }


    /**
     * Remove all ID's from current custom form and fields, field options and field validations
     *
     * @returns
     */
    prepareForCloning(): CustomFormTemplate
    {
        const item = CustomFormTemplate.fromJson(this);
        item.id = null;
        return item;
    }
}
