import { Component, Input, OnInit } from '@angular/core';
import { ReactNativeService } from '../../services/react-native.service';

@Component({
    selector: 'mobile-apps',
    templateUrl: './mobile-apps.component.html',
    styleUrls: ['./mobile-apps.component.scss']
})
export class MobileAppsComponent implements OnInit {
    isMobileApp: boolean = true;

    @Input() footer: boolean = false;
    @Input() sidebar: boolean = false;
    @Input() withLabel: boolean = false;

    constructor(
        private reactNativeService: ReactNativeService,
    ) { }

    ngOnInit(): void {
        this.isMobileApp = this.reactNativeService.isMobile();
    }
}
