import { User } from "../user.model";

import * as moment from 'moment';
import { Job } from "./job.model";

export class JobActivity {
    id?: string;
    user?: User;
    data?: any;
    coordinate_lat?: number;
    coordinate_long?: number;
    created?: string;
    updated?: string;

    static fromJson(fields): JobActivity {
        let item = Object.assign(new this(), fields);
        item.user = User.fromJson(item?.user);
        return item;
    }

    public getAction(task?: Job): string {
        switch (this.data?.action) {
            case 'create':
                return 'created task';
            case 'appointment':
                return 'changed appointment time to';
            case 'team':
                return 'changed team to';
            case 'status':
                return 'changed status to';
            case 'suggestion':
                return 'applied for this task at';
            case 'hire':
                return 'hired';
            case 'cancel':
                return 'canceled'
            case 'auto-assign':
                return 'auto assigned to';
            case 'quote-issue':
                return 'issued a Quote';
            case 'invoice-issue':
                if (task?.group === 'internal') {
                    return 'issued a Closing sheet'
                }
                return 'issued an Invoice';
            case 'invoice-rejected':
                return 'rejected an Invoice';
            case 'invoice-approved':
                return 'approved an Invoice';
            case 'followup':
                return 'created a follow up task';
            case 'confirmed':
                return 'confirmed follow up task';
            case 'rate':
                return 'rated this task as';
            default:
                return '';
        }
    }

    get action(): string {
       return this.getAction();
    }

    get value(): string {
        if (['appointment', 'suggestion'].indexOf(this.data?.action) >= 0) {
            return moment(this.data?.value || '').format('llll');
        } else if (['quote-issue', 'invoice-issue', 'invoice-rejected', 'invoice-approved'].indexOf(this.data?.action) >= 0) {
            return '';
        } else if (['rate'].indexOf(this.data?.action) >= 0) {
            return (this.data?.value === 1) ? 'Satisfied' : 'Disappointed'
        }

        return this.data?.value || '';
    }

    public getLink(task?: Job): string {
        if (task?.group !== 'internal' && ['quote-issue', 'invoice-issue', 'invoice-rejected', 'invoice-approved'].indexOf(this.data?.action) >= 0) {
            return this.data?.value || '';
        } else {
            return null;
        }
    }

    get link(): string {
        return this.getLink();
    }

    get icon(): string|null {

        switch (this.data?.action) {
            case 'rate':
                if (this.data?.value === 1) {
                    return 'fa-solid fa-thumbs-up fa-fw fa-lg text-approve';
                } else {
                    return 'fa-solid fa-thumbs-down fa-fw fa-lg text-primary';
                }
            default:
                return null;
        }
    }

    get createdFormatted(): string {
        return moment(this.created).format('llll');
    }

    get updatedFormatted(): string {
        return moment(this.updated).format('llll');
    }

    get isToday(): boolean {
        return moment(this.created).isSame(moment(), 'day');
    }

    get isYesterday(): boolean {
        return moment(this.created).isSame(moment().subtract(1, 'day'), 'day');
    }
}
