import { User } from "../user.model";
import { CircleEvent } from "./circle-event.model";
import { CircleMember } from "./circle-member.model";
import { CirclePost } from "./circle-post.model";

export class Circle {
    id?: number;
    uuid?: string;
    title?: string;
    description?: string;
    image?: string;
    user?: User;
    location?: {
        address?: string;
        country?: string;
        city?: string;
        postal_code?: string;
        coordinate_lat?: number;
        coordinate_long?: number;
    };
    visibility?: ('public'|'private');
    status?: ('active'|'inactive');
    counts?: {
        members?: number;
        posts?: number;
        post_comments?: number;
        events?: number;
        event_interests?: number;
        favorites?: number;
        viewed?: number;
    };
    members?: CircleMember[];
    posts?: CirclePost[];
    events?: CircleEvent[];
    created?: string;
    updated?: string;
    can?: {
        join?: boolean;
        pending?: boolean;
        leave?: boolean;
        modify?: boolean;
        delete?: boolean;
        post?: boolean;
        comment?: boolean;
        event?: boolean;
        modify_members?: boolean;
    };

    static fromJson(fields): Circle {
        let item = Object.assign(new this(), fields);
        item.user = User.fromJson(fields?.user);
        item.members = fields?.members?.map(member => CircleMember.fromJson(member));
        item.posts = fields?.posts?.map(post => CirclePost.fromJson(post));
        item.events = fields?.events?.map(event => CircleEvent.fromJson(event));
        return item;
    }
}
