export type ServiceCategoryStatus = ('active'|'inactive');

export class ServiceCategory {
    id?: number;
    slug?: string;
    name?: string;
    image?: string;
    description?: string;
    show_homepage?: boolean;
    popular?: boolean;
    position?: string;
    status?: ServiceCategoryStatus;
    services?: any[];
    services_count?: number;
    created_at?: string;
    updated_at?: string;

    static fromJson(fields: any): ServiceCategory {
        let item = Object.assign(new this(), fields);

        item.show_homepage = item.show_homepage > 0;
        item.popular = item.popular > 0;

        return item;
    }
}
