export class Reference  {
    id: number = 0;
    full_name?: string;
    relationship?: string;
    company_name?: string;
    email?: string;
    phone?: string;
    description?: string;
    rating?: ('satisfied'|'disappointed');

    static fromJson(fields): Reference {
        let item = Object.assign(new this(), fields);
        return item;
    }
};
