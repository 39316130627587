import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationExtras } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ReactNativeService } from '../services/react-native.service';
import { SettingService } from '../services/setting.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    protected readonly ROUTE_GUEST: string = '/auth/login';
    protected readonly ROUTE_CHECK_IN: string = '/check-in'
    protected readonly ROUTE_NON_GUEST: string = '/';

    protected isAuthReady: boolean = false;
    protected checkinEnabled: boolean = false;

    constructor(
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
        private router: Router,
        private authService: AuthService,
        private settingService: SettingService,
        private reactNativeService: ReactNativeService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const getSettings = async () => {

            return new Promise((resolve, reject) => {
                this.settingService.getItems().subscribe({
                    next: data => {
                        this.checkinEnabled = data?.data?.filter(item => item.name === 'feature.checkin_enable')[0]?.value as boolean ?? false;

                        resolve(data);
                    },
                    error: error => reject(error)
                });
            });
        }

        if (isPlatformServer(this.platform)) {
            return new Promise((resolve, reject) => {
                let timeout = null;
                const authReadySubscribe: Subscription = this.authService.getAuthReady()
                    .subscribe({
                        next: async status => {
                            const authReady = String(status).toLowerCase() === 'true';
                            if (this.isAuthReady === authReady && authReady ) {
                                setTimeout(() => authReadySubscribe?.unsubscribe(), 10);
                                resolve(await this.checkPermissions(route, state));
                                return;
                            }
                            this.isAuthReady = authReady;
                            timeout && clearTimeout(timeout);
                            if (this.isAuthReady) {
                                setTimeout(() => authReadySubscribe?.unsubscribe(), 10);
                                await getSettings();
                                resolve(await this.checkPermissions(route, state));
                            } else {
                                timeout = setTimeout(() => {
                                    setTimeout(() => authReadySubscribe?.unsubscribe(), 10);
                                    getSettings().then(async () => {
                                        resolve(await this.checkPermissions(route, state));
                                    }).catch(async () => {
                                        resolve(await this.checkPermissions(route, state));
                                    })
                                }, 1000);
                            }
                        },
                        error: error => {
                        reject(error);
                    }
                });
            });
        } else if (this.isAuthReady) {
            // we have everything we need to make checkings
            return new Promise(async (resolve, reject) => {
                resolve(await this.checkPermissions(route, state));
            });
        } else {
            return new Promise((resolve, reject) => {
                this.authService.getAuthReady().pipe(
                    filter(status => status),  // don't care about "false" statuses
                    map(async status => {
                        this.isAuthReady = String(status).toLowerCase() === 'true';
                        await getSettings();
                        resolve(await this.checkPermissions(route, state));
                    }),
                    first()
                ).subscribe();
            });
        }
    }

    async checkPermissions(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let isGuest: boolean = this.authService.user.isGuest();
        let guestParams: NavigationExtras = {queryParams: {redirect: state.url}};

        return new Promise(async (resolve, reject) => {
            if ('data' in route && 'guest' in route.data) {
                if (isGuest && !route.data.guest) {
                    this.router.navigate([this.ROUTE_GUEST], guestParams);
                   resolve(false);
                } else if (!isGuest && route.data.guest) {
                    this.router.navigate([this.ROUTE_NON_GUEST]);
                   resolve(false);
                }
            }

            let current = route.root;
            while (current.children[0] !== undefined) {
                current = current.children[0];
            }

            if (!isPlatformServer(this.platform)
                && this.checkinEnabled === true
                && this.authService.user.hasGroups(['itasker', 'worker'])
                && !(state.url === this.ROUTE_CHECK_IN || state.url?.startsWith('/auth'))
                && !this.reactNativeService.isMobile()
                && typeof navigator !== 'undefined'
                && ('permissions' in navigator)
            ) {
                const geolocation = await navigator.permissions.query({name:'geolocation'});
                geolocation.state !== 'granted' && this.router.navigate([this.ROUTE_CHECK_IN]);
            }

            if ('groups' in current?.data
                && current.data.groups
                && !this.authService.user.hasGroups(current.data.groups)
            ) {
                if (isGuest) {
                    this.router.navigate([this.ROUTE_GUEST], guestParams);
                    resolve(false);
                } else if (!isGuest) {
                    this.router.navigate([this.ROUTE_NON_GUEST]);
                    resolve(false);
                }
            } else if ('guest' in current?.data) {
                if (isGuest && !current.data?.guest) {
                    this.router.navigate([this.ROUTE_GUEST], guestParams);
                    resolve(false);
                } else if (!isGuest && current.data?.guest) {
                    this.router.navigate([this.ROUTE_NON_GUEST]);
                    resolve(false);
                }
            }

            if ('permissions' in current?.data
                && current.data.permissions?.length
                && !this.authService.user.can(current.data.permissions)
            ) {
                this.router.navigate([this.ROUTE_NON_GUEST]);
                resolve(false);
            }

            if ('permissionsAnyOf' in current?.data
                && current.data.permissionsAnyOf?.length
                && !this.authService.user.canAnyOf(current.data.permissionsAnyOf)
            ) {
                this.router.navigate([this.ROUTE_NON_GUEST]);
                resolve(false);
            }

            resolve(true);
        });
    }
}
