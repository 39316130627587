import { User } from "../user.model";

export type CircleMemberStatus = ('pending'|'active'|'inactive'|'blocked');
export type CircleMemberRole = ('member'|'administrator'|'moderator');

export class CircleMember {
    role?: CircleMemberRole;
    status?: CircleMemberStatus;
    blocked?: {
        reason?: string;
        date?: string;
    };
    created?: string;
    user?: User;

    static fromJson(fields): CircleMember {
        let item = Object.assign(new this(), fields);
        item.user = User.fromJson(fields?.user);
        return item;
    }
}
