import { ServiceTax } from "./service-tax.model";
import { ServiceCategory } from './service-category.model';
import { ServiceDescription } from './service-description.model';
import * as moment from 'moment';
import { CustomForm } from "./custom-forms/custom-form.model";
import { Itasker } from "./itaskers/itasker.model";

export type ServiceType = ('regular'|'tax');
export type ServiceStatus = ('active'|'inactive');
export type ServiceDiscounts = ('enable'|'disable');
export type ServiceVerificationStatus = ('enable'|'disable');

interface ServiceTypeTax {
    id?: number;
    title?: string;
    name?: string;
    tax?: ServiceTax;
};


export class Service {
    id?: number;
    type?: ServiceType;
    slug?: string;
    category_id?: number;
    category?: ServiceCategory;
    name?: string;
    image?: string;
    avg_init_time?: string;
    avg_rate?: string;
    avg_time?: number;
    quote_fee?: number;
    description?: string;
    descriptions?: ServiceDescription[];
    status?: ServiceStatus;
    tags_count?: number;
    tags?: string[];
    taxes?: ServiceTypeTax[];
    range_rate?: {min?: number, max?: number};
    range_init_time?: number[];
    rate_description: string;
    discounts?: ServiceDiscounts;
    rate?: number|string;
    init_time?: number;
    init_time_duration: number;
    init_time_description: string;
    show_homepage?: boolean = false;
    show_tax_assistant?: boolean = false;
    custom_forms?: CustomForm[];
    verification?: ServiceVerificationStatus;

    has_itaskers?: boolean;

    itaskers?: Itasker[];
    itaskers_count?: number;
    itaskers_approved_count?: number;

    attempt?: number; // tracking ID - do not rename it

    static fromJson(fields: any): Service {
        let item = Object.assign(new this(), fields);

        item.taxes = item.taxes?.map(item => {
            item.tax = ServiceTax.fromJson(item?.tax);
            return item as ServiceTypeTax;
        });

        item.descriptions = fields?.descriptions?.map(description => ServiceDescription.fromJson(description));
        item.custom_forms = fields?.custom_forms?.map(custom => CustomForm.fromJson(custom));

        item.itakers = fields?.itaskers && Array.isArray(fields?.itaskers) ? fields?.itaskers?.map(item => Itasker.fromJson(item)) : [];

        return item;
    }

    get avgTimeFormatted(): string {
        let test = this.avg_time - 30;
        let avgTime = moment((test*60)*1000);
        let minutesRound = 30 - (avgTime.minute()%30)
        let avgTimeFormatted = moment.utc(avgTime).add(minutesRound, "minutes").format("H:mm")

        return avgTimeFormatted;
    }
}
