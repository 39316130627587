// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    application: 'iTaskApp Services',
    domain: 'localhost',
    scheme: 'http',
    port: 8000,
    webSockets: {
        broadcaster: 'pusher',
        key: 'aaa4778fd3e41aaa027741bdc0f8a6d9723d7d86',
        wsHost: typeof window !== 'undefined' ? window.location?.hostname : 'localhost',
        wsPort: 6001,
        wssPort: 6001,
        forceTLS: false,
        disableStats: true,
        cluster: '',
    },
    pagination: {
        default: 50,
        messages: 350,
        suggestions: 40,
        feed: 50,
        comments: 10,
        circles: 25,
    },
    gps: {
        timeout: 60 * 1000,
    },

    // caching requests
    cache: {
        default: 5 * 60 * 1000, // 5 min.
    },

    // external services
    services: {
        google: {
            key: 'AIzaSyD6NjyJSQliSFINgcx-b9A0peiO5l9fa4k',
            language: 'en',
            region: 'CA',
        },
        recaptcha: {
            key: '6Lf2RPoUAAAAAEfD0NHDI4cjdiEim5e7vlcN5rT3',
        },

        stripe: {
            key: 'pk_test_51IJnuHGnx2OpIgcanLjblW5ohA9BmWbde9VrMB6ob2DzFEcaNdoE6dhK5BPrax47iNbnERWJ2eHjFzG0EvVMgeD900pUfLUPRq',
            locale: 'en',
        }
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
