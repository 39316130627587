
import { CustomFormPostCondition } from './custom-form-post-condition.model';
import { CustomFormPostItem } from './custom-form-post-item.model';
import { CustomFormType } from './custom-form-type.model';

export type CustomFormPostStatus = ('active'|'inactive');

export class CustomFormPost {
    id?: number;
    title?: string;
    description?: string;
    field?: number;
    field_template?: number;
    type?: CustomFormType;
    status?: CustomFormPostStatus;
    items?: CustomFormPostItem[] = [];
    conditions?: CustomFormPostCondition[] = [];
    created?: string;
    updated?: string;

    static fromJson(fields: any): CustomFormPost {
        let item = Object.assign(new this(), fields);

        item.type = CustomFormType.fromJson(fields?.type);
        item.items = fields?.items?.map(item => CustomFormPostItem.fromJson(item));
        item.conditions = fields?.conditions?.map(item => CustomFormPostCondition.fromJson(item));

        return item;
    }
}
