import { CustomFormTemplateFieldOption } from './custom-form-template-field-option.model';

export class CustomFormTemplateField {
    id?: number;
    title?: string;
    name?: string;
    type?: string;
    options?: CustomFormTemplateFieldOption[];

    static fromJson(fields: any): CustomFormTemplateField {
        let item = Object.assign(new this(), fields);

        item.options = fields?.options?.map(item => CustomFormTemplateFieldOption.fromJson(item));

        return item;
    }
}
