
import { ProjectUserRated } from "./project-types";

export class ProjectCurrentUser {
    is_favorite?: boolean;
    rated?: ProjectUserRated;

    static fromJson(fields): ProjectCurrentUser {
        let item = Object.assign(new this(), fields);
        return item;
    }
};
