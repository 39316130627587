import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { GoogleMapsModule } from "@angular/google-maps";

import { GalleriaModule } from 'primeng/galleria';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { ListboxModule } from 'primeng/listbox';
import { CheckboxModule } from 'primeng/checkbox';
import { SkeletonModule } from 'primeng/skeleton';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { SliderModule } from 'primeng/slider';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputMaskModule } from 'primeng/inputmask';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppMainComponent } from './components/app-main/app-main.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';

import { ListItemComponent } from './components/list-item/list-item.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ListingTableComponent } from './components/listing-table/listing-table.component';
import { SafePipe } from './pipes/safe.pipe';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { AlertComponent } from './components/alert/alert.component';
import { UploadComponent } from './components/upload/upload.component';
import { FormatTimerPipe } from './pipes/format-timer.pipe';
import { IsArrayPipe } from './pipes/is-array.pipe';
import { InlineInputComponent } from './components/inline-input/inline-input.component';
import { InlineDateComponent } from './components/inline-date/inline-date.component';
import { CountInPipe } from './pipes/count-in.pipe';
import { InlineDropdownComponent } from './components/inline-dropdown/inline-dropdown.component';
import { ItemTemplateDirective } from './directives/item-template.directive';
import { ReCaptchaDirective } from './directives/recaptcha.directive';
import { InlineTextComponent } from './components/inline-text/inline-text.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { FilterPipe } from './pipes/filter.pipe';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { InfoboxComponent } from './components/infobox/infobox.component';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';
import { IntroComponent } from './components/intro/intro.component';
import { ItaskerComponent } from './components/itasker/itasker.component';
import { UploadAvatarComponent } from './components/upload-avatar/upload-avatar.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { UploadPhotoComponent } from './components/upload-photo/upload-photo.component';
import { TaskFlagComponent } from './components/task-flag/task-flag.component';
import { ModalMapComponent } from './components/modal-map/modal-map.component';
import { TaskItemComponent } from './components/task-item/task-item.component';
import { ModalDisputeComponent } from './components/modal-dispute/modal-dispute.component';
import { ModalRejectInvoiceComponent } from './components/modal-reject-invoice/modal-reject-invoice.component';
import { ModalSortItemsComponent } from './components/modal-sort-items/modal-sort-items.component';
import { ModalPaymentComponent } from './components/modal-payment/modal-payment.component';
import { MapAreasComponent } from './components/map-areas/map-areas.component';
import { AddressSuggestionComponent } from './components/address-suggestion/address-suggestion.component';
import { CoordsMatchPipe } from './pipes/coords-match.pipe';
import { SchedulerComponent } from './components/scheduler/scheduler.component';
import { HasAppointmentsPipe } from './pipes/has-appointments.pipe';
import { MobileAppsComponent } from './components/mobile-apps/mobile-apps.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { LinkUserComponent } from './components/link-user/link-user.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { ModalAssignTeamComponent } from './components/modal-assign-team/modal-assign-team.component';
import { ModalSetAppointmentComponent } from './components/modal-set-appointment/modal-set-appointment.component';
import { ExcludeUserPipe } from './pipes/exclude-user.pipe';
import { ExcludeUsersPipe } from './pipes/exclude-users.pipe';
import { FilesizePipe } from './pipes/filesize.pipe';
import { SendMessageLinkComponent } from './components/send-message-link/send-message-link.component';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { ModalChangeStatusComponent } from './components/modal-change-status/modal-change-status.component';
import { ImgDirective } from './directives/img.directive';
import { PageNavigationComponent } from './components/page-navigation/page-navigation.component';
import { ModalAuthComponent } from './components/modal-auth/modal-auth.component';
import { AuthLoginComponent } from './components/auth/login/auth-login.component';
import { AuthForgotComponent } from './components/auth/forgot/auth-forgot.component';
import { AuthRegisterComponent } from './components/auth/register/auth-register.component';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { LoadingComponent } from './components/loading/loading.component';
import { UserGroupPipe } from './pipes/user-group.pipe';
import { UserValidPipe } from './pipes/user-valid.pipe';
import { UserNamePipe } from './pipes/user-name.pipe';
import { UserFullNamePipe } from './pipes/user-full-name.pipe';
import { UserInitialsPipe } from './pipes/user-initials.pipe';
import { UndoClickDirective } from './directives/undo-click.directive';
import { ItaskerRatingComponent } from './components/itasker-rating/itasker-rating.component';
import { JobPricePipe } from './pipes/job-price.pipe';
import { PricetagComponent } from './components/pricetag/pricetag.component';
import { ToDatePipe } from './pipes/to-date.pipe';
import { JobAddressPipe } from './pipes/job-address.pipe';
import { JobAppointmentPipe } from './pipes/job-appointment.pipe';
import { ModalTaskApplyComponent } from './components/modal-task-apply/modal-task-apply.component';
import { ModalTaskConfirmComponent } from './components/modal-task-confirm/modal-task-confirm.component';
import { NumberTrailingZeroesPipe } from './pipes/number-trailing-zeroes.pipe';
import { ApprovalStatusPipe } from './pipes/approval-status.pipe';
import { ShortcutsComponent } from './components/shortcuts/shortcuts.component';
import { ModalDateComponent } from './components/modal-date/modal-date.component';
import { FindPipe } from './pipes/find.pipe';
import { ModalItaskerSendMessageComponent } from './components/modal-itasker-send-message/modal-itasker-send-message.component';
import { ModalUserCreateComponent } from './components/modal-user-create/modal-user-create.component';
import { ChooserComponent } from './components/chooser/chooser.component';
import { BecomeAnItaskerComponent } from './components/become-an-itasker/become-an-itasker.component';
import { AutosizeModule } from 'ngx-autosize';
import { TooltipDirective } from './directives/tooltip.directive';
import { ActiveStatusPipe } from './pipes/active-status.pipe';
import { NiceCountPipe } from './pipes/nice-count.pipe';
import { NiceRatingPipe } from './pipes/nice-rating.pipe';
import { ModalReportComponent } from './components/modal-report/modal-report.component';
import { ModalShareComponent } from './components/modal-share/modal-share.component';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { PageWrapperComponent } from './components/page-wrapper/page-wrapper.component';
import { UserCanPipe } from './pipes/user-can.pipe';
import { UserCanAnyPipe } from './pipes/user-can-any.pipe';
import { FormatMinutesPipe } from './pipes/format-minutes.pipe';
import { InvoiceStatusComponent } from './components/invoice-status/invoice-status.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { DurationPipe } from './pipes/duration.pipe';
import { ButtonMapComponent } from './components/button-map/button-map.component';
import { RequiredFieldDirective } from './directives/required-field.directive';
import { CustomFormComponent } from './components/custom-form/custom-form.component';
import { InterpolatePipe } from './pipes/interpolate.pipe';
import { SelectWidgetComponent } from './components/select-widget/select-widget.component';
import { InfoboxCompanyComponent } from './components/infobox-company/infobox-company.component';

@NgModule({
    declarations: [
        AppHeaderComponent,
        AppFooterComponent,
        AppMainComponent,
        ListItemComponent,
        AvatarComponent,
        SafePipe,
        ListingTableComponent,
        PaginationComponent,
        ModalConfirmComponent,
        AlertComponent,
        UploadComponent,
        FormatTimerPipe,
        IsArrayPipe,
        InlineInputComponent,
        InlineDateComponent,
        CountInPipe,
        InlineDropdownComponent,
        ItemTemplateDirective,
        InlineTextComponent,
        CountdownComponent,
        FilterPipe,
        Nl2brPipe,
        StripHtmlPipe,
        HighlightPipe,
        ReCaptchaDirective,
        ProfileCardComponent,
        InfoboxComponent,
        ModalInfoComponent,
        IntroComponent,
        ItaskerComponent,
        UploadAvatarComponent,
        UploadFileComponent,
        UploadPhotoComponent,
        TaskFlagComponent,
        ModalMapComponent,
        TaskItemComponent,
        ModalDisputeComponent,
        ModalSortItemsComponent,
        ModalPaymentComponent,
        MapAreasComponent,
        AddressSuggestionComponent,
        CoordsMatchPipe,
        SchedulerComponent,
        HasAppointmentsPipe,
        MobileAppsComponent,
        ModalRejectInvoiceComponent,
        TextEditorComponent,
        LinkUserComponent,
        InvoiceComponent,
        ModalAssignTeamComponent,
        ModalSetAppointmentComponent,
        ExcludeUserPipe,
        ExcludeUsersPipe,
        FilesizePipe,
        SendMessageLinkComponent,
        LinkifyPipe,
        ModalChangeStatusComponent,
        ImgDirective,
        PageNavigationComponent,
        ModalAuthComponent,
        AuthLoginComponent,
        AuthForgotComponent,
        AuthRegisterComponent,
        ServicesListComponent,
        LoadingComponent,
        UserGroupPipe,
        UserValidPipe,
        UserNamePipe,
        UserFullNamePipe,
        UserInitialsPipe,
        UndoClickDirective,
        ItaskerRatingComponent,
        JobPricePipe,
        PricetagComponent,
        ToDatePipe,
        JobAddressPipe,
        JobAppointmentPipe,
        ModalTaskApplyComponent,
        NumberTrailingZeroesPipe,
        ModalTaskConfirmComponent,
        ApprovalStatusPipe,
        ShortcutsComponent,
        ModalDateComponent,
        FindPipe,
        ModalItaskerSendMessageComponent,
        ModalUserCreateComponent,
        ChooserComponent,
        BecomeAnItaskerComponent,
        TooltipDirective,
        ActiveStatusPipe,
        NiceCountPipe,
        NiceRatingPipe,
        ModalReportComponent,
        ModalShareComponent,
        PageWrapperComponent,
        UserCanPipe,
        UserCanAnyPipe,
        FormatMinutesPipe,
        InvoiceStatusComponent,
        GalleryComponent,
        DurationPipe,
        ButtonMapComponent,
        RequiredFieldDirective,
        CustomFormComponent,
        InterpolatePipe,
        SelectWidgetComponent,
        InfoboxCompanyComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        DropdownModule,
        MultiSelectModule,
        BreadcrumbModule,
        SelectButtonModule,
        TabViewModule,
        DialogModule,
        MenuModule,
        ToastModule,
        ListboxModule,
        CheckboxModule,
        SkeletonModule,
        CalendarModule,
        AutoCompleteModule,
        InputNumberModule,
        InputMaskModule,
        SliderModule,
        ReactiveFormsModule,
        FormsModule,
        DragDropModule,
        InfiniteScrollModule,
        AutosizeModule,
        ShareModule,
        ShareButtonsModule,
        ShareIconsModule,
        GoogleMapsModule,
        NgxQRCodeModule,
        GalleriaModule,
        CKEditorModule,
    ],
    exports: [
        AppHeaderComponent,
        AppFooterComponent,
        AppMainComponent,
        ListItemComponent,
        RouterModule,
        AvatarComponent,
        RouterModule,
        DropdownModule,
        MultiSelectModule,
        BreadcrumbModule,
        SelectButtonModule,
        TabViewModule,
        DialogModule,
        MenuModule,
        ToastModule,
        ListboxModule,
        CheckboxModule,
        SkeletonModule,
        CalendarModule,
        AutoCompleteModule,
        InputNumberModule,
        InputMaskModule,
        SliderModule,
        ReactiveFormsModule,
        FormsModule,
        ListingTableComponent,
        PaginationComponent,
        ModalConfirmComponent,
        SafePipe,
        FormatTimerPipe,
        AlertComponent,
        UploadComponent,
        IsArrayPipe,
        InlineInputComponent,
        InlineDateComponent,
        InlineDropdownComponent,
        InlineTextComponent,
        CountInPipe,
        ItemTemplateDirective,
        CountdownComponent,
        DragDropModule,
        FilterPipe,
        InfiniteScrollModule,
        Nl2brPipe,
        StripHtmlPipe,
        HighlightPipe,
        ReCaptchaDirective,
        ProfileCardComponent,
        InfoboxComponent,
        ModalInfoComponent,
        IntroComponent,
        ItaskerComponent,
        UploadAvatarComponent,
        UploadFileComponent,
        UploadPhotoComponent,
        TaskFlagComponent,
        ModalMapComponent,
        TaskItemComponent,
        ModalDisputeComponent,
        ModalSortItemsComponent,
        ModalPaymentComponent,
        MapAreasComponent,
        AddressSuggestionComponent,
        CoordsMatchPipe,
        SchedulerComponent,
        HasAppointmentsPipe,
        MobileAppsComponent,
        ModalRejectInvoiceComponent,
        TextEditorComponent,
        LinkUserComponent,
        InvoiceComponent,
        ModalAssignTeamComponent,
        ModalSetAppointmentComponent,
        ExcludeUserPipe,
        ExcludeUsersPipe,
        FilesizePipe,
        SendMessageLinkComponent,
        LinkifyPipe,
        ModalChangeStatusComponent,
        ImgDirective,
        PageNavigationComponent,
        ModalAuthComponent,
        AuthLoginComponent,
        AuthRegisterComponent,
        AuthForgotComponent,
        ServicesListComponent,
        LoadingComponent,
        UserGroupPipe,
        UserValidPipe,
        UserNamePipe,
        UserFullNamePipe,
        UserInitialsPipe,
        UndoClickDirective,
        ItaskerRatingComponent,
        PricetagComponent,
        JobPricePipe,
        ToDatePipe,
        JobAddressPipe,
        JobAppointmentPipe,
        ModalTaskApplyComponent,
        NumberTrailingZeroesPipe,
        ModalTaskConfirmComponent,
        ApprovalStatusPipe,
        ShortcutsComponent,
        ModalDateComponent,
        FindPipe,
        ModalItaskerSendMessageComponent,
        ModalUserCreateComponent,
        ChooserComponent,
        BecomeAnItaskerComponent,
        AutosizeModule,
        TooltipDirective,
        ActiveStatusPipe,
        NiceCountPipe,
        NiceRatingPipe,
        ModalReportComponent,
        ModalShareComponent,
        PageWrapperComponent,
        UserCanPipe,
        UserCanAnyPipe,
        FormatMinutesPipe,
        InvoiceStatusComponent,
        GalleryComponent,
        DurationPipe,
        ButtonMapComponent,
        RequiredFieldDirective,
        CustomFormComponent,
        SelectWidgetComponent,
        InfoboxCompanyComponent,
        CKEditorModule,
    ]
})
export class SharedModule { }
