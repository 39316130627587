import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user.model';

@Pipe({
    name: 'userValid'
})
export class UserValidPipe implements PipeTransform {
    transform(user: User): boolean {
        return (user instanceof User ? user : User.fromJson(user)).isValid();
    }
}
