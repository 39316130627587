import { Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription, timer } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MainmenuService } from 'src/app/shared/services/mainmenu.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { ReactNativeService} from 'src/app/shared/services/react-native.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DomService } from 'src/app/shared/services/dom.service';
import { environment } from 'src/environments/environment';
import { WebsocketService } from './shared/services/websocket.service';
import { SettingService } from './shared/services/setting.service';
import { UndoClickService } from './shared/services/undo-click.service';
import { ProfileService } from './shared/services/profile.service';
import { isPlatformServer } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ],
    host: {
        '(window:message)': 'onReceiveMessage($event)',
        '(document:click)': 'onDocumentClick($event)',
    }
})
export class AppComponent implements OnDestroy, OnInit {
    readonly title: string = environment.application;
    readonly BASE_URL: string = environment.scheme + '://' + environment.domain;

    showMenu: boolean = false;
    showHeader: boolean = true;

    breadcrumbNavigation: MenuItem[] = [];
    urlChange: boolean = false;

    private subscriptions: Subscription[] = [];
    private profileSubscription: Subscription = null;
    private settingsSubscription: Subscription = null;
    private page: string = null;
    private isServer: boolean = false;
    private appsChooserEnabled: boolean = false;

    private checkinEnabled: boolean = false;
    private checkinTimeout: number = 1;
    private checkInSubscription: Subscription = null;

    private querySearch: string = null;
    private redirectUrl: string = null;
    private redirectUrlSkip: boolean = null;


    constructor(
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
        private settingService: SettingService,
        private authService: AuthService,
        private reactNativeService: ReactNativeService,
        private i18nService: I18nService,
        private mainMenu: MainmenuService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta,
        private breadcrumbService: BreadcrumbService,
        private domService: DomService,
        private renderer: Renderer2,
        private wsService: WebsocketService,
        private undoClickService: UndoClickService,
        private profileService: ProfileService,
    ) {
        this.isServer = isPlatformServer(this.platform);
    }

    ngOnInit() {
        try {
            if (typeof window !== 'undefined') {
                this.showHeader = !(window.self !== window.top || window.frameElement);
            }
        } catch (e) { }

        this.getAppChooserSettings();

        this.i18nService.initPrimeNG();
        this.i18nService.initMoment();

        this.initMetaTags(true);

        this.metaService.updateTag({
            property: 'og:title',
            content: this.title
        });

        const menuSubscribe = this.mainMenu.changes().subscribe(visible => {
            this.showMenu = visible;
        });
        this.subscriptions.push(menuSubscribe);

        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                // reset meta tags
                this.initMetaTags();

                this.redirectUrlSkip = event.url !== '/';
            });

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.reactNativeService.notifyUrlChange(event.url);


                let current = this.route.root;
                while (current.children[0] !== undefined) {
                    current = current.children[0];
                }
                this.page = current.snapshot.data['page'] || null;

                if (!current?.snapshot?.data['skip_moveToTop'] ?? false) {
                    this.domService.moveToTop();
                }

                const url = event.url.replace(/(^\/+|\/$)/g, '') ?? '';
                url !== '' && this.reactNativeService.setAppChooserSeen(true);

                if (this.page === 'apps') {
                    this.showHeader = false;
                } else if (this.appsChooserEnabled && this.reactNativeService.isMobile() && !this.reactNativeService.isAppChooserSeen()) {
                    this.router.navigateByUrl('/apps');
                } else {
                    try {
                        if (typeof window !== 'undefined') {
                            this.showHeader = !(window.self !== window.top || window.frameElement);
                        }
                    } catch (e) { }
                }

                this.breadcrumbService.init(this.route.root);
                this.metaService.updateTag({
                    property: 'og:url',
                    content: this.BASE_URL + event.url
                });

                this.redirectsUrlOnQueryClearHandler(event);
            });

        this.breadcrumbService.onTitleUpdate().subscribe(title => {
            let prefix = this.breadcrumbService.getTitlePrefix() ?? '';
            title.unshift((prefix?.length > 0 ? prefix + ' ' : '') + this.title);
            this.titleService.setTitle(title.join(' - '));
            this.metaService.updateTag({
                property: 'og:title',
                content: title.join(' - ')
            });
        });

        if (this.reactNativeService.isMobile()) {
            this.renderer.setStyle(document.body.parentNode, 'background-color', '#f7f7f7');
        }

        let user = null;

        const authSubscribe = this.authService.getStatus().subscribe(status => {
            if (user !== this.authService.user?.id) {
                if (status.isSigned) {
                    this.initProfileReceive(this.authService.user?.id);
                } else {
                    this.profileSubscription && this.profileSubscription.unsubscribe();
                }

                this.reactNativeService.notifyUserChange(this.authService?.user?.compactData);
            }

            user = this.authService.user.id;

            this.checkInEvents();
        });
        this.subscriptions.push(authSubscribe);

        this.eventsReceive();
    }

    ngOnDestroy() {
        this.subscriptions.map(item => item?.unsubscribe());
        this.profileSubscription && this.profileSubscription.unsubscribe();
        this.settingsSubscription && this.settingsSubscription.unsubscribe();
        this.checkInSubscription && this.checkInSubscription.unsubscribe();
    }

    onReceiveMessage(data: any): void {
        this.reactNativeService.messageReceived(data);
    }

    onDocumentClick(event?: Event): void {
        this.undoClickService.setClick(event);
    }

    protected initProfileReceive(userId): void {
        this.profileSubscription && this.profileSubscription.unsubscribe();
        this.profileSubscription = this.wsService
            .on('profile.update', 'private', 'user.' + userId)
            .subscribe((data: any) => {
                this.authService.user?.isValid() && this.authService.getActualProfileData().catch(e => {}).then();
            });
    }

    protected eventsReceive(): void {
        this.settingsSubscription && this.settingsSubscription.unsubscribe();
        this.settingsSubscription = this.wsService
            .on('setting.update', 'public', 'events')
            .subscribe((data: any) => {
                this.settingService.getItems().subscribe(data => {}, error => {});
            });
    }

    protected getAppChooserSettings(): void {
        const settingsSubscription = this.settingService.getCurrentSettings().subscribe(data => {
            this.appsChooserEnabled =
                data.find(setting => setting.name === 'apps.chooser')?.value === true
                && data.filter(item => item.name.match(/^apps\.[\d]+\.status$/i) && item.value).length > 0;

            this.checkinEnabled = data.filter(item => item.name === 'feature.checkin_enable')[0]?.value as boolean ?? false;
            this.checkinTimeout = data.filter(item => item.name === 'feature.checkin_timeout')[0]?.value as number ?? 0;
        });

        this.subscriptions.push(settingsSubscription);
    }

    protected checkInEvents()
    {
        this.checkInSubscription?.unsubscribe();

        if (this.isServer || !this.checkinEnabled || !this.authService?.user?.hasGroups(['itasker', 'worker'])) {
            return;
        }

        this.checkInSubscription = this.profileService.updateActivity(this.checkinTimeout, '/check-in');
    }

    protected initMetaTags(update: boolean = true)
    {
        const defaults: Array<{property?: string, name?: string, content?: string}> = [
            {
                property: 'og:title',
                content: this.title
            }, {
                property: 'og:site_name',
                content: environment.application
            }, {
                property: 'og:type',
                content: 'website'
            }, {
                property: 'og:description',
                content: 'Getting your task completed. Just a click away',
            }, {
                property: 'og:image',
                content: this.BASE_URL + '/img/logo-default.png',
            }, {
                name: 'keywords',
                content: '',
            }, {
                name: 'description',
                content: 'Getting your task completed. Just a click away',
            }
        ];

        if (!update) {
            this.metaService.addTags(defaults);
            return;
        }

        let images = this.metaService.getTags("property='og:image'")?.length;
        if (images > 1) {
            for (let i = 0; i < images; i++) {
                this.metaService.removeTag('property="og:image"');
            }
        }

        for (let meta of defaults) {
            meta.property
            this.metaService.updateTag({
                [meta.name ? 'name' : 'property']: meta?.property ?? meta.name,
                content: meta.content
            });
        }
    }

    protected redirectsUrlOnQueryClearHandler(event: NavigationEnd): void {
        if (!(this.route?.snapshot?.queryParams['q'] ?? '')?.length && !this.querySearch?.length) {
            this.redirectUrl = event?.url;
        }

        if (this.redirectUrl?.length && this.redirectUrl !== '/' && this.querySearch?.length && !(this.route?.snapshot?.queryParams['q'] ?? '')?.length) {
            setTimeout(() => {
                !this.redirectUrlSkip && this.router.navigateByUrl(this.redirectUrl);
                this.redirectUrl = null;
            }, 100);
        }

        this.querySearch = this.route?.snapshot?.queryParams['q'] ?? null;
    }
}
