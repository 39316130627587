
import { Itasker } from "../itaskers/itasker.model";
import { ProjectCurrentUser } from "./project-current-user.model";
import { ProjectStatus, ProjectCompletion } from './project-types';

export class ProjectIndex {
    id?: string;
    uuid?: string;
    title?: string;
    description?: string;
    cover_photo?: string;
    rating?: number;
    favorites?: number;
    comments?: number;
    status?: ProjectStatus;
    completion?: ProjectCompletion;
    images_count?: number;
    itasker?: Itasker;
    current_user?: ProjectCurrentUser;

    static fromJson(fields): ProjectIndex {
        let item = Object.assign(new this(), fields);

        if (item?.itasker) {
            item.itasker = Itasker.fromJson(item?.itasker);
        }

        item.current_user = ProjectCurrentUser.fromJson(item?.current_user);

        return item;
    }
};
