export class ServiceTax {
    title?: string;
    description?: string;
    percent?: number;
    fixed?: number;
    currency?: string;
    invoice?: boolean;

    static fromJson(fields: any): ServiceTax {
        let item = Object.assign(new this(), fields);
        return item;
    }

    isValid(): boolean {
        return ((this.fixed > 0) || (this.percent > 0)) && this.currency?.length > 0;
    }
}
