import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user.model';

@Pipe({
    name: 'userCan'
})
export class UserCanPipe implements PipeTransform {

    transform(user: any|User, ...args: string[]): boolean {
        const account: User = user instanceof User
            ? user
            : Object.assign(new User, user);

        return account.can(args, false);
    }
}
