import { environment } from "src/environments/environment";


export class Upload {
    title?: string;
    description?: string;
    size?: number;
    url?: string;
    path?: string;
    mime?: string;

    // custom
    protected _isImageFormat?: boolean = undefined;
    protected _urlSmallerImage?: string = null;

    static fromJson(fields: any): Upload {
        let item = Object.assign(new this(), fields);
        return item;
    }

    get isFormatImage(): boolean {
        if (typeof this._isImageFormat === 'undefined') {
            this._isImageFormat = this.mime?.split('/')[0] === 'image';
        }
        return this._isImageFormat;
    }

    get urlSmallerImage(): string {
        if (this._urlSmallerImage === null) {
            this._urlSmallerImage = (new RegExp("https?\://" + environment.domain + '[\:/]')).test(this.url)
                ? this.url + '?w=200'
                : this.url;
        }
        return this._urlSmallerImage;
    }
}
