import { User } from './user.model';

export type CommentStatus = ('active'|'inactive');

export class Comment {
    id?: number;
    description?: string;
    status?: CommentStatus;
    created?: string;
    updated?: string;
    user?: User;
    last?: boolean = false;

    // custom
    isUpdated?: boolean = false;

    static fromJson(fields): Comment {
        let item = Object.assign(new this(), fields);
        item.user = User.fromJson(item?.user);

        item.isUpdated = item?.updated?.length && item?.created !== item?.updated;
        return item;
    }
}
