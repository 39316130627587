import { User } from './user.model';
import { TeamCoordinate } from './team-coordinate.model';
import { JobService } from './jobs/job-service.model';
import { Itasker } from './itaskers/itasker.model';

export type TeamApproval = ('approved' | 'rejected' | 'pending');

export class Team {
    id?: number;
    itasker?: Itasker;
    title?: string;
    services?: JobService[];
    users?: User[];
    coordinates?: TeamCoordinate[];
    approval?: TeamApproval;
    created_type?: ('system'|'user') = 'user';
    created?: string;
    updated?: string;

    static fromJson(fields: any): Team {
        let item = Object.assign(new this(), fields);

        item.users = item?.users?.map(item => User.fromJson(item));
        item.services = item?.services?.map(service => JobService.fromJson(service));
        item.coordinates = item?.coordinates?.map(coords => TeamCoordinate.fromJson(coords));
        item.itasker = Itasker.fromJson(item?.itasker);

        return item;
    }

    static onlyWithServices(teams: Team[], services: JobService[]): Team[] {
        const serviceIds = services?.map(item => item?.id);
        return (teams || []).filter(item => item.services?.filter(service => serviceIds.indexOf(service.id) !== -1).length > 0);
    }

    isApproved(): boolean {
        return this.approval === 'approved' || this.approval === 'pending';
    }
}
