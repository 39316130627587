import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MainmenuService } from 'src/app/shared/services/mainmenu.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { User } from 'src/app/shared/models/user.model';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MenuItem } from 'primeng/api';
import { UserGroupType } from '../../models/user-group.model';
import { ReactNativeService } from '../../services/react-native.service';
import { ChatService } from '../../services/chat.service';
import { NotificationService, NotificationServiceFilter } from '../../services/notification.service';
import { NotificationItem } from '../../models/notification-item.model';
import { Paginate } from '../../models/paginate.model';
import { KeyValue } from '@angular/common';
import { SettingService } from '../../services/setting.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { SearchbarService } from 'src/app/shared/services/searchbar.service';

interface MainMenuItem {
    hidden?: boolean;
    disabled?: boolean;
    title?: string;
    name?: string;
    groups?: UserGroupType[];
    permissions?: string[];
    shouldHide?: (user: User) => boolean;
    route?: string|((user: User) => string);
    queryParams?: any;
    exact?: any;
    href?: string;
    class?: string;
    classParent?: string;
    children?: MainMenuItem[];
    icon?: string;
    badge?: string|null;
    separator?: boolean;
    active?: boolean;
};

interface Menu {
    [name: string]: MainMenuItem;
};

interface NotificationViewItem extends NotificationItem {
    photo?: string;
    fragmentUrl?: string;
    content?: string;
    date?: string;
    url?: string;
    queryParamsList?: object|null;
};

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
    host: {
        '(window:visibilitychange)': 'onWindowVisibilityChange($event)',
    }
})
export class AppHeaderComponent implements OnInit, OnDestroy {
    protected readonly PAGINATION_LIMIT: number = environment.pagination.default;
    protected readonly SEARCHBAR_ROUTE: string = '/';
    protected readonly SEARCHBAR_PLACEHOLDER: string = 'Find the services you need...';

    protected innerLoadingQueue: number = 0;
    protected subscriptions: Subscription[] = [];
    protected menuSubscribe: Subscription = null;
    protected authReadySubscribe: Subscription = null;
    protected authStatusSubscribe: Subscription = null;
    protected chatSubscribe: Subscription = null;
    protected innerShowNotifications: boolean = null;
    protected invisibleWindow: boolean = document?.visibilityState === 'hidden';

    @ViewChild('chatNotification') chatNotification: ElementRef = null;
    chatNotificationLast: any = null;
    chatNotificationMinTime: number = 8; // seconds

    @Input() breadcrumb: MenuItem[] = [];

    isMobileApp: boolean = true;
    isAuthReady: boolean = false;

    appName: string = environment.application;
    loading: boolean = false;
    user: User = new User();
    authMode: ('auth'|'reset') = 'auth';
    authToken: string = null;
    authEmail: string = null;
    notifications: Paginate<NotificationItem> = null;
    notificationsFilter: NotificationServiceFilter = {
        limit: this.PAGINATION_LIMIT,
        page: 1,
    }
    apps: any = null;
    featureCustomInvoices: boolean = false;
    featureCustomQuotes: boolean = false;
    featureCheckinEnabled: boolean = false;
    featureNeighborhoodEnable: boolean = false;
    featureItaskCoin: boolean = false;
    featureDashboardAssets: boolean = false;
    itaskServicesUrl: string = null;
    currentPage: string = '/';
    isPageCircle: boolean = false;

    showAuth: boolean = false;
    showMenu: boolean = false;
    showProfileMenu: boolean = false;

    @ViewChild('searchBarElement') searchBarElement: ElementRef = null;
    searchBarTimer: any = null;
    searchBarInput: string = '';
    searchBarVisible: boolean = true;
    searchBarRoute: string = this.SEARCHBAR_ROUTE;
    searchBarPlaceholder: string = this.SEARCHBAR_PLACEHOLDER;

    set showNotifications(value: boolean) {
        if (this.innerShowNotifications !== value) {
            this.innerShowNotifications = value;

            if (this.innerShowNotifications) {
                this.loadNotifications(null);
            }
        }
    }
    get showNotifications(): boolean {
        return this.innerShowNotifications;
    }

    menus: Menu = {
        home: {
            title: 'Home',
            route: '/',
            exact: true,
            icon: 'fa-solid fa-house fa-fw',
            shouldHide: (user: User) => {
                return user?.isValid()
            }
        },
        discount_club: {
            title: 'Discount Club',
            groups: [],
            route: '/discount-club',
            icon: 'fa-solid fa-tags fa-fw',
            shouldHide: (user: User) => {
                return user?.hasGroups('itasker');
            },
        },
        about_us: {
            title: 'About Us',
            groups: ['guest'],
            route: '/about/us',
            icon: 'fa-solid fa-circle-info fa-fw',
        },
        blog: {
            title: 'Blog',
            href: 'https://blog.itask.com/',
            icon: 'fa-solid fa-newspaper fa-fw',
            shouldHide: (user: User) => {
                return user?.hasGroups('itasker');
            },
        },
        dashboard: {
            title: 'Dashboard',
            route: '/dashboard',
            icon: 'fa-solid fa-chalkboard fa-fw',
            classParent: 'd-none d-lg-inline',
            // queryParams: {'tab': 'taskboard'},
            shouldHide: (user: User) => {
                return !user?.isValid()
            },
        },
        dashboard_mobile: {
            title: 'Dashboard',
            icon: 'fa-solid fa-chalkboard fa-fw',
            classParent: 'd-lg-none',
            shouldHide: (user: User) => {
                return !user?.isValid()
            },
            children: [{
                title: 'Tasks',
                route: '/dashboard',
                icon: 'fa-regular fa-paste fa-fw',
                queryParams: {'tab': 'taskboard'}
            }, {
                title: 'Finances',
                route: '/dashboard',
                icon: 'fa-regular fa-paste fa-fw',
                queryParams: {'tab': 'finances'}
            }, {
                title: 'Wallet',
                route: '/dashboard',
                icon: 'fa-solid fa-wallet fa-fw',
                queryParams: {'tab': 'wallet'},
                shouldHide: (user: User) => {
                    return !this.featureItaskCoin;
                }
            }, {
                title: 'Assets',
                route: '/dashboard',
                icon: 'fa-regular fa-paste fa-fw',
                shouldHide: (user: User) => {
                    return !this.featureDashboardAssets;
                },
                queryParams: {'tab': 'assets'},
            }, {
                title: 'Analytics',
                route: '/dashboard',
                icon: 'fa-regular fa-paste fa-fw',
                queryParams: {'tab': 'analytics'},
                shouldHide: (user: User) => {
                    return true
                },
            }, {
                title: 'Clients',
                route: '/dashboard',
                icon: 'fa-regular fa-paste fa-fw',
                queryParams: {'tab': 'clients'},
                shouldHide: (user: User) => {
                    return !user?.hasGroups(['admin','itasker'])
                },
            }]
        },
        wallet: {
            title: 'Finances',
            groups: ['client'],
            route: '/finances',
            icon: 'fa-solid fa-wallet fa-fw',
        },
        company: {
            title: 'Company',
            groups: ['itasker'],
            icon: 'fa-solid fa-briefcase fa-fw',
            shouldHide: (user: User): boolean => {
                return !user?.itasker_details?.id;
            },
            children: [{
                title: 'Overview',
                route: '/profile/summary',
                icon: 'fa-regular fa-paste fa-fw',
                shouldHide: (user: User): boolean => {
                    return !user.itasker;
                },
                queryParams: {'edit':true}
            }, {
                name: 'profile',
                title: 'Profile',
                route: '/profile',
                icon: 'fa-solid fa-id-card-clip fa-fw',
                shouldHide: (user: User): boolean => {
                    return !user.itasker;
                },
            }, {
                separator: true,
            // }, {
            //     title: 'Documents',
            //     route: '/documents',
            //     icon: 'fa-solid fa-file-lines fa-fw',
            }, {
                title: 'Workers',
                route: '/workers',
                icon: 'fa-solid fa-person-digging fa-fw',
            }, {
                title: 'Teams',
                route: '/teams',
                icon: 'fa-solid fa-truck-pickup fa-fw',

            }, {
                title: 'Task Codes',
                route: '/task-codes',
                icon: 'fa-solid fa-list-check fa-fw',
            }, {
                separator: true,
            },{
                title: 'Clients',
                route: '/itasker-clients',
                icon: 'fa-solid fa-address-book fa-fw',
            }, {
                separator: true,
            }, {
                title: 'Finances',
                groups: ['itasker'],
                route: '/finances',
                exact: true,
                icon: 'fa-solid fa-wallet fa-fw',
            }]
        },
        messages: {
            title: 'Messages',
            route: '/messages',
            icon: 'fa-solid fa-envelope fa-fw',
            shouldHide: (user) => {
                return this.reactNativeService.isMobile();
            }
        },
        connections: {
            title: 'Connections',
            route: '/connections',
            icon: 'fa-solid fa-people-group fa-fw',
            groups: ['itasker', 'worker', 'client', 'admin'],
        },
        neighborhood: {
            title: 'Neighborhood',
            route: '/neighborhood',
            icon: 'fa-solid fa-people-roof fa-fw',
            shouldHide: (user) => {
                return !this.featureNeighborhoodEnable && this.currentPage !== '/neighborhood';
            }
        },
        messages_signed: {
            title: 'Messages',
            route: '/messages',
            icon: 'fa-regular fa-comment-dots fa-fw',
            groups: ['itasker', 'worker', 'client', 'admin'],
        },
        preferences: {
            title: 'Preferences',
            route: '/profile/preferences',
            icon: 'fa-solid fa-screwdriver-wrench fa-fw',
        },
        manage: {
            title: 'Manage',
            groups: ['admin'],
            icon: 'fa-solid fa-gear fa-fw',
            children: [{
                title: 'Users',
                route: '/users',
                icon: 'fa-solid fa-user-pen fa-fw',
                permissions: ['user.index'],
            }, {
                title: 'Roles',
                route: '/roles',
                icon: 'fa-solid fa-user-lock fa-fw',
                permissions: ['role.index'],
            }, {
                separator: true,
            }, {
                title: 'Companies',
                route: '/companies',
                icon: 'fa-solid fa-helmet-safety fa-fw',
                permissions: ['company.index'],
            }, {
                title: 'Teams',
                route: '/teams',
                icon: 'fa-solid fa-people-group fa-fw',
                permissions: ['team.index'],
            }, {
                title: 'Workers',
                route: '/workers',
                icon: 'fa-solid fa-person-digging fa-fw',
                permissions: ['worker.index'],
            }, {
                title: 'Task Codes',
                route: '/task-codes',
                icon: 'fa-solid fa-list-check fa-fw',
                permissions: ['taskcode.index'],
            }, {
                separator: true,
            }, {
                title: 'Promo Codes',
                route: '/promo-codes',
                icon: 'fa-solid fa-gift fa-fw',
                permissions: ['promocode.index'],
            }, {
                title: 'Finances',
                route: '/finances',
                icon: 'fa-solid fa-file-invoice-dollar fa-fw',
                permissions: ['invoice.index'],
            }, {
                separator: true,
            }, {
                title: 'Pages',
                route: '/pages',
                icon: 'fa-solid fa-file-contract fa-fw',
                permissions: ['article.index'],
            }, {
                title: 'Categories',
                route: '/categories',
                icon: 'fa-solid fa-sitemap fa-fw',
                permissions: ['category.index'],
            }, {
                title: 'Services',
                route: '/services-manage',
                icon: 'fa-solid fa-hand-holding-dollar fa-fw',
                permissions: ['service.index'],
            }, {
                title: 'Custom forms',
                route: '/custom-forms',
                icon: 'fa-fw fa-solid fa-list-ul',
                permissions: ['customform.index'],
            }, {
                title: 'Shortcuts',
                route: '/shortcuts',
                icon: 'fa-solid fa-table-cells-large fa-fw',
                permissions: ['shortcut.index'],
            }, {
                title: 'Task Types',
                route: '/task-types',
                icon: 'fa-solid fa-money-check fa-fw',
                exact: true,
                permissions: ['task.type.index'],
            }, {
                separator: true,
            }, {
                title: 'Statistics',
                route: '/stats',
                icon: 'fa-solid fa-chart-pie fa-fw',
                permissions: ['tracking.index'],
            }, {
                title: 'Preferences',
                route: '/preferences',
                icon: 'fa-sharp fa-solid fa-bell-on fa-fw',
                permissions: ['preference.index'],
            }, {
                title: 'System Settings',
                route: '/settings',
                icon: 'fa-solid fa-gears fa-fw',
                permissions: ['setting.index'],
            }]
        },
        become_an_itasker: {
            title: 'Become an iTasker',
            groups: ['itasker'],
            route: "/become-itasker/getting-started",
            icon: 'fa-solid fa-helmet-safety fa-fw',
            shouldHide: (user) => {
                return user.itasker;
            }
        },
        profile: {
            title: 'Profile',
            route: '/profile',
            exact: true,
            icon: 'fa-solid fa-circle-user fa-fw',
        },
        log_out: {
            title: 'Log Out',
            route: '/auth/logout',
            icon: 'fa-solid fa-arrow-right-from-bracket fa-fw',
        },
        check_in_out: {
            title: 'Check In/Out',
            groups: ['itasker', 'worker'],
            href: 'itaskapp://check-in',
            icon: 'fa-solid fa-user-check fa-fw',
            shouldHide: (user): boolean => {
                return !this.featureCheckinEnabled || !this.isMobileApp;
            }
        },
        sign_up: {
            title: 'Create account',
            groups: ['guest'],
            route: '/auth/register',
            classParent: 'align-self-start align-self-lg-center d-none d-lg-inline-block',
            class: 'd-inline-block btn btn-outline-primary px-3 py-1 me-1 btn-sm sign-btn',
            icon: 'fas fa-user-plus fa-fw'
        },
        log_in: {
            title: 'Sign in',
            groups: ['guest'],
            route: '/auth/login',
            classParent: 'ms-0 ms-lg-2 align-self-start align-self-lg-center  d-none d-lg-inline-block' ,
            class: 'd-inline-block btn btn-primary btn-sm px-3 py-1 me-1 sign-btn',
            icon: 'fas fa-sign-in-alt fa-fw'
        },
        separator: {
            separator: true,
        }
    };

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private mainMenu: MainmenuService,
        private authService: AuthService,
        private reactNativeService: ReactNativeService,
        private chatService: ChatService,
        private notificationService: NotificationService,
        private settingService: SettingService,
        private breadcrumbService: BreadcrumbService,
        private changeDetectorRef: ChangeDetectorRef,
        private searchbarService: SearchbarService,
    ) {
        this.itaskServicesUrl = environment.scheme
            + "://" + environment.domain
            + ([443, 80, null].indexOf(environment.port) < 0 ? ":" + environment.port : '');
    }

    ngOnInit(): void {
        this.isMobileApp = this.reactNativeService.isMobile();

        this.menuSubscribe = this.mainMenu.changes().subscribe(visible => {
            this.showMenu = visible;
        });

        this.authReadySubscribe = this.authService.getAuthReady()
            .pipe(filter(status => status)).subscribe(ready => {
                this.isAuthReady = String(ready).toLowerCase() === 'true';
            });

        let isSigned = null;
        this.authStatusSubscribe = this.authService.getStatus().subscribe(status => {
            this.user = this.authService.user;
            this.toggleMenuItems(this.menus);

            this.menus['messages'].badge = this.user?.counters?.chats > 0
                ? ('' +  this.user?.counters?.chats)
                : null;

            if (this.user?.id && isSigned !== status.isSigned) {
                this.chatSubscribe && this.chatSubscribe.unsubscribe();
                this.chatSubscribe = this.chatService.newMessageReceived(this.user?.id)
                    .subscribe(data => {}, error => {});

                isSigned = status.isSigned;
            }

            this.menus['company'].children = this.menus['company']?.children?.map(child => {
                if (child.name === 'profile') {
                    child.route = "/profile/" + (this.user?.itasker_details?.uuid ?? '');
                }
                return child;
            });
        });

        const settingSubscription = this.settingService.getCurrentSettings().subscribe(data => {
            this.featureCustomInvoices = data.filter(item => item.name === 'feature.custom_invoices')[0]?.value as boolean ?? false;
            this.featureCustomQuotes = data.filter(item => item.name === 'feature.custom_quotes')[0]?.value as boolean ?? false;
            this.featureCheckinEnabled = data.filter(item => item.name === 'feature.checkin_enable')[0]?.value as boolean ?? false;
            this.featureNeighborhoodEnable = data.filter(item => item.name === 'feature.neighborhood')[0]?.value as boolean ?? false;
            this.featureItaskCoin = data.filter(item => item.name === 'feature.itaskcoin')[0]?.value as boolean ?? false;
            this.featureDashboardAssets = data.filter(item => item.name === 'feature.dashboard_assets')[0]?.value as boolean ?? false;

            this.toggleMenuItems(this.menus);

            this.apps = [];
            let apps = {};

            for (let item in data) {
                if (data[item].name.indexOf('apps.') >= 0) {
                    const name = data[item].name.split('.');

                    if (name[1] in apps) {
                        apps[name[1]][name[2]] = data[item].value;
                    } else {
                        apps[name[1]] = {
                            [name[2]]: data[item].value
                        };
                    }
                }
            }

            for (let app in apps) {
                if (apps[app].status) {
                    apps[app].type = 'link';
                    this.apps.push(apps[app]);
                }
            }
        });
        this.subscriptions.push(settingSubscription);

        this.route.queryParams.subscribe((data: any) => {
            this.searchBarInput = data['q'] || '';

            if (data && 'token' in data && (data.token || '').length) {
                this.authToken = data.token;
                this.authEmail = data.email || '';
                this.authMode = 'reset';
                this.showAuth = true;
            }
        });

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                let current = this.route.root;
                while (current.children[0] !== undefined) {
                    current = current.children[0];
                }

                const search = current.snapshot.data['search'] || null;

                if (search) {
                    // this.searchBarVisible = search?.visible ?? this.searchBarVisible;
                    this.searchBarPlaceholder = search?.placeholder ?? this.SEARCHBAR_PLACEHOLDER;
                    this.searchBarRoute = search?.route ?? this.SEARCHBAR_ROUTE;
                } else {
                    this.searchBarPlaceholder = this.SEARCHBAR_PLACEHOLDER;
                    this.searchBarRoute = this.SEARCHBAR_ROUTE;
                }

                this.currentPage = '/' + (event.url?.split('/').filter(i => i)[0] ?? '');
                this.isPageCircle = this.currentPage === '/neighborhood';

                this.toggleMenuItems(this.menus);
            });

        this.chatsCounterHandler();
        this.notificationsCounterHandler();

        const searchbarSubscription = this.searchbarService.getChanges().subscribe(status => {
            setTimeout(() => {
                this.searchBarVisible = status.visible;
                this.searchBarInput = status.search ?? this.searchBarInput;

                if (status.focus) {
                    this.searchBarElement?.nativeElement?.focus();
                }
            });
        });
        this.subscriptions.push(searchbarSubscription);
    }

    ngOnDestroy(): void {
        this.menuSubscribe && this.menuSubscribe.unsubscribe();
        this.authReadySubscribe && this.authReadySubscribe.unsubscribe();
        this.authStatusSubscribe && this.authStatusSubscribe.unsubscribe();
        this.chatSubscribe && this.chatSubscribe.unsubscribe();
        this.subscriptions.map(item => item?.unsubscribe());
    }

    onLogin(event?: any): void {
        this.authMode = 'auth';
        this.showAuth = true;
    }

    onLogout(event?: Event): void {
        event.preventDefault();
        this.authService.logout().subscribe(status => {
            const currentUrl = this.router.url;

            this.router.navigate(['/']).then(() => {
                this.router.navigateByUrl(currentUrl);
            });
        });
    }

    onAuthClose(event?: any): void {
        this.showAuth = false;
    }

    toggleMenuItems(menu: Menu, deep: number = 0): void {
        let prev = null;
        for (let item in menu) {
            menu[item].hidden = !(
                this.user.hasGroups(menu[item].groups || [], false)
                && this.user.can(menu[item].permissions || [])
                && (
                    !('shouldHide' in menu[item])
                    || !menu[item].shouldHide(this.user)
                )
            );

            if (menu[item].route && typeof menu[item]['route'] === 'function') {
                // @ts-ignore
                menu[item].route = menu[item].route(this.user);
            }

            if (menu[item].separator && prev && menu[prev].hidden) {
                menu[item].hidden = true;
            }

            menu[item].children?.map((child, index) => {
                this.toggleMenuItems({child}, deep+1);
                if (child.separator && index > 0 && menu[item].children[index - 1]?.hidden) {
                    child.hidden = true;
                }
            });

            const childrenLength = menu[item].children?.length;
            if (!menu[item].hidden && childrenLength && childrenLength === menu[item].children?.filter(item => item.hidden).length) {
                menu[item].hidden = true;
            }

            prev = item;
        }
    }

    protected chatsCounterHandler() {
        const subscription = this.chatService.getNewMessageChanges().subscribe(counter => {
            counter = counter ?? this.user?.counters?.chats;


            if (this.user?.counters?.chats < counter && !this.isMobileApp && this.invisibleWindow) {
                const now = new Date();
                const last = this.chatNotificationLast?.getTime() ?? 0;

                if (counter > 0 && (now.getTime() - last) >= (this.chatNotificationMinTime * 1000)) {
                    this.chatNotification?.nativeElement && this.chatNotification.nativeElement.play();
                    this.chatNotificationLast = now;
                }
            }

            this.breadcrumbService.setTitlePrefix(counter > 0 ? '(' + counter + ')' : null);

            this.user.counters = {
                ...this.user.counters,
                ...{chats: counter}
            };
            this.menus['messages'].badge = counter > 0 ? '' + counter : null;
        });
        this.subscriptions.push(subscription);
    }

    protected notificationsCounterHandler() {
        const subscription = this.notificationService.getNewItemChanges().subscribe(counter => {
            counter = counter ?? this.user?.counters?.notifications;

            this.user.counters = {
                ...this.user.counters,
                ...{notifications: counter}
            };
        });
        this.subscriptions.push(subscription);
    }

    loadNotifications(event: Event = null, appendData: boolean = false) {
        if (appendData && this.loading) {
            return;
        }

        if (appendData && this.notifications) {
            if (this.notifications.reachEnd()) {
                return;
            }
            this.notificationsFilter.page = this.notifications.nextPage();
        } else {
            this.notificationsFilter.page = 1;
        }

        this.loadingQueue++;
        const subscription = this.notificationService.getList(this.notificationsFilter).subscribe(data => {

            let notifications: NotificationViewItem[] = data.data.map(notification => {
                let viewItem: NotificationViewItem = notification;
                viewItem.content = notification.nice ?? '';
                viewItem.photo = notification.image ?? '';
                viewItem.fragmentUrl = '' + (notification.fragment ?? '');
                viewItem.queryParamsList = notification.queryParams ?? null;
                viewItem.date = notification.posted ?? '';
                viewItem.url = notification.link ?? null;
                return viewItem;
            });

            if (appendData && this.notifications) {
                data.data = [
                    ...this.notifications.data,
                    ...notifications,
                ];
            }

            this.notifications = data;
            this.loadingQueue--;
        }, error => {
            this.loadingQueue--;
        });
        this.subscriptions.push(subscription);
    }

    onNotificationClick(event: Event, notification: NotificationItem) {
        this.subscriptions.push(this.notificationService.update({uuid: [notification?.id]}).subscribe(_ => {
            this.subscriptions.push(this.notificationService.newItemsCount().subscribe(_ => {}));
        }));
    }

    onNotificationMarkAsRead(event: Event) {
        event && event.preventDefault();
        this.subscriptions.push(this.notificationService.update().subscribe(_ => {
            this.subscriptions.push(this.notificationService.newItemsCount().subscribe(_ => {}));
        }));

        // @ts-ignore
        $('.notifications-panel').collapse('hide');
    }

    onNotificationNext(event?: any) {
        this.loadNotifications(event, true);
    }

    keyOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        const first = Number(a.key) || 0;
        const second = Number(b.key) || 0;
        return first > second ? 1 : (second > first ? 0 : 1);
    }

    onWindowVisibilityChange(event): void {
        this.invisibleWindow = document?.visibilityState === 'hidden';
    }

    onNotificationsToggle(event?: Event, status?: boolean): boolean {
        event && event?.preventDefault();

        if (this.user.isValid()) {
            const statusWere = this.showNotifications
            this.showNotifications = (status === true || status === false)
                ? status
                : !this.showNotifications;

            if (statusWere !== this.showNotifications) {
                this.changeDetectorRef.detectChanges();
            }
        } else {
            this.router.navigateByUrl('/auth/login');
        }

        return false;
    }

    onAppClick($event, current: any): void {
        $event.stopPropagation();

        this.apps = this.apps.map(app => {
            app.loading = app.link === current.link
                && app.title === current.title
                && app.app_title === current.app_title
                && app.type === current.type
                && app.image === current.image;

            return app;
        });
    }

    onActiveChange(state: boolean, item: MainMenuItem, parent?: MainMenuItem): void {
        item && (item.active = state);
        parent && (parent.active = parent?.children?.filter(child => child.active).length > 0);
    }

    onSearchBarInput(event?: Event): boolean {
        event?.preventDefault();

        this.searchBarTimer && clearTimeout(this.searchBarTimer);
        this.searchBarTimer = setTimeout(() => {
            this.router.navigate([this.searchBarRoute], {queryParams: {
                q: this.searchBarInput?.length > 0 ? this.searchBarInput : undefined,
            }});
        }, 350);

        return false;
    }

    onSearchBarClear(event?: Event): boolean {
        event?.preventDefault();

        this.router.navigate([this.searchBarRoute], {queryParams: {
            q: undefined,
        }});

        return false;
    }

    onExternalLink(event: Event, item: MainMenuItem): void {
        const icon = item?.icon ?? '';
        item.icon = 'fas fa-spinner fa-pulse';

        setTimeout(() => {
            item.icon = icon;
        }, 3500);
    }
}
