export type JobInvoiceItemType = ('regular'|'other'|'tax');

export class InvoiceUpload {
    title?: string;
    description?: string;
    path?: string;
    url?: string;
    mime?: string;
    size?: number;
    status?: ('active'|'inactive');

    static fromJson(fields): InvoiceUpload {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
