import { JobType } from "./jobs/job-type.model";

export class ServiceDescription {
    type: JobType;
    type_id?: number;
    description?: string;
    unavailable?: boolean;
    visible?: ('show'|'hidden');

    static fromJson(fields: any): ServiceDescription {
        let item = Object.assign(new this(), fields);

        item.type = JobType.fromJson(fields?.type);
        item.type_id = item?.type?.id > 0 ? item?.type?.id : item.type_id;
        item.unavailable = item?.unavailable > 0;

        return item;
    }
}
