import { Comment } from "../comment.model";
import { User } from "../user.model";

export type CirclePostStatus = ('active'|'inactive');
export type CircleEventVisibility = ('public'|'private');


export class CirclePost {
    id?: number;
    circle_uuid?: string;
    description?: string;
    image?: string;
    url?: string;
    user?: User;
    counts?: {
        comments?: number;
        favorites?: number;
        shared?: number;
        viewed?: number;
    };
    preview?: {
        image?: string;
        title?: string;
        description?: string;
    };
    status?: CirclePostStatus;
    visibility?: CircleEventVisibility;
    created?: string;
    updated?: string;
    can?: {
        status?: boolean;
        modify?: boolean;
        delete?: boolean;
        comment_add?: boolean;
        comment_modify?: boolean;
        comment_delete?: boolean;
    };
    comments?: Comment[];

    // custom
    isUpdated?: boolean = false;

    static fromJson(fields): CirclePost {
        let item = Object.assign(new this(), fields);
        item.user = User.fromJson(fields?.user);
        item.comments = fields?.comments?.map(comment => Comment.fromJson(comment));
        item.description = ('' + (item.description || '')).trim();
        item.isUpdated = item.updated?.length && item.created != item.updated;
        return item;
    }
}
