import { User } from './user.model';

class TeamCoordinateLocation {
    lat: number;
    long: number;

    static fromJson(fields: any): TeamCoordinateLocation {
        let item = Object.assign(new this(), fields);
        return item;
    }
};

export class TeamCoordinate {
    start?: TeamCoordinateLocation;
    end?: TeamCoordinateLocation;

    static fromJson(fields: any): TeamCoordinate {
        let item = Object.assign(new this(), fields);

        item.start = TeamCoordinateLocation.fromJson(item?.start);
        item.end = TeamCoordinateLocation.fromJson(item?.end);

        return item;
    }
}
