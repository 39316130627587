export class UserCoordinate {
    id?: string;
    coordinate_lat?: number;
    coordinate_long?: number;
    created?: string;

    static fromJson(fields: any): UserCoordinate {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
