import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UndoClickService {

    protected subjectStatus: Subject<Event> = new Subject();

    constructor(
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
    ) {
        if (isPlatformBrowser(this.platform)) {
            this.bootstrapDropdownHandler();
        }
    }

    onClickEvent(): Observable<Event> {
        return this.subjectStatus.asObservable().pipe(share());
    }

    setClick(event: Event) {
        this.subjectStatus.next(event);
    }

    // catch all bootstrap 4 dropdowns
    // due to .stopPropagation() call in their handlers, document:click is not fired
    bootstrapDropdownHandler() {
        let self = this;
        // @ts-ignore
        $('body').on('show.bs.dropdown', '.dropdown', function(e) {
            self.subjectStatus.next(e);
        });
    }
}
