import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/shared/models/user.model';

@Pipe({
  name: 'userFullName'
})
export class UserFullNamePipe implements PipeTransform {
    transform(user: any|User): string {
        return (
            user instanceof User ? user
                : Object.assign(new User, user)
        ).full_name || '';
    }
}
