import { Component, HostBinding, OnInit } from '@angular/core';
import { ReactNativeService } from '../../services/react-native.service';

@Component({
    selector: 'app-main',
    templateUrl: './app-main.component.html',
    styleUrls: ['./app-main.component.scss']
})
export class AppMainComponent implements OnInit {
    @HostBinding('class.app') isMobileApp: boolean = false;

    constructor(
        private reactNativeService: ReactNativeService,
    ) { }

    ngOnInit(): void {
        this.isMobileApp = this.reactNativeService.isMobile();
    }

}
