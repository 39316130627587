export class AssetEvent {
    id?: number;
    type?: ('once'|'after'|'repeat');
    notes?: string;
    once_date?: string;
    once_after_seconds?: number;
    repeat_type?: ('daily'|'weekly'|'monthly');
    repeat_every?: number;
    repeat_days_of_week?: (
        'Monday'|'Tuesday'|'Wednesday'|'Thursday'|'Friday'|'Saturday'|'Sunday'
    );
    repeat_months?: (
        'January'|'February'|'March'|'April'|'May'|'June'|'July'
        |'August'|'September'|'October'|'November'|'December'
    );
    repeat_days?: (
           1 |  2 |  3 |  4 |  5 |  6 |  7 |  8 |  9 | 10
        | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20
        | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30
        | 31
        | 'last'
    );
    status?: ('active'|'inactive');
    created?: string;
    updated?: string;

    static fromJson(fields: any): AssetEvent {
        let item = Object.assign(new this, fields);

        return item;
    }
}
