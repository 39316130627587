import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import * as moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class I18nService {

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private primeConfig: PrimeNGConfig,
    ) { }

    initPrimeNG() {

        // Setup i18n support for PrimeNG (globally)
        this.primeConfig.setTranslation({
            dayNames: [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday"
            ],
            dayNamesShort: [
                "Su",
                "Mo",
                "Tu",
                "We",
                "Th",
                "Fr",
                "Sa"
            ],
            dayNamesMin: [
                "Su",
                "Mo",
                "Tu",
                "We",
                "Th",
                "Fr",
                "Sa"
            ],
            monthNames: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ],
            monthNamesShort: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ],
            today: "Today",
            clear: "Clear",
            weekHeader: "Wd",
        });
    }

    initMoment() {
        moment.locale(this.locale);
    }
}
