import { Itasker } from "./itaskers/itasker.model";
import { Project } from "./projects/project.model";

export type FavoriteType = ('itasker'|'project');

export class Favorite {
    id?: number;
    ids?: string[];
    type?: FavoriteType;
    image?: string;
    width?: number;
    height?: number;
    headline?: string;
    url?: string;
    details?: Itasker|Project;

    static fromJson(fields: any): Favorite {
        let item = Object.assign(new this(), fields);

        switch (fields.type) {
            case 'itasker':
                item.details = Itasker.fromJson(fields.details);
                break;
            case 'project':
                item.details = Project.fromJson(fields.details);
                break;
        }

        return item;
    }
}
