export class CircleEventLink {
    id?: number;
    title?: string;
    url?: string;
    created?: string;
    updated?: string;

    static fromJson(fields): CircleEventLink {
        let item = Object.assign(new this(), fields);
        return item;
    }
}

