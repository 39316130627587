import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MessageService } from 'primeng/api'
import localeBg from '@angular/common/locales/bg';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpUnauthInterceptorService } from 'src/app/shared/services/http-unauth-interceptor.service';

import packageJson from './../../package.json';

registerLocaleData(localeBg);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: packageJson.name }),
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        AppRoutingModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpUnauthInterceptorService,
            multi: true
        }, {
            provide: LOCALE_ID,
            useValue: "en"
        },
        MessageService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
