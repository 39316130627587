<ng-container *ngIf="sizes">
    <ng-container [ngTemplateOutlet]="avatar" [ngTemplateOutletContext]="{size: sizes.xxl,  urlWidth: urlWidths.xxl,  classes: 'd-none d-xxl-inline-flex'}"></ng-container>
    <ng-container [ngTemplateOutlet]="avatar" [ngTemplateOutletContext]="{size: sizes.xl,   urlWidth: urlWidths.xl,   classes: 'd-none d-xxl-none d-xl-inline-flex'}"></ng-container>
    <ng-container [ngTemplateOutlet]="avatar" [ngTemplateOutletContext]="{size: sizes.lg,   urlWidth: urlWidths.lg,   classes: 'd-none d-xl-none d-lg-inline-flex'}"></ng-container>
    <ng-container [ngTemplateOutlet]="avatar" [ngTemplateOutletContext]="{size: sizes.md,   urlWidth: urlWidths.md,   classes: 'd-none d-lg-none d-md-inline-flex'}"></ng-container>
    <ng-container [ngTemplateOutlet]="avatar" [ngTemplateOutletContext]="{size: sizes.sm,   urlWidth: urlWidths.sm,   classes: 'd-none d-md-none d-sm-inline-flex'}"></ng-container>
    <ng-container [ngTemplateOutlet]="avatar" [ngTemplateOutletContext]="{size: sizes.xs,   urlWidth: urlWidths.xs,   classes: 'd-inline-flex d-sm-none'}"></ng-container>
</ng-container>
<ng-container *ngIf="!sizes" [ngTemplateOutlet]="avatar" [ngTemplateOutletContext]="{size: size, urlWidth: urlWidth}"></ng-container>

<ng-template #avatar let-size="size" let-classes="classes" let-urlWidth="urlWidth">
    <div class="avatar {{ className }} {{ classes ?? '' }}" [ngClass]="{
        'tiny': size === 'tiny',
        'small': size === 'small',
        'medium': size === 'medium',
        'large': size === 'large',
        'big': size === 'big',
        'huge': size === 'huge',
        'empty': !user?.id && !photo && showEmpty,
        'with-shadow': withShadow,
        'admin': (user | userGroup:'admin')
    }">
        <div class="default d-flex align-items-center justify-content-center" *ngIf="!user && !photo && !initials?.length">
            <i *ngIf="logo" class="fa-regular fa-file-image text-light"></i>
            <img *ngIf="!logo" src="/assets/img/profile.png" alt="User" />
        </div>

        <small *ngIf="user?.initials?.length && ((!photo && !user?.avatar?.length) || imageError || !imageLoaded)"
                class="text-uppercase text-white text-nowrap" [attr.aria-label]="(user | userName) || 'User'">{{ user.initials }}</small>

        <small *ngIf="!user?.id && (!photo || imageError || !imageLoaded)"
                class="text-uppercase text-white text-nowrap" [attr.aria-label]="(user | userName) || 'User'">{{ initials }}</small>

        <img *ngIf="(photo || user?.avatar?.length) && !imageError"
             [src]="(photo || user?.avatar) + (urlWidth && urlResize ? '?w=' + urlWidth : '')"
             class="h-100 w-100"
             [alt]="(user | userName) ?? 'User'"
             [class.d-none]="!imageLoaded"
             (load)="imageLoaded = true"
             (error)="imageError = true"
        />

        <i *ngIf="enableRemoving && (photo?.length || user?.avatar?.length)"
            class="fa-solid fa-xmark fa-fw text-danger remove-photo"
            [title]="removingTitle"
            (click)="onRemoveClicked($event)"></i>

        <ng-content></ng-content>

        <span class="status rounded-circle align-middle p-2 me-1 position-absolute"
              [class.bg-success]="status === true"
              [class.bg-danger]="status === false"
              [attr.title]="status === true ? 'Online' : status === false ? 'Offline' : null"
        ></span>
    </div>
</ng-template>
