export class JobServicePivot {
    service_id: number;
    itasker_id: number;
    rate: string;
    warranty_time: number;
    follow_up_appointment_discount: string;
    init_appointment_discount: string;
    init_appointment_time: number;
    created_at: string;
    updated_at: string;

    static fromJson(fields): JobServicePivot {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
